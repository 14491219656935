import React from 'react'

import { StaticSvgIcon } from '@dominos/res'
import { toPascalCase } from '@dominos/business/functions'

import cssList from './list-group.less'

import { ListGroupCollectionLineWrapper } from './list-group-collection-line-wrapper'
import { ListGroupCollectionLineSuffix } from './list-group-collection-line-suffix'

import { IListGroupCollectionItem } from './interfaces'

interface ListGroupCollectionLineProps extends BaseProps {
  key: string
  iconStyling: SvgIconDimension | undefined
  isLoading: boolean | undefined
  itemLoadingKey: string | undefined
  onPress: (itemId: string) => void
  item: IListGroupCollectionItem
}

export const ListGroupCollectionLine = (props: ListGroupCollectionLineProps) => (
  <ListGroupCollectionLineWrapper
    prefix={`address`}
    itemId={props.item.id}
    itemIndex={props.item.index}
    onPress={props.onPress}
    testID={`${props.testID}.wrapper`}
  >
    <div className={cssList.container}>
      {props.item.icon && (
        <div data-testid={`${props.testID}.icon.${props.item.icon}`} className={cssList.iconContainer}>
          <StaticSvgIcon
            name={props.item.icon}
            isUnstyled
            width={props.iconStyling?.width}
            height={props.iconStyling?.height}
          />
        </div>
      )}

      <div className={cssList.textContainer}>
        <p className={cssList.highlightTitle} data-testid={`${props.testID}.title`}>
          {props.item.title}
        </p>
        <p className={cssList.subtitle} data-testid={`${props.testID}.subtitle`}>
          {toPascalCase(props.item.subtitle)}
        </p>
      </div>

      {props.item.suffix && (
        <ListGroupCollectionLineSuffix
          content={props.item.suffix}
          testID={`${props.testID}.suffix`}
          isLoading={props.isLoading && props.item.id === props.itemLoadingKey}
        />
      )}
    </div>
  </ListGroupCollectionLineWrapper>
)
