import { ActionType } from 'typesafe-actions'
import * as applicationActions from './application.actions'
import * as basketActions from './basket.actions'
import * as checkoutActions from './checkout.actions'
import * as consentActions from './consent.actions'
import * as customerActions from './customer.actions'
import * as featureActions from './feature.actions'
import * as storeActions from './store.actions'
import * as voucherActions from './voucher.actions'
import * as upsellActions from './upsell.actions'

export * from './basket.actions'
export * from './checkout.actions'
export * from './consent.actions'
export * from './application.actions'
export * from './customer.actions'
export * from './store.actions'
export * from './feature.actions'
export * from './voucher.actions'
export * from './upsell.actions'

export type BasketActions = ActionType<typeof basketActions>
export type CheckoutActions = ActionType<typeof checkoutActions>
export type ConsentActions = ActionType<typeof consentActions>
export type CommonActions = ActionType<typeof applicationActions>
export type CustomerActions = ActionType<typeof customerActions>
export type StoreActions = ActionType<typeof storeActions>
export type FeatureActions = ActionType<typeof featureActions>
export type VoucherActions = ActionType<typeof voucherActions>
export type UpsellActions = ActionType<typeof upsellActions>
