import { useAllOffers } from '@dominos/hooks-and-hocs/offers'
import { useEffect, useState } from 'react'
import { productUpsellFilter } from './offers-helpers'
import { isOfferComponentAvailable } from '@dominos/components/product/product-upsell/utils'
import { ProductUpsellData } from '@dominos/components/product/product-upsell/product-upsell.interface'
import { useProductContext } from '@dominos/components'

const allowedLayout: BffContext.Layouts = 'ProductList'
const locationType = 'ProductDetails'

export const useProductUpsellOffer = (currentProduct: BasketLineData | undefined) => {
  const { offers: allOffers = [] } = useAllOffers()
  const [productUpsellOffer, setProductUpsellOffer] = useState<ProductUpsellData | undefined>()
  const { dimensionSetState } = useProductContext()

  useEffect(() => {
    if (allOffers.length === 0 || !dimensionSetState || !dimensionSetState.selectedDimensionSet || !currentProduct)
      return

    const filteredOffer = productUpsellFilter(allOffers, locationType, allowedLayout)
    const firstFilteredItem = filteredOffer?.items?.[0]
    if (!filteredOffer || !firstFilteredItem) return

    const { media, locations } = filteredOffer
    const { linkedItem, price } = firstFilteredItem
    const { name, banner, popUp, description } = media

    if (isOfferComponentAvailable(linkedItem.itemCode, linkedItem.componentType, currentProduct, dimensionSetState)) {
      setProductUpsellOffer({
        media: {
          name: { value: name },
          bannerImage: { uri: banner.url || '', altText: banner.altText || '' },
          popupImage: { uri: popUp.url || '', altText: popUp.altText || '' },
          description: { value: description },
        },
        price,
        code: linkedItem.itemCode,
        showBanner: locations[0].showBanner,
        showPopUp: locations[0].showPopUp,
      })
    } else {
      setProductUpsellOffer(undefined)
    }
  }, [allOffers, dimensionSetState, dimensionSetState?.selectedDimensionSet])

  return productUpsellOffer
}
