import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { applicationName } from '@dominos/business/functions'
import { useConfigContext, useLaunchDarklyContext, useLaunchDarklyID } from '@dominos/hooks-and-hocs'

export const useIdentifyContext = () => {
  const { applicationConfig } = useConfigContext()
  const { setLDContext } = useLaunchDarklyContext()
  const launchDarklyID = useLaunchDarklyID()
  const { i18n } = useTranslation('footer')

  useEffect(() => {
    if (!launchDarklyID) {
      return
    }

    setLDContext({
      kind: 'user',
      key: launchDarklyID,
      oloApplication: applicationName().toLowerCase(),
      oloMarket: applicationConfig.COUNTRY.toLowerCase(),
      oloVersion: window.nativeAppVersion || applicationConfig.BUILD_BUILDNUMBER,
      oloLanguage: i18n.language,
    })
  }, [applicationConfig.COUNTRY, i18n.language, launchDarklyID])
}
