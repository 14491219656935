import { rootActions } from '@dominos/business/root.actions'
import { ActionsObservable, Epic, StateObservable } from 'redux-observable'
import { combineLatest, Observable, of, pipe } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators'
import { getType, isActionOf } from 'typesafe-actions'
import { createConfig } from '../functions/epics/create-config'
import { storeQuery, storesAlternativeDistancesQuery } from '../queries'
import { getBooleanFromFeatures } from '@dominos/hooks-and-hocs'
import { createStoreDistances } from '../functions/store/create-store-distances'
import { createStoresAlternativeDistanceInput } from '../functions/store'

interface StoreResponse {
  store: Bff.Stores.Store
}

interface StoresAlternativeResponse {
  storesAlternativeDistances: Bff.Stores.StoresAlternativeDistanceResponse
}

export const getStoreEpic: Epic = (
  action$: ActionsObservable<RootActions>,
  store$: StateObservable<RootReducer>,
  { graphQLQuery, securitySender }: IReduxInjected,
) =>
  action$.pipe(
    filter(
      isActionOf([
        rootActions.addCustomerAddressToOrder,
        rootActions.alternativeStoreSelected,
        rootActions.updateLanguage,
      ]),
    ),
    switchMap((action) => {
      let storeNo
      switch (action.type) {
        case getType(rootActions.addCustomerAddressToOrder): {
          storeNo = action.address.storeNo!
          break
        }
        case getType(rootActions.alternativeStoreSelected): {
          storeNo = action.storeNo!
          break
        }
        default: {
          storeNo = store$.value.storeReducer.currentStore?.storeNo
          break
        }
      }

      if (!storeNo) {
        return new Observable()
      }

      const storeConfig = createConfig(store$.value, { storeNo })
      if (storeConfig) {
        const storeObserver = graphQLQuery<StoreResponse>(storeQuery, storeConfig, securitySender)
        const showAlternativeDeliveryStores =
          store$.value.featureReducer.features &&
          getBooleanFromFeatures('ShowAlternativeDeliveryStores', store$.value.featureReducer.features)
        if (action.type === getType(rootActions.addCustomerAddressToOrder) && showAlternativeDeliveryStores) {
          const storesAlternativeConfig = createConfig(
            store$.value,
            createStoresAlternativeDistanceInput(
              storeNo,
              action.address.customerAddress,
              store$.value.applicationReducer.countryCode,
            ),
          )
          if (storesAlternativeConfig) {
            const alternativeStoresObserver = graphQLQuery<StoresAlternativeResponse>(
              storesAlternativeDistancesQuery,
              storesAlternativeConfig,
              securitySender,
            ).pipe(catchError(() => of({ storesAlternativeDistances: { alternativeStoreDistances: [] } }))) // Don't want to break the flow if this call fails.

            return combineLatest([storeObserver, alternativeStoresObserver]).pipe(
              mergeMap(([storeResponse, alternativeStoresResponse]) =>
                of(
                  rootActions.retrievingStoreInformation(),
                  rootActions.retrievedStoreInformation(storeResponse.store, false),
                  rootActions.retrievedStoreDistances(
                    createStoreDistances(storeResponse.store, alternativeStoresResponse.storesAlternativeDistances),
                  ),
                ),
              ),
              catchError(pipe(rootActions.selectStoreFail, of)),
            )
          }
        }

        return storeObserver.pipe(
          map((response) => rootActions.retrievedStoreInformation(response.store, false)),
          catchError(pipe(rootActions.selectStoreFail, of)),
        )
      }

      return of(rootActions.selectStoreFail('could not build config'))
    }),
  )
