import { ProductUpsellData } from '../product-upsell.interface'
import { TFunction } from 'react-i18next'
import { getImageUrl } from '@dominos/components/product/product-upsell/utils'
import { ProductComponentUpsellLDVariation } from '@dominos/components/product/product-upsell/product-upsell.interface'

export type ProductUpsellCardViewProps = {
  viewModel: ProductUpsellCardViewModel
  onYesClicked: () => void
  onNoClicked: () => void
}

type ProductUpsellCardViewModelProps = {
  t: TFunction<'menu'>
  upsellData: ProductUpsellData
  isTestImgDevToggle: boolean
  productComponentUpsellVariation: string
}

type ProductUpsellCardViewModel = {
  yesButtonText: string
  bannerImageUrl: string | undefined
  upsellName: string
}

export const createProductUpsellCardViewModel = ({
  t,
  upsellData,
  isTestImgDevToggle,
  productComponentUpsellVariation,
}: ProductUpsellCardViewModelProps): ProductUpsellCardViewModel => {
  const yesButtonText =
    productComponentUpsellVariation == ProductComponentUpsellLDVariation.VariationPriceEnable && upsellData.price
      ? upsellData.price
      : t('ProductComponentUpsellYes')
  const upsellName = upsellData.media.name.value
  const bannerImageUrl = getImageUrl(upsellData.media.bannerImage.uri ?? '', isTestImgDevToggle)

  return { yesButtonText, bannerImageUrl, upsellName }
}
