import { ActionButton, CollapsableTitledCard, CountryToggle, GenericCard, LoyaltyMarketing } from '@dominos/components'
import { Modal } from '@dominos/components/notification'
import { useDominosTheme, useFeatures, useLoyalty } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import { RewardsEnrollDE, RewardsEnrollFR, RewardsEnrollNL } from '@dominos/res/images/icons/components'
import { RouteComponentProps, useNavigate } from '@reach/router'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import css from './reward-enrollment.less'

interface RewardEnrollmentProps extends RouteComponentProps<BaseProps> {
  onRefresh?: () => void
}

export const RewardEnrollment = ({ testID, onRefresh }: RewardEnrollmentProps) => {
  const navigate = useNavigate()
  const theme = useDominosTheme()
  const [showPopup, setShowPopup] = useState(false)
  const { t } = useTranslation('loyalty')
  const { enroll, loading } = useLoyalty()
  const { featureEnabled } = useFeatures()
  const [myRewards, isLoyaltyMarketingEnabled, isSingleOptInEnabled] = featureEnabled(
    'loyalty-backend',
    'LoyaltyMarketing',
    'LoyaltySingleOptIn',
  )
  const [marketingConsent, setMarketingConsent] = useState(isSingleOptInEnabled)

  const handlePopupDismiss = () => setShowPopup(false)

  const FeatureImage = CountryToggle({
    DE: RewardsEnrollDE,
    NL: RewardsEnrollNL,
    FR: RewardsEnrollFR,
  })

  const presentConfirmationDialog = () => setShowPopup(true)

  const handleEnrollConfirmation = () => enroll(marketingConsent).then(handlePopupDismiss).finally(onRefresh)

  const displayLegal = () => navigate(t('RewardsAcceptLegalUrl', { defaultValue: '' }))

  const handleMarketingEnrollToggle = (consent: boolean) => setMarketingConsent(consent)

  return (
    <div data-testid={testID} className={css.wrapper}>
      {showPopup && (
        <Modal testID={testID as string} onModalClose={handlePopupDismiss}>
          <GenericCard
            testID={`${testID}.accept`}
            title={t('RewardsAcceptTitle', { defaultValue: `Activate Domino's Club` })}
            style={{ zIndex: 1, position: 'relative' }}
          >
            <div data-testid={`${testID}.dismiss`} role='close' onClick={handlePopupDismiss} className={css.dismiss}>
              <StaticSvgIcon name='cross' direction='left' width={20} height={20} isUnstyled />
            </div>
            <div data-testid={`${testID}.details`} style={{ display: 'grid', gridGap: '10px', gridAutoFlow: 'row' }}>
              <div data-testid={`${testID}.description`}>
                {t('RewardsAcceptDescription', {
                  defaultValue: `To join Domino's Club, you must accept the Terms & Conditions of the program.`,
                })}
              </div>
              {isLoyaltyMarketingEnabled && (
                <GenericCard
                  noPadding
                  testID='LoyaltyEnroll'
                  style={{
                    border: 'solid 0.1px rgba(151, 151, 151, 0.4)',
                    boxShadow: '0',
                    marginBottom: '10px',
                    marginTop: '10px',
                    maxWidth: '100%',
                  }}
                >
                  <LoyaltyMarketing
                    testID={`${testID}.marketing`}
                    value={marketingConsent}
                    onChange={handleMarketingEnrollToggle}
                  />
                </GenericCard>
              )}
              <ActionButton
                testID={`${testID}.confirm`}
                text={t('RewardsAcceptConfirmLabel', { defaultValue: `Accept` })}
                containerStyle={{ width: '100%', height: 50 }}
                textFontWeight={'semibold'}
                loading={loading}
                onPress={handleEnrollConfirmation}
              />
              <ActionButton
                testID={`${testID}.terms-and-conditions`}
                text={t('RewardsAcceptLegalLabel', { defaultValue: `View Terms and Conditions` })}
                containerStyle={{ width: '100%', backgroundColor: 'transparent' }}
                textStyle={{ color: theme.colours.actionStandard }}
                textFontWeight={'semibold'}
                onPress={displayLegal}
              />
            </div>
          </GenericCard>
        </Modal>
      )}
      <GenericCard
        testID={`${testID}.rewards`}
        title={t('RewardsTitle', { defaultValue: `Domino's Club` })}
        style={{ maxWidth: '100%' }}
      >
        <div data-testid={`${testID}.image`} className={css.image}>
          {FeatureImage && <FeatureImage width={235} height={235} />}
        </div>
      </GenericCard>
      <GenericCard
        testID={`${testID}.enroll`}
        title={t('RewardsEnrollTitle', { defaultValue: 'Activate Punten Sparen' })}
        style={{ maxWidth: '100%' }}
      >
        <ActionButton
          testID={`${testID}.enroll`}
          onPress={presentConfirmationDialog}
          text={t('RewardsEnrollLabel', { defaultValue: `Activate Domino's Rewards` })}
          disabled={!myRewards}
          containerStyle={{ width: '100%', height: 50 }}
          textFontWeight={'semibold'}
        />
      </GenericCard>
      <CollapsableTitledCard
        testID={`${testID}.about`}
        primaryTitle={t('RewardsAboutTitle', { defaultValue: `About Domino's Club` })}
        style={{ padding: 10 }}
      >
        {t('RewardsTermsAndConditions', {
          defaultValue: ``,
        })}
      </CollapsableTitledCard>
    </div>
  )
}
