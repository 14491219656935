import '@adyen/adyen-web/dist/adyen.css'
import { PaymentSetting } from '@dominos/hooks-and-hocs'
import React from 'react'
import { getAdyenPaymentMethods, PaymentAdyen, PaymentSavedAdyen, PaymentTerminal } from './payment-method/adyen'
import { PaymentCash } from './payment-method/cash/payment-cash'
import { PaymentEdenred } from './payment-method/edenred/payment-edenred'
import { PaymentGMO } from './payment-method/gmo/payment-gmo'
import { SplitPaymentOutstandingBalance } from './split-payment/split-payment'
import { useFilterSavedPaymentSettings } from './use-filter-saved-payment-settings'
import { isNonSavedAdyenPaymentEnabled } from './use-non-saved-payment-settings'
import { FeatureToggles } from './use-payment-feature-toggles'
import { SelectedPaymentSetting } from './payment-method/payment-method'
import { PaymentPaypalDirect } from './payment-method/paypal-direct/payment-paypal-direct'
import { PaymentB2b } from './payment-method/b2b/payment-b2b'

interface PaymentMethodListProps
  extends Omit<ProviderAdyenPaymentProps & ProviderPayPalPaymentProps, 'index' | 'paymentSetting' | 'providerCode'> {
  serviceMethod: BffContext.ServiceMethods | undefined
  edenredAuthCode: string | null
}

interface ProviderPaymentBaseProps {
  orderId: string
  paymentSetting: PaymentSetting
  selectedPaymentSetting: SelectedPaymentSetting
  isValidating: boolean
}

interface ProviderAdyenPaymentProps extends ProviderPaymentBaseProps {
  index: number
  paymentSettings: PaymentSetting[]
  featureToggles: FeatureToggles
  outstandingBalance: SplitPaymentOutstandingBalance | undefined
  onOutstandingBalance: (balance: SplitPaymentOutstandingBalance) => void
}

interface ProviderPayPalPaymentProps extends ProviderPaymentBaseProps {
  providerCode: BffContext.PaymentProviders
  featureToggles: FeatureToggles
  outstandingBalance: SplitPaymentOutstandingBalance | undefined
}

const ProviderAdyenPayment = ({
  index,
  orderId,
  paymentSetting,
  paymentSettings,
  selectedPaymentSetting,
  isValidating,
  featureToggles,
  outstandingBalance,
  onOutstandingBalance,
}: ProviderAdyenPaymentProps) => {
  const { savedCreditCardSettings, savedMisterCashSettings } = useFilterSavedPaymentSettings(paymentSettings)

  if (paymentSetting.paymentMethod === 'Terminal') {
    return (
      <PaymentTerminal
        key={`${paymentSetting.paymentMethod}-${paymentSetting.providerCode}`}
        paymentSetting={paymentSetting}
        selectedPaymentSetting={selectedPaymentSetting}
        isValidatingBasket={isValidating}
      />
    )
  }
  const adyenPaymentMethods = getAdyenPaymentMethods(paymentSetting)

  if (paymentSetting.savedPayment) {
    const savedCards =
      paymentSetting === savedCreditCardSettings[0]
        ? savedCreditCardSettings
        : paymentSetting === savedMisterCashSettings[0]
        ? savedMisterCashSettings
        : []
    if (
      (paymentSetting.paymentMethod === 'MisterCash' || paymentSetting.paymentMethod === 'CreditCard') &&
      !savedCards.length
    ) {
      return null
    }

    return adyenPaymentMethods.length > 0 ? (
      <React.Fragment key={`${paymentSetting.paymentMethod}-${paymentSetting.providerCode}-saved-payment`}>
        {adyenPaymentMethods.map((adyenPaymentMethod, savedPaymentIndex) => (
          <PaymentSavedAdyen
            index={`${index}-${savedPaymentIndex}`}
            key={`${paymentSetting.paymentMethod}-${adyenPaymentMethod.type}-${paymentSetting.providerCode}-saved-payment-${index}-${savedPaymentIndex}`}
            paymentSetting={paymentSetting}
            selectedPaymentSetting={selectedPaymentSetting}
            isValidatingBasket={isValidating}
            adyenPaymentMethod={adyenPaymentMethod}
            onOutstandingBalance={onOutstandingBalance}
            outstandingBalance={outstandingBalance}
            orderId={orderId}
            savedCreditCardSettings={savedCards}
          />
        ))}
      </React.Fragment>
    ) : null
  }

  const adyenEnabled = isNonSavedAdyenPaymentEnabled(paymentSetting.paymentMethod, featureToggles)

  return adyenEnabled && adyenPaymentMethods.length > 0 ? (
    <React.Fragment key={`${paymentSetting.paymentMethod}-${paymentSetting.providerCode}`}>
      {adyenPaymentMethods.map((adyenPaymentMethod) => (
        <PaymentAdyen
          key={`${paymentSetting.paymentMethod}-${adyenPaymentMethod.type}-${paymentSetting.providerCode}`}
          paymentSetting={paymentSetting}
          selectedPaymentSetting={selectedPaymentSetting}
          isValidatingBasket={isValidating}
          adyenPaymentMethod={adyenPaymentMethod}
          onOutstandingBalance={onOutstandingBalance}
          outstandingBalance={outstandingBalance}
          orderId={orderId}
        />
      ))}
    </React.Fragment>
  ) : null
}

const ProviderPayPalPayment = ({
  orderId,
  paymentSetting,
  selectedPaymentSetting,
  isValidating,
  featureToggles,
  outstandingBalance,
}: ProviderPayPalPaymentProps) => {
  if (!featureToggles.paypalEnabled && !featureToggles.paypalDirectEnabled) {
    return null
  }

  return (
    <PaymentPaypalDirect
      key={`${paymentSetting.paymentMethod}-${paymentSetting.providerCode}`}
      paymentSetting={paymentSetting}
      selectedPaymentSetting={selectedPaymentSetting}
      isValidatingBasket={isValidating}
      orderId={orderId}
      outstandingBalance={outstandingBalance}
    />
  )
}

const PaymentMethodList = ({
  orderId,
  serviceMethod,
  paymentSettings,
  selectedPaymentSetting,
  isValidating,
  featureToggles,
  outstandingBalance,
  onOutstandingBalance,
  edenredAuthCode,
}: PaymentMethodListProps) => (
  <>
    {paymentSettings.map((paymentSetting, idx) => {
      switch (paymentSetting.providerCode) {
        case 'Cash':
          return (
            <PaymentCash
              key={`${paymentSetting.paymentMethod}-${paymentSetting.providerCode}`}
              paymentSetting={paymentSetting}
              selectedPaymentSetting={selectedPaymentSetting}
              isValidatingBasket={isValidating}
              isCashChangeRequired={featureToggles.cashChangeRequiredEnabled && serviceMethod === 'Delivery'}
            />
          )

        case 'Adyen':
          return (
            <ProviderAdyenPayment
              key={`${paymentSetting.paymentMethod}-${paymentSetting.providerCode}`}
              orderId={orderId}
              paymentSetting={paymentSetting}
              selectedPaymentSetting={selectedPaymentSetting}
              isValidating={isValidating}
              index={idx}
              paymentSettings={paymentSettings}
              featureToggles={featureToggles}
              outstandingBalance={outstandingBalance}
              onOutstandingBalance={onOutstandingBalance}
            />
          )

        case 'PayPal':
        case 'PayPalDirect':
          return (
            <ProviderPayPalPayment
              key={`${paymentSetting.paymentMethod}-${paymentSetting.providerCode}`}
              orderId={orderId}
              paymentSetting={paymentSetting}
              selectedPaymentSetting={selectedPaymentSetting}
              isValidating={isValidating}
              providerCode={paymentSetting.providerCode}
              featureToggles={featureToggles}
              outstandingBalance={outstandingBalance}
            />
          )

        case 'GMO':
          return featureToggles.gmoEnabled ? (
            <PaymentGMO
              key={`${paymentSetting.paymentMethod}-${paymentSetting.providerCode}`}
              paymentSetting={paymentSetting}
              selectedPaymentSetting={selectedPaymentSetting}
              isValidatingBasket={isValidating}
            />
          ) : null

        case 'StaffOrder':
          return featureToggles.isB2BOrderEnabled ? (
            <PaymentB2b
              key={`${paymentSetting.paymentMethod}-${paymentSetting.providerCode}`}
              paymentSetting={paymentSetting}
              selectedPaymentSetting={selectedPaymentSetting}
              isValidatingBasket={isValidating}
            />
          ) : null

        case 'Edenred':
          return featureToggles.edenredEnabled ? (
            <PaymentEdenred
              key={`${paymentSetting.paymentMethod}-${paymentSetting.providerCode}`}
              paymentSetting={paymentSetting}
              selectedPaymentSetting={selectedPaymentSetting}
              isValidatingBasket={isValidating}
              orderId={orderId}
              authorisationCode={edenredAuthCode}
              onOutstandingBalance={onOutstandingBalance}
              outstandingBalance={outstandingBalance}
            />
          ) : null

        default:
          return null
      }
    })}
  </>
)

export default PaymentMethodList
