import { CrustIcon, SauceIcon, SizeIcon } from '@dominos/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomisationCard } from './customisation-card'
import { ProductCustomiserProps } from './product-customiser.interface'

export const NUM_COLUMNS = 3

export const ProductCustomiser = ({
  sizeData,
  onSizeChange,
  baseData,
  onBaseChange,
  sauceData,
  onSauceChange,
}: ProductCustomiserProps) => {
  const { t } = useTranslation('menu')
  const isSizeCustomisable = sizeData && sizeData.items.length > 0
  const isBaseCustomisable = baseData && baseData.items.length > 0
  const isSauceCustomisable = sauceData && sauceData.items.length > 0

  return (
    <>
      {isSizeCustomisable && (
        <CustomisationCard
          testID={'Size-Selector'}
          items={sizeData.items}
          selectedItemCode={sizeData.selectedItemCode}
          primaryTitle={t('Size')}
          numColumns={NUM_COLUMNS}
          renderItem={SizeIcon}
          onItemSelected={onSizeChange}
        />
      )}
      {isBaseCustomisable && (
        <CustomisationCard
          testID={'Base-Selector'}
          items={baseData.items}
          selectedItemCode={baseData.selectedItemCode}
          primaryTitle={t('Base')}
          numColumns={NUM_COLUMNS}
          renderItem={CrustIcon}
          onItemSelected={onBaseChange}
        />
      )}
      {isSauceCustomisable && (
        <CustomisationCard
          testID={'Sauce-Selector'}
          items={sauceData.items}
          selectedItemCode={sauceData.selectedItemCode}
          primaryTitle={t('Sauce')}
          numColumns={NUM_COLUMNS}
          renderItem={SauceIcon}
          onItemSelected={onSauceChange}
        />
      )}
    </>
  )
}
