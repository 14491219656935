import React from 'react'
import { CreateProductBuildYourOwnViewProps } from './create-product-build-your-own-view-model'
import css from './product-build-your-own-view.less'
import { CollapsableTitledCard } from '@dominos/components/cards/collapsable-titled-card'

export const ProductBuildYourOwnView = ({
  viewModel: { buildYourOwnTitle, createHalfHalfText, createQuattroText, color },
  onClick,
  testID,
}: CreateProductBuildYourOwnViewProps) => {
  const handleCreateQuattro = () => {
    alert('create quattro button clicked')
  }

  return (
    <CollapsableTitledCard
      testID={`${testID}.card`}
      primaryTitle={buildYourOwnTitle}
      noPadding={false}
      startCollapsed={true}
      style={{ color }}
    >
      <div className={css.container}>
        <div onClick={onClick} className={css.createAction}>
          <span className={css.imageHalfHalf}></span>
          <span>{createHalfHalfText}</span>
        </div>
        <div onClick={handleCreateQuattro} className={css.createAction}>
          <span className={css.imageQuattro}></span>
          <span>{createQuattroText}</span>
        </div>
      </div>
    </CollapsableTitledCard>
  )
}
