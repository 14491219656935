import React, { useEffect, useRef, useState } from 'react'
import css from './delivery-address-search-autocomplete.less'
import { GeoLocationInterface } from '@dominos/interfaces'
import { DeliverySearchActionConfirmContainer } from '../delivery-search-action-confirm-container'
import { DeliverySearchMap, IMapViewableArea, useDeliveryAddressSearchAutoCompleteContainer } from 'olo-feature-address'
import { DeliveryAutoCompleteSearch } from '../delivery-autocomplete-search'

const testID = 'delivery-address-search-autocomplete'

export interface DeliveryAddressSearchAutoCompleteProps {
  locationDetectionPermitted: boolean | undefined
  detectedLocation?: GeoLocationInterface | undefined
}

export const DeliveryAddressSearchAutoComplete = ({ detectedLocation }: DeliveryAddressSearchAutoCompleteProps) => {
  const {
    hideComponents,
    clearSearchInput,
    onClearInputComplete,
    shouldShowMap,
    deliveryAddressChange,
    deliveryAddress,
    currentError,
    setCurrentError,
    viewMode,
    toggleViewMode,
    navigationAction,
    setAddressInput,
  } = useDeliveryAddressSearchAutoCompleteContainer()

  const mapUseAbleAreaElement = useRef<HTMLDivElement>(null)
  const [mapViewAbleArea, setMapViewAbleArea] = useState<IMapViewableArea | undefined>(undefined)

  useEffect(() => {
    if (!mapUseAbleAreaElement.current) return

    const element = mapUseAbleAreaElement.current
    const resizeObserver = new ResizeObserver(() => {
      const rect = element.getBoundingClientRect()
      setMapViewAbleArea({
        width: rect.width,
        height: rect.height + 8, // covers padding from missing search element
        positionTop: rect.top,
        positionLeft: rect.left,
      })
    })
    resizeObserver.observe(element)

    return () => {
      resizeObserver.unobserve(element)
      resizeObserver.disconnect()
    }
  }, [mapUseAbleAreaElement])

  return (
    <div
      data-testid={testID}
      className={css.deliveryAddressMapViewWrapper}
      style={hideComponents ? { display: 'none' } : undefined}
    >
      {shouldShowMap && (
        <DeliverySearchMap
          deliveryAddress={deliveryAddress}
          deliveryAddressChange={deliveryAddressChange}
          setCurrentError={setCurrentError}
          mapViewableArea={mapViewAbleArea}
          currentError={currentError}
        />
      )}
      <div className={css.deliveryAddressComponentsWrapper}>
        <div className={css.addressSearchContainer}>
          <DeliveryAutoCompleteSearch
            testID={`${testID}.delivery-address-search`}
            viewMode={viewMode}
            toggleViewMode={toggleViewMode}
            deliveryAddressChange={deliveryAddressChange}
            location={detectedLocation!}
            deliveryAddress={deliveryAddress}
            currentError={currentError}
            setCurrentError={setCurrentError}
            isLoading={false}
            navigationAction={navigationAction}
            resetInput={clearSearchInput}
            onResetInputComplete={onClearInputComplete}
            setAddressInput={setAddressInput}
          />
        </div>
        <div
          className={css.mapUseAbleAreaContainer}
          ref={mapUseAbleAreaElement}
          style={!shouldShowMap ? { display: 'none' } : undefined}
        />
        <DeliverySearchActionConfirmContainer
          deliveryAddressChange={deliveryAddressChange}
          deliveryAddress={deliveryAddress}
          hideComponent={false} // TODO need figure out who controls visibility?
          currentError={currentError}
          setCurrentError={setCurrentError}
          viewMode={viewMode}
          navigationAction={navigationAction}
        />
      </div>
    </div>
  )
}
