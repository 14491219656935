import React from 'react'
import {
  createMyRewardsBalanceWidgetViewModel,
  MyRewardsBalanceWidget,
  useCustomerRewardStore,
} from 'olo-feature-loyalty'
import { useTranslation } from 'react-i18next'
import { useCustomer, useFeatures } from '@dominos/hooks-and-hocs'
import { RewardBalance } from '../../my-account/my-rewards/reward-balance'
import { useApolloClient } from '@apollo/client'
import { useApplyCoupon } from './useApplyCoupon'

export const LoyaltyBalanceCard = ({ testID }: { testID?: string }) => {
  const { customer } = useCustomer()
  const { t } = useTranslation('loyalty')
  const { featureEnabled } = useFeatures()
  const [loyaltyV2Enabled] = featureEnabled('LoyaltyV2Enabled')
  const applyCoupon = useApplyCoupon()

  const customerRewardStore = useCustomerRewardStore({
    loyaltyV2Enabled,
    client: useApolloClient(),
    customerId: customer?.id,
  })

  if (!loyaltyV2Enabled) return <RewardBalance testID={testID || ''} balance={customer?.loyalty?.balance} />

  const viewModel = createMyRewardsBalanceWidgetViewModel({
    t,
    customerRewardStore,
    applyCoupon,
  })

  return <MyRewardsBalanceWidget viewModel={viewModel} />
}
