import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getImages } from '@dominos/business/functions/menu'
import { formatSuperscript } from '@dominos/business/functions/text/format-superscript'
import { ActionButton } from '@dominos/components'
import { useDominosTheme, useFeatures } from '@dominos/hooks-and-hocs'
import { useMenuStyles } from '../menu-card.styles'
import { MenuSetCardProps } from './menu-set-card.interfaces'
import css from './menu-set-card.less'

export const MenuSetCard = ({ testID, item, children, addToBasket }: MenuSetCardProps) => {
  const styles = useMenuStyles(!!children)
  const { t } = useTranslation('menu')
  const {
    colours: { actionGreen, actionGreenBackgound },
    textSizeButton,
  } = useDominosTheme()
  const { featureEnabled } = useFeatures()

  const imageUrl = getImages(item).menuImage.fullUrl
  const [leftNavMenuEnabled] = featureEnabled('LeftNavMenu')

  const onAddToBasket = () => {
    addToBasket?.(item.code)
  }

  return (
    <div
      data-testid={`${testID}`}
      className={classnames(css.card, leftNavMenuEnabled && css.leftNavMenu)}
      style={styles.card}
    >
      <div>
        <div className={css.imageContainer}>
          <img
            data-testid={`${testID}.image`}
            onClick={onAddToBasket}
            className={css.image}
            style={styles.image}
            src={imageUrl}
            alt=''
          />
        </div>

        <div className={css.cardContent} style={styles.cardContent}>
          <p data-testid={`${testID}.title`} className={css.title}>
            {(item.media && item.media.name) || ''}
          </p>

          <p data-testid={`${testID}.description`} className={css.description}>
            {item.media?.description ? formatSuperscript(item.media.description) : ''}
          </p>
        </div>
      </div>
      <div data-testid='menu-set-card.children' className={css.selectors}>
        <ActionButton
          testID={`${testID}.add`}
          onPress={onAddToBasket}
          text={t('Add')}
          textFontWeight='bold'
          containerStyle={{
            width: '100%',
            minHeight: 30,
            padding: 0,
            backgroundColor: actionGreenBackgound,
          }}
          textStyle={{ color: actionGreen, fontSize: textSizeButton }}
        />
      </div>
    </div>
  )
}
