/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentOrderDetails, useFeatures, useReport, useSocialAuth } from '@dominos/hooks-and-hocs'
import { AppleButton } from './apple-button'
import { FacebookButton } from './facebook-button'
import { GoogleButton } from './google-button'
import { EmailButton } from './email-button'
import { isNativeApp, sendTokenToNativeApp } from '@dominos/business/functions/native-app'
import { SocialProviders, SocialUserDetails, UniversalAuthProps } from './auth-button-group.interface'
import { ErrorInline, ErrorScope, getStatusReasonFromApolloError, useErrorContext } from '../error'
import { loginErrors } from './login-errors'
import { AppleIdAuthFailureError } from './apple-button/apple-button.interface'
import { useLocation } from '@reach/router'
import { SocialLoginError } from './auth-button-group.interface'

import css from './auth-button-group.less'
import { getAppleSpecificLoggingPayload, getCreateAccountSceneProps } from './helper'

const IGNORED_ERRORS: (AppleIdAuthFailureError | string)[] = ['popup_closed_by_user']

export const AuthenticationButtonGroup = ({
  testID,
  onPopupBlocked,
  onEmail,
  onRegister,
  onComplete,
}: UniversalAuthProps) => {
  const { t } = useTranslation('login')
  const location = useLocation()
  const { notifyError } = useErrorContext()
  const { reportRegistration, reportLogin } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const [loginFailed, setLoginFailed] = useState(false)
  const nativeApp = isNativeApp()
  const enableLongLived = nativeApp
  const { featureEnabled } = useFeatures()
  const [appleAuthEnabled, fbAuthEnabled, googleAuthEnabled] = featureEnabled(
    'apple-auth',
    'facebook-auth',
    'google-login',
  )

  const handleSuccessCallback = (
    provider: BffContext.SocialLogins.Providers,
    customerInfo: Bff.SocialLogins.SocialLogin,
    userDetails: SocialUserDetails,
  ) => {
    if (customerInfo.customerId) {
      reportLogin({
        enableLongLived,
        order_id: orderId,
        customerId: customerInfo.customerId,
        status: 'success',
        url: location.href,
        identityProvider: provider,
        authenticationSource: 'UniversalAuth',
      })

      provider === 'Apple' && appleAuthEnabled && nativeApp
        ? sendTokenToNativeApp(customerInfo.idToken)
        : onComplete(provider)
    } else {
      const { customerId, email, firstName, lastName, identityProviderId } = customerInfo
      reportRegistration({
        success: false,
        orderId,
        customerId,
        identityProvider: provider,
        note: 'Creating new account',
        authenticationSource: 'UniversalAuth',
        url: location.href,
        payload: getAppleSpecificLoggingPayload(provider, customerInfo, userDetails),
      })

      const createAccountSceneProps = {
        provider,
        name: userDetails?.name,
        email: userDetails?.email ?? email,
        id: userDetails?.identityProviderId ?? identityProviderId,
      }
      if (!createAccountSceneProps.name && firstName && lastName) {
        createAccountSceneProps.name = `${firstName} ${lastName}`
      }
      onRegister(getCreateAccountSceneProps(createAccountSceneProps))
    }
  }

  const { signIn, authCustomerInfo, provider, pending, error } = useSocialAuth(handleSuccessCallback)

  const handleSignIn = async (
    provider: SocialProviders,
    providerToken: string,
    additionalDetails?: SocialUserDetails,
  ) => await signIn({ orderId, enableLongLived, providerToken, provider }, additionalDetails ?? {})

  const handleReady = (provider?: SocialProviders | 'Email') => {
    setLoginFailed(false)

    if (provider === 'Email') {
      onEmail()
    }
  }

  const handleError = (identityProvider: SocialProviders, reason?: string, error?: string) => {
    setLoginFailed(true)

    if (reason === SocialLoginError.PopupBlocked) {
      onPopupBlocked()
    }

    if (reason && !IGNORED_ERRORS.includes(reason)) {
      reportLogin({
        enableLongLived,
        status: 'fail',
        url: location.href,
        order_id: orderId,
        customerId: authCustomerInfo?.customerId,
        identityProvider,
        status_reason: reason,
        status_error: error,
        authenticationSource: 'UniversalAuth',
      })
    }
  }

  useEffect(() => {
    if (error) {
      const statusReason = getStatusReasonFromApolloError(error)

      if (statusReason && provider) {
        reportLogin({
          enableLongLived,
          status: 'fail',
          order_id: orderId,
          customerId: authCustomerInfo?.customerId,
          url: location.href,
          authenticationSource: 'UniversalAuth',
          identityProvider: provider,
          status_reason: statusReason,
        })
      }

      notifyError({
        error,
        definitions: loginErrors,
        handlers: {},
        scope: ErrorScope.CreateAccount,
      })
    }
  }, [error])

  return (
    <div data-testid={testID}>
      <div data-testid={`${testID}.providers`} className={css.group}>
        <EmailButton testID={`${testID}.email`} onReady={handleReady} onToken={handleSignIn} onError={handleError} />

        {appleAuthEnabled ? (
          <AppleButton
            testID={`${testID}.apple`}
            loading={provider === 'Apple' && pending}
            onToken={handleSignIn}
            onReady={handleReady}
            onError={handleError}
          />
        ) : null}

        {fbAuthEnabled ? (
          <FacebookButton
            testID={`${testID}.facebook`}
            customer={authCustomerInfo}
            loading={provider === 'Facebook' && pending}
            onToken={handleSignIn}
            onReady={handleReady}
            onError={handleError}
          />
        ) : null}

        {googleAuthEnabled ? (
          <GoogleButton
            testID={`${testID}.google`}
            loading={provider === 'Google' && pending}
            onToken={handleSignIn}
            onReady={handleReady}
            onError={handleError}
          />
        ) : null}
      </div>
      {loginFailed && (
        <ErrorInline
          testID={`${testID}.error`}
          message={t('CreateAccountGenericErrorText', { defaultValue: 'Something has gone wrong. Please try again' })}
        />
      )}
    </div>
  )
}
