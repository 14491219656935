import { ApolloLink } from '@apollo/client'
import { getState } from '@dominos/hooks-and-hocs/application'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'
import { akamaiCacheStatusHandler, dpeTokenHandler } from './handlers'

export const afterwareResponseLink = (securitySender: SecuritySender) =>
  new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
      // handle response data
      const context = operation.getContext()
      const {
        response: { headers },
      } = context
      const state = getState()

      if (headers) {
        dpeTokenHandler(headers, operation, securitySender)
        akamaiCacheStatusHandler(headers, operation, securitySender, state.featureReducer.features)
      }

      return response
    }),
  )
