import React, { useEffect, useMemo, useState } from 'react'
import { useFeatures, useStartOrder } from '@dominos/hooks-and-hocs'
import { OrderLaterContainer } from './order-later-container'
import { OrderNowContainer } from './order-now-container'
import { StoreDetailsContainer } from './store-details-container'
import externalStyles from './timed-order-picker-container.less'
import { TimedOrderPickerContainerProps } from '../interfaces'
import { OrderLaterOrNowContainer } from './order-later-now-container'

export const TimedOrderPickerContainer = (props: TimedOrderPickerContainerProps) => {
  const [orderNowExpanded, setOrderNowExpanded] = useState<boolean>(props.isAsapAvailable)
  const [orderLaterExpanded, setOrderLaterExpanded] = useState<boolean>(!props.isAsapAvailable)
  const { getBooleanVariation } = useFeatures()
  const isSimplifiedOrderTimeEnabled = getBooleanVariation('SimplifiedOrderTimeEnabled')
  const { startOrder } = useStartOrder()

  useEffect(() => {
    setOrderNowExpanded(props.isAsapAvailable)
    setOrderLaterExpanded(!props.isAsapAvailable)
  }, [props.isAsapAvailable])

  const toggleCards = () => {
    setOrderNowExpanded(!orderNowExpanded)
    setOrderLaterExpanded(!orderLaterExpanded)
  }

  const hasDayOfTradeTimeSlots = useMemo(
    () => props.openingHours && props.openingHours?.dayOfTradeTimeSlots?.length > 0,
    [props.openingHours],
  )

  const onStartOrderAction = (selectedTime?: TimeSlotAvailability) => {
    startOrder(selectedTime, props.openingHours)
  }

  return (
    <section className={externalStyles.container}>
      <StoreDetailsContainer {...props} testID={'store-details'} />
      {isSimplifiedOrderTimeEnabled && (
        <OrderLaterOrNowContainer
          {...props}
          onStartOrderAction={onStartOrderAction}
          hasDayOfTradeTimeSlots={hasDayOfTradeTimeSlots}
          testID={'OrderLaterOrNowContainer'}
        />
      )}
      {!isSimplifiedOrderTimeEnabled && (
        <>
          {props.isAsapAvailable && (
            <OrderNowContainer
              {...props}
              onStartOrderAction={onStartOrderAction}
              onToggleExpanded={toggleCards}
              expanded={orderNowExpanded}
              testID={'OrderNowContainer'}
            />
          )}
          {hasDayOfTradeTimeSlots && (
            <OrderLaterContainer
              {...props}
              onStartOrderAction={onStartOrderAction}
              onToggleExpanded={toggleCards}
              expanded={orderLaterExpanded}
              testID={'OrderLaterContainer'}
            />
          )}
        </>
      )}
    </section>
  )
}
