import { createSendFosEvents } from 'olo-feature-fos'
import { createFosStoreMapper } from './mappers/create-fos-store-mapper'
import { createFosCustomerMapper } from './mappers/create-fos-customer-mapper'
import { createFosCurrentOrderMapper } from './mappers/create-fos-current-order-mapper'
import { createFosLocaleMapper } from './mappers/create-fos-locale-mapper'
import { createSendGraphQLMutation } from './create-send-graphql-mutation'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'
import { createFosSessionMapper } from './mappers/create-fos-session-mapper'

export const createFosEventsHandler = (
  rootReducer: RootReducer,
  securitySender: SecuritySender,
  isEnabledFallback?: boolean | undefined,
) =>
  createSendFosEvents({
    isEnabled: () => shouldSendFosEvents(rootReducer, isEnabledFallback),
    mappers: {
      getStore: createFosStoreMapper(rootReducer),
      getCustomer: createFosCustomerMapper(rootReducer),
      getCurrentOrder: createFosCurrentOrderMapper(rootReducer),
      getLocale: createFosLocaleMapper(rootReducer),
      getSession: createFosSessionMapper(rootReducer),
    },
    transport: createSendGraphQLMutation(rootReducer, securitySender),
  })

export const shouldSendFosEvents = (rootReducer: RootReducer, isEnabledFallback?: boolean) =>
  (rootReducer.featureReducer.features?.find((f) => f.key === 'IsFosVersionThreeEnabled')?.value ??
    `${isEnabledFallback}`.toLowerCase()) === 'true' && !rootReducer.currentOrderDetailsReducer.advanceOrder.endedAt
