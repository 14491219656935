type VoucherMenuItem = Bff.Menu.old.VoucherMenuItem
type PortionMenuItem = Bff.Menu.old.PortionMenuItem

export const isMenuSet = (item: MenuItemDependents): item is VoucherMenuItem =>
  (item as VoucherMenuItem).type === 'Voucher'

export const isProductMenuItem = (item: MenuItemDependents): item is ProductMenuItem =>
  'code' in item && item.type === 'Product'

export const isProductMenuItemNew = (item: Bff.Menu.old.MenuItemDependents): item is Bff.Menus.ProductMenuItem =>
  'productCode' in item && item.type === 'Product'
export const isPortionMenuItem = (item: MenuItemDependents): item is PortionMenuItem => item.type === 'Portion'
export const isHalfNHalfEnabled = (item: MenuItemDependents): item is ProductMenuItem =>
  (item as ProductMenuItem)?.halfNHalfEnabled === true

export const isDimensionalPortionProduct = (
  product?: Bff.Products.ProductBase<Bff.Dimensions.DimensionSet>,
): product is Bff.Products.PortionProduct<Bff.Dimensions.DimensionSet> => product?.type === 'Portion'

export const isDimensionalRecipeProduct = (
  product?: Bff.Products.ProductBase<Bff.Dimensions.DimensionSet>,
): product is Bff.Products.RecipeProduct<Bff.Dimensions.DimensionSet> => product?.type === 'Recipe'

export const isSearchableMenuItem = (item: Bff.Menu.old.MenuItemDependents): item is ProductMenuItem => 'media' in item

export const isVarietyMenuItem = (item: Bff.Menu.old.MenuItemDependents): item is Bff.Menu.old.VarietyMenuItem =>
  item.type === 'Variety'

export const isProductSizeWithOptions = (size: ProductSize | PortionSize): size is ProductSize =>
  (size?.swaps && 'options' in size?.swaps) ?? false
