import React from 'react'

import { useBreakpoints, useKiosk, useSpotlightContext } from '@dominos/hooks-and-hocs'
import { SPOTLIGHT_KEY_ORDER_DETAILS_PILL } from '@dominos/components/spotlight'
import classnames from 'classnames'

import css from './container-layout.less'

const NAV_HEIGHT_MOBILE = 95
const DESKTOP_BASKET_OFFSET = '0px'

const ContainerLayout = (props: ContainerLayoutProps) => {
  const { isMobile, isNotMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const { isSpotlightOn } = useSpotlightContext()
  const { testID, children, sidebar, isSidebarLayout, isMenuHidden, leftNavMenu } = props

  const headerOffset = isMenuHidden ? NAV_HEIGHT_MOBILE : DESKTOP_BASKET_OFFSET

  return (
    <>
      <div
        data-testid={`${testID}.wrapper`}
        className={
          !isKioskOrder && isNotMobile && (sidebar || isSidebarLayout) ? css.wrapper : css.withoutSidebarWrapper
        }
      >
        <div
          data-testid={`${testID}.grid`}
          className={classnames(
            isMobile || isKioskOrder ? css.withoutSidebar : css.withSidebar,
            leftNavMenu && css.leftNavMenu,
          )}
        >
          {children}
        </div>

        {!isKioskOrder && isNotMobile && (sidebar || isSidebarLayout) ? (
          <div data-testid={`${testID}.sidebar.wrapper`} className={css.sidebarWrapper}>
            <div
              className={isSpotlightOn(SPOTLIGHT_KEY_ORDER_DETAILS_PILL) ? css.relativeWrapper : css.stickyWrapper}
              style={{
                top: isMenuHidden ? 'auto' : headerOffset,
              }}
            >
              {sidebar}
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export { ContainerLayout }
