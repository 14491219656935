import gql from 'graphql-tag'

export const featuresQuery = gql`
  query featuresQuery($userId: ID) {
    featureToggles(userId: $userId) {
      key
      type
      value
    }
  }
`
