import React from 'react'
import { useFeatures } from '@dominos/hooks-and-hocs/features'
import { AddressFeatureContext, IAddressFeatureCommonProviders } from 'olo-feature-address'
import {
  useAddressProviderCustomer,
  useAddressProviderCustomerAddresses,
  useAddressProviderDispatch,
  useAddressProviderGraphCaller,
  useAddressProviderLanguage,
  useAddressProviderLogger,
  useAddressProviderPageNavigation,
  useAddressProviderSelectStore,
} from './address-provider-hooks'
import { getCountryConfig } from '../../../business/functions'
import { useAddressProviderTranslation } from './address-provider-hooks'

export const AddressProvider: React.FC = ({ children }) => {
  const providers: IAddressFeatureCommonProviders = {
    useFeatures: useFeatures,
    useLogger: useAddressProviderLogger,
    useCustomer: useAddressProviderCustomer,
    useLanguage: useAddressProviderLanguage,
    countryConfig: getCountryConfig,
    useSourceDispatch: useAddressProviderDispatch,
    useCustomerDeliveryAddresses: useAddressProviderCustomerAddresses,
    usePageNavigation: useAddressProviderPageNavigation,
    useSelectStore: useAddressProviderSelectStore,
    useTranslations: useAddressProviderTranslation,
    useGraphCaller: useAddressProviderGraphCaller,
  }

  return <AddressFeatureContext.Provider value={providers}>{children}</AddressFeatureContext.Provider>
}
