import React from 'react'
import { FieldWidth, IAddressInputFieldProps, useAddressFieldComponent } from 'olo-feature-address'
import { ValidationTextField } from '@dominos/components/inputs/validation-text-field/validation-text-field'

export const AddressField = (props: IAddressInputFieldProps) => {
  const {
    inputWidth,
    initialValue,
    addressAttributeName,
    attributeLabel,
    fieldStatus,
    fieldValidationFunctions,
    testId,
    onChange,
  } = useAddressFieldComponent(props)

  const onFiledChange = (updatedField: { [K: string]: ValidationField }) => {
    const validationField = updatedField[addressAttributeName]
    onChange({
      attributeName: addressAttributeName,
      value: validationField.value ?? '',
      isValid: validationField.isValid,
    })
  }

  if (fieldStatus == 'hide') return <></>

  const style = getStyles(inputWidth)

  return (
    <ValidationTextField
      testID={testId}
      fieldName={addressAttributeName}
      placeholder={attributeLabel}
      hasClearInputButton={fieldStatus !== 'disabled'}
      hideEmptyErrorContainer
      onChange={onFiledChange}
      validationRules={fieldValidationFunctions}
      initialValue={initialValue}
      allowInitialValueResetWhenUndefined={true}
      style={style.textFieldContainer}
      showAsteriskWhenRequired={true}
      disabled={fieldStatus == 'disabled'}
    />
  )
}

interface Style {
  textFieldContainer: React.CSSProperties
}

const getStyles = (inputWidth: FieldWidth): Style => {
  const width = inputWidth === 'Full' ? '0 0 100%' : inputWidth === 'Half' ? '0 0 48%' : '0 0 31%'

  return {
    textFieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: width,
    },
  }
}
