import { ApolloError } from '@apollo/client'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'
import { GenericCard } from '@dominos/components'
import {
  OrderStatus,
  SuccessfulOrderStatus,
  useBasketTotal,
  useFeatures,
  useLocationExtended,
  useNativeAppStoredData,
  useOrderStatus,
  usePlaceOrder,
  useReport,
} from '@dominos/hooks-and-hocs'
import OrderModel from '@dominos/hooks-and-hocs/order/model/order-model'
import { NavigationConstants } from '@dominos/navigation'
import { StaticSvgIcon } from '@dominos/res'
import { navigate, RouteComponentProps } from '@reach/router'
import { useErrorContext } from '@dominos/components/error'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getProcessingTranslations } from './get-processing-translations'
import { createPaymentDetails, getPropertiesFromQueryString } from './helper'
import css from './processing-container.less'
import { useProcessingErrorHandlers } from './use-processing-error-handlers'
import { getNotifiableEventData } from './get-notifiable-event-data'
import { HandledByAdditionalDetails } from './../payment-container/payment-method/adyen'

const OrderStatusPollingInterval = 1000
const MaxPollingRetryAttempts = 3
const MaxPlaceOrderRetryAttempts = 2
const RecoverableFailureStatuses = ['PaymentFailed'] as OrderStatus[]
const UnrecoverableFailureStatuses = ['Cancelled', 'Failed'] as OrderStatus[]

export const getTransactionToken = (search: string): string | undefined => {
  const params = new URLSearchParams(search)

  return params?.get('token') ?? undefined
}

// eslint-disable-next-line max-lines-per-function
const ProcessingContainer = ({
  orderId,
  orderPaymentId,
}: RouteComponentProps<{ orderId: string; orderPaymentId: string }>) => {
  // TODO: these data stored in redux store are not reliable, when 3rd party payment redirection happened
  // they'll be all lost. Need a better way to repopulate them safely
  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)
  const { retrieveNativeAppPurchaseEvent, retrieveNativeAppPaymentIds, removeNativeAppPurchaseEventAndPaymentIds } =
    useNativeAppStoredData()
  const { search, searchParams } = useLocationExtended()
  const transactionToken = useSelector(
    (state: RootReducer) => state.currentOrderDetailsReducer.transactionToken || getTransactionToken(search),
  )
  const paymentId = useSelector((state: RootReducer) => state.currentOrderDetailsReducer.orderPaymentId)
  const { orderId: nativeAppOrderId, orderPaymentId: nativeAppOrderPaymentId } = retrieveNativeAppPaymentIds()
  const id = orderId || basketData.id || nativeAppOrderId
  const pid = orderPaymentId || paymentId || nativeAppOrderPaymentId
  const retryCount = useRef(0)
  const retryPlaceOrderCount = useRef(0)

  const total = useBasketTotal()
  const { reportRedirectBack, reportOrderStatus, reportPurchase } = useReport()
  const { notifyError } = useErrorContext()
  const {
    addInitialStatusHandler,
    addStatusHandler,
    addUnknownStatusHandler,
    addNetworkErrorHandler,
    addGqlErrorHandler,
    startPolling,
    stopPolling,
  } = useOrderStatus(id)
  const isOrderPlacedRef = useRef(false)
  const isPurchaseReportedRef = useRef(false)

  const { featureEnabled } = useFeatures()
  const [doubleDecodeEnabled] = featureEnabled('DoubleDecodeAdyenRedirectResult')

  const { t } = useTranslation('checkout')
  const messages = getProcessingTranslations(t)

  const handlers = useProcessingErrorHandlers(id)

  const resetRetryCount = () => {
    retryCount.current = 0
  }

  const handleUnknownFailure = (response: { orderModel?: OrderModel; error?: ApolloError }) => {
    reportOrderStatus(response.orderModel?.status, retryCount.current)

    if (retryCount.current === 0) {
      startPolling(OrderStatusPollingInterval)
    }

    if (retryCount.current >= MaxPollingRetryAttempts) {
      handleRecoverableFailure(response)
    }

    retryCount.current++
  }

  const handleRecoverableFailure = (response: { orderModel?: OrderModel; error?: ApolloError }) => {
    if (!response.error?.networkError) {
      stopPolling()
    }
    notifyError(getNotifiableEventData(response, handlers))
  }

  const handlePlaceOrderError = (error: ApolloError) => {
    if (!error?.networkError || retryPlaceOrderCount.current >= MaxPlaceOrderRetryAttempts) {
      stopPolling()
      notifyError(getNotifiableEventData({ error }, handlers))
    } else if (error?.networkError) {
      retryPlaceOrderCount.current++
      setTimeout(() => (isOrderPlacedRef.current = false), OrderStatusPollingInterval)
    }
  }

  const handleUnrecoverableFailure = (response: { orderModel?: OrderModel; error?: ApolloError }) => {
    response.orderModel?.verifyIfStatusExist()
    stopPolling()
    notifyError(getNotifiableEventData(response, handlers))
  }

  const { placeOrder } = usePlaceOrder({
    handlePlaceOrderError,
    onOrderConfirmed: () => {},
  })

  const warnUserBeforeLeaving = (e: Event) => {
    // returnValue only accepts boolean in the official spec
    // but some browsers allow a string to be passed in for the prompt
    const retVal = messages.orderInterrupted as unknown as boolean

    // prevents Firefox displaying the confirm by default
    e.preventDefault()

    // Chrome requires a string value to be set to trigger
    // the 'are you sure' dialogue
    e.returnValue = retVal
  }

  const initialStatusHandler = ({ orderModel }: { orderModel?: OrderModel }) => {
    const paymentDetail = orderModel?.getCurrentPaymentDetailById(pid)
    if (!paymentDetail) {
      return
    }

    const { paymentMethod, providerCode } = paymentDetail
    if (search && !searchParams.has(HandledByAdditionalDetails)) {
      reportRedirectBack({
        transactionToken,
        paymentMethod,
        providerCode,
        orderPaymentId,
        hasCurrentOrder: isOrderPlacedRef.current,
        properties: getPropertiesFromQueryString(providerCode, search),
        amount: total!,
        userAgent: window.navigator.userAgent,
        urlOrderId: orderId,
      })
    }
    if (paymentMethod !== 'PayPal' && providerCode !== 'GMO' && search === '') {
      isOrderPlacedRef.current = true
    }
  }

  // This is a hacky interim fix to address Adyen's double encoded redirectResult
  const getQueryString = (paymentProvider: BffContext.PaymentProviders) => {
    if (paymentProvider !== 'Adyen' || !doubleDecodeEnabled || !searchParams.get('redirectResult')?.includes('%21')) {
      return search
    }

    const indexOfHash = search.indexOf('%23')

    return decodeURIComponent(search.substring(0, indexOfHash > 0 ? indexOfHash : undefined))
  }

  useEffect(() => {
    resetRetryCount()
    notifyNativeApp('processing')
    window.addEventListener('beforeunload', warnUserBeforeLeaving)

    addInitialStatusHandler(initialStatusHandler)
    addStatusHandler(OrderStatus.New, () => startPolling(OrderStatusPollingInterval))
    addStatusHandler(OrderStatus.Pending, ({ orderModel }) => {
      const paymentDetail = orderModel?.getCurrentPaymentDetailById(pid)
      if (!isOrderPlacedRef.current && paymentDetail) {
        const finalPaymentDetails = createPaymentDetails(
          paymentDetail,
          getPropertiesFromQueryString(paymentDetail.providerCode, getQueryString(paymentDetail.providerCode)),
          transactionToken,
          pid,
        )
        placeOrder(id, finalPaymentDetails)
        isOrderPlacedRef.current = true
      }
      startPolling(OrderStatusPollingInterval)
    })
    addStatusHandler(OrderStatus.Unknown, handleUnknownFailure)
    addStatusHandler(RecoverableFailureStatuses, handleRecoverableFailure)
    addStatusHandler(UnrecoverableFailureStatuses, handleUnrecoverableFailure)
    addUnknownStatusHandler(handleUnrecoverableFailure)
    addGqlErrorHandler(handleUnrecoverableFailure)
    addNetworkErrorHandler(handleRecoverableFailure)
    addStatusHandler(SuccessfulOrderStatus, () => {
      if (!isPurchaseReportedRef.current) {
        reportPurchase(retrieveNativeAppPurchaseEvent(), () => {
          isPurchaseReportedRef.current = true
        })
      }

      stopPolling()
      removeNativeAppPurchaseEventAndPaymentIds()
      // There is an issue where on mobile user can be redirected to an app for payment authorisation
      // and then be redirected back to a different (default) browser. Using deep linking url will
      // ensure we rehydrate the order in that scenario.
      navigate(
        isNativeApp()
          ? `${NavigationConstants.nativeAppTracker}?order=${id}`
          : `${NavigationConstants.tracker}?order=${id}`,
      )
    })

    return () => {
      window.removeEventListener('beforeunload', warnUserBeforeLeaving)
    }
  }, [])

  return (
    <div className={css.titledCardContainer} data-testid='processing-container'>
      <GenericCard testID='order-status' title={t('Processing Order Title')}>
        <div className={css.loadingIcon}>
          <StaticSvgIcon aria-hidden name={'bouncingDots'} isUnstyled={true} />
          <p className={css.description} data-testid='explanation-message'>
            {t('Processing Order Explanation Text')}
          </p>
        </div>
      </GenericCard>
    </div>
  )
}

export { ProcessingContainer }
