/* eslint-disable max-lines */
import { NOT_SELECTED_OPTION_CODE } from '@dominos/business/functions/menu'
import { isPortionMenuItem, isProductSizeWithOptions } from '@dominos/components'
import { useCallback, useMemo, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useEffect } from 'react'

type IngredientSet = Bff.Menu.old.IngredientSet

export interface MenuItemToggles {
  basesEnabled: boolean
}

const createNotSelectedOption = (t: TFunction<'menu'>, index?: number): MenuCustomisationItem => ({
  index,
  type: 'option',
  value: NOT_SELECTED_OPTION_CODE,
  label: t('NotSelectedOption'),
  ingredient: {
    code: NOT_SELECTED_OPTION_CODE,
    media: {
      name: t('NotSelectedOption'),
    },
  },
})
// eslint-disable-next-line max-lines-per-function
export const useProductMenuItemCustomisations = (
  menuItem: ProductMenuItem | PortionMenuItem,
  menuItemToggles: MenuItemToggles,
) => {
  const { t } = useTranslation('menu')

  const defaultCustomisations = useMemo<MenuCustomisationItem[]>(() => {
    const result: MenuCustomisationItem[] = []

    const defaultSize = isPortionMenuItem(menuItem) ? getPortionDefaultSize(menuItem) : getProductDefaultSize(menuItem)

    const defaultSizeItem = {
      type: 'size',
      value: defaultSize?.code,
      label: defaultSize?.media.name,
    } as MenuCustomisationItem
    result.push(defaultSizeItem)

    if (menuItemToggles.basesEnabled) {
      const defaultBaseItem = {
        type: 'base',
        value: defaultSize?.recipe?.base?.code,
        label: defaultSize?.recipe?.base?.media?.name,
        ingredient: defaultSize?.recipe?.base,
      } as MenuCustomisationItem
      result.push(defaultBaseItem)
    }

    if (defaultSize && isProductSizeWithOptions(defaultSize)) {
      const swapsOptions = defaultSize?.swaps?.options
      pushDefaultOptions(swapsOptions, defaultSize, result, t)
    }

    const defaultQuantityItem = {
      type: 'quantity',
      value: '1',
      label: '1',
    } as MenuCustomisationItem
    result.push(defaultQuantityItem)

    return result
  }, [menuItem])

  const [selectedSize, setSelectedSize] = useState<MenuCustomisationItem>(
    defaultCustomisations.find((c) => c.type === 'size')!,
  )
  const [selectedBase, setSelectedBase] = useState<MenuCustomisationItem>(
    defaultCustomisations.find((c) => c.type === 'base') ?? {
      type: 'base',
      value: '',
      label: '',
      ingredient: undefined,
    },
  )
  const [selectedOptions, setSelectedOptions] = useState<MenuCustomisationItem[]>(
    defaultCustomisations.filter((c) => c.type === 'option'),
  )
  const [selectedQuantity, setSelectedQuantity] = useState<MenuCustomisationItem>(
    defaultCustomisations.find((c) => c.type === 'quantity')!,
  )

  const currentAvailableCustomisations = useMemo<MenuCustomisationItem[][]>(() => {
    const result: MenuCustomisationItem[][] = []

    if (isPortionMenuItem(menuItem)) {
      return result
    }

    const sizes = getAllSelectableSizes(menuItem)
    const currentSize = menuItem.sizes?.find((size) => size.code === selectedSize.value) || undefined
    const bases = getBasesForSize(menuItem, currentSize)
    const options = getOptionsForSize(menuItem, currentSize)
    const quantities = getQuantities()
    const newDefaultBase = !!currentSize && getDefaultBaseForSize(currentSize)

    if (newDefaultBase && selectedBaseIsNotDefault(newDefaultBase, selectedBase)) {
      setSelectedBase(newDefaultBase)
    }

    if (sizes.length > 0 && sizes[0].value) {
      result.push(sizes)
    }
    if (bases.length > 0 && menuItemToggles.basesEnabled) {
      result.push(bases)
    }
    if (options.length > 0 && currentSize?.swaps?.options) {
      for (let i = 0; i < currentSize.swaps.options.rule.max; i++) {
        const selectableOptions: MenuCustomisationItem[] = []

        if (currentSize.swaps.options.rule.min <= i) {
          selectableOptions.push(createNotSelectedOption(t, i))
        }
        selectableOptions.push(...options.map((op) => ({ ...op, index: i })))

        result.push(selectableOptions)
      }
    }
    if (quantities.length > 0) {
      result.push(quantities)
    }

    return result
  }, [selectedSize, menuItem])

  const setSize = useCallback(
    (sizeCode: string) => {
      const flattenedAvailableCustomisations = currentAvailableCustomisations.reduce((elem1, elem2) =>
        elem1.concat(elem2),
      )
      const currentAvailableSizes = flattenedAvailableCustomisations.filter((c) => c.type === 'size')
      const userSelectedSize = currentAvailableSizes.find((cas) => cas.value === sizeCode) ?? {
        type: 'size',
        value: sizeCode,
        label: '',
      }
      setSelectedSize(userSelectedSize)
    },
    [currentAvailableCustomisations],
  )

  const setBase = useCallback(
    (base: string, cac: MenuCustomisationItem[][] = currentAvailableCustomisations) => {
      if (!menuItemToggles.basesEnabled) {
        return
      }
      const flattenedAvailableCustomisations = cac.reduce((elem1, elem2) => elem1.concat(elem2))
      const currentAvailableBases = flattenedAvailableCustomisations.filter((c) => c.type === 'base')
      const userSelectedBase = currentAvailableBases.find((cab) => cab.value === base) ?? {
        type: 'base',
        value: base,
        label: '',
        ingredient: undefined,
      }
      setSelectedBase(userSelectedBase)
    },
    [currentAvailableCustomisations],
  )

  const setOptions = useCallback(
    (option: MenuCustomisationItem) => {
      const options = selectedOptions.filter((op) => op.index !== option.index)
      options.push(option)
      setSelectedOptions(options)
    },
    [currentAvailableCustomisations, selectedOptions],
  )

  const setQuantity = useCallback((quantity: string) => {
    setSelectedQuantity({
      type: 'quantity',
      value: quantity.toString(),
      label: quantity.toString(),
    })
  }, [])

  useEffect(() => {
    resetCurrentCustomisationsToDefault()
  }, [defaultCustomisations])

  const resetCurrentCustomisationsToDefault = () => {
    const defaultSize = defaultCustomisations.find((c) => c.type === 'size') ?? {
      type: 'size',
      value: '',
      label: '',
    }
    const defaultBase = defaultCustomisations.find((c) => c.type === 'base') ?? {
      type: 'base',
      value: '',
      label: '',
      ingredient: undefined,
    }
    const defaultQuantity = defaultCustomisations.find((c) => c.type === 'quantity') ?? {
      type: 'quantity',
      value: '1',
      label: '1',
    }
    const defaultOptions = defaultCustomisations.filter((c) => c.type === 'option')

    setSelectedSize(defaultSize)
    setSelectedBase(defaultBase)
    setSelectedOptions(defaultOptions)
    setSelectedQuantity(defaultQuantity)
  }

  const currentCustomisations: MenuCustomisationItem[] = []
  if (selectedSize?.value && selectedSize.value !== '') {
    currentCustomisations.push(selectedSize)
  }
  if (selectedBase?.value && selectedBase.value !== '' && menuItemToggles.basesEnabled) {
    currentCustomisations.push(selectedBase)
  }
  if (selectedOptions) {
    currentCustomisations.push(...selectedOptions)
  }
  if (selectedQuantity) {
    currentCustomisations.push(selectedQuantity)
  }

  return {
    setSize,
    setBase,
    setOptions,
    setQuantity,
    resetCurrentCustomisationsToDefault,
    currentCustomisations,
    defaultCustomisations,
    currentAvailableCustomisations,
  }
}

const getAllSelectableSizes = (menuItem: ProductMenuItem): MenuCustomisationItem[] => {
  if (menuItem.sizes && menuItem.sizes.length) {
    // MenuCustomisationItem expects a size.code && and a size.media.name, however these can be null

    return menuItem.sizes.map(({ code, media }) => ({
      type: 'size',
      value: code!,
      label: media.name!,
    }))
  }

  return []
}

const getDefaultBaseForSize = (size: ProductSize): MenuCustomisationItem => ({
  type: 'base',
  value: size.recipe?.base?.code ?? '',
  label: size.recipe?.base?.media?.name ?? '',
  ingredient: size.recipe?.base ?? undefined,
})

const getBasesForSize = (menuItem: ProductMenuItem, selectedSize: ProductSize | undefined): MenuCustomisationItem[] => {
  const menuBases = selectedSize?.swaps?.bases?.ingredients
  if (menuBases) {
    return menuBases.map((ingredient) => ({
      ingredient,
      type: 'base',
      value: ingredient.code,
      label: ingredient.media?.name ?? '',
    }))
  }

  return []
}

const getOptionsForSize = (
  menuItem: ProductMenuItem,
  selectedSize: ProductSize | undefined,
): MenuCustomisationItem[] => {
  const menuOptions = selectedSize?.swaps?.options?.ingredients
  if (menuOptions) {
    return menuOptions.map((ingredient) => ({
      ingredient,
      type: 'option',
      value: ingredient.code,
      label: ingredient.media?.name ?? '',
    }))
  }

  return []
}

const getQuantities = () => {
  const MAX_PIZZA_QUANTITY = 10
  const pizzaQuantity: MenuCustomisationItem[] = []

  for (let index = 1; index <= MAX_PIZZA_QUANTITY; index++) {
    const element: MenuCustomisationItem = { type: 'quantity', value: index.toString(), label: index.toString() }
    pizzaQuantity.push(element)
  }

  return pizzaQuantity
}

const getProductDefaultSize = (menuItem: ProductMenuItem): ProductSize | null =>
  (menuItem.defaultSize && menuItem.sizes!.find((size) => size.code === menuItem.defaultSize)) || menuItem.sizes![0]

const getPortionDefaultSize = (menuItem: PortionMenuItem): PortionSize | null =>
  (menuItem.defaultSize && menuItem.sizes!.find((size) => size.code === menuItem.defaultSize)) || menuItem.sizes![0]

const selectedBaseIsNotDefault = (defaultBase: MenuCustomisationItem, selectedBase: MenuCustomisationItem): boolean =>
  defaultBase.value !== selectedBase.value

export const pushDefaultOptions = (
  swapsOptions: IngredientSet | null | undefined,
  defaultSize: ProductSize | null,
  result: MenuCustomisationItem[],
  t: TFunction<'menu'>,
) => {
  if (swapsOptions && swapsOptions.ingredients.length > 0) {
    const optionsDefaults: MenuCustomisationItem[] = []

    optionsDefaults.push(
      ...(defaultSize?.recipe?.options?.map(
        (option) =>
          ({
            type: 'option',
            value: option.ingredient.code,
            label: option.ingredient.media?.name || '',
            ingredient: option.ingredient,
          } as MenuCustomisationItem),
      ) || []),
    )

    for (let i = swapsOptions.rule.min; i < swapsOptions.rule.max; i++) {
      optionsDefaults.push(createNotSelectedOption(t))
    }

    optionsDefaults.forEach((option, index) => (option.index = index))

    result.push(...optionsDefaults)
  }
}
