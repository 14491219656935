import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { rootActions } from '@dominos/business'
import { FosEventInfoType, FosInteractionEventType } from 'olo-feature-fos'
import {
  Error,
  ErrorScope,
  KioskTrackerPage,
  PopUpNotification,
  SingleColumn,
  StackNavigationBase,
  TrackerPage,
  useErrorContext,
} from '@dominos/components'
import {
  OrderStatus,
  SuccessfulOrderStatus,
  useCurrentOrderDetails,
  useFeatures,
  useFos,
  useKiosk,
  useLastOrderDetails,
  useOneTrust,
  useOrder,
  useOrderId,
} from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation/navigation-constants'
import { navigate, useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { orderCancelledError, trackerErrors } from './tracker-errors'
const TrackerScene = () => {
  const { t } = useTranslation('tracker')
  const consents = useOneTrust()
  const { featureEnabled } = useFeatures()
  const [isStartUserJourneyOnMenuEnabled] = featureEnabled('StartUserJourneyOnMenu')
  const { notifyError } = useErrorContext()
  const {
    id: orderId,
    errors: orderIdError,
    order: preOrder,
  } = useOrderId({ inspectQuery: true, useIdFromBasket: true })
  const { fetchOrder, order } = useOrder()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { sendFosEvent } = useFos()
  const { isKioskOrder } = useKiosk()
  const [showPopup, setShowPopup] = useState(false)
  const currentOrderDetails = useCurrentOrderDetails()
  const { saveAsLastOrder } = useLastOrderDetails()

  useEffect(() => {
    if (orderId) {
      fetchOrder(orderId, 'cache-first')
      // needed for the case where user is taken here due to orderAlreadyPlaced error
      dispatch(rootActions.setOrderTime())
    }
    if (orderId === null) {
      navigate(NavigationConstants.home)
    }
  }, [orderId])

  useEffect(() => {
    if (preOrder === null || order === null) {
      setShowPopup(true)

      return
    }
    if (order?.status && order.status.status === OrderStatus.Cancelled) {
      notifyError({
        orderStatus: order.status.status,
        definitions: orderCancelledError,
        handlers: {},
        scope: ErrorScope.Tracker,
      })
    }

    const isSuccessfulOrderStatus = SuccessfulOrderStatus.includes(order?.status.status as OrderStatus)

    if (order?.id && !isSuccessfulOrderStatus) {
      navigate(NavigationConstants.checkoutProcessing)
    }

    const isCurrentOrder = orderId === currentOrderDetails?.orderId
    if (isCurrentOrder && isSuccessfulOrderStatus) {
      if (isStartUserJourneyOnMenuEnabled && consents.Functional) {
        saveAsLastOrder(
          {
            serviceMethod: order?.details?.header?.serviceMethod,
            storeNo: order?.store?.storeNo,
          },
          order?.details?.header.deliveryAddress,
        )
      }

      sendFosEvent({
        type: FosEventInfoType.InteractionEvent,
        locationPath: pathname,
        eventType: FosInteractionEventType.FosSessionFinalised,
      })
      dispatch(
        rootActions.orderFlowSuccess({
          orderId,
          createdOn: Date.now(),
        }),
      )
    }
  }, [preOrder, order])

  const onNotificationClose = () => {
    setShowPopup(false)
    dispatch(rootActions.resetOrder())
    navigate(NavigationConstants.home)
  }

  return (
    <StackNavigationBase testID='tracker-scene-stack'>
      <SingleColumn>
        {orderIdError && <Error tns={t} errorDefinitions={trackerErrors} error={orderIdError} />}
        {order &&
          (isKioskOrder ? (
            <KioskTrackerPage order={order} />
          ) : (
            <TrackerPage order={order} customerDetails={currentOrderDetails?.customerDetailsData} />
          ))}
      </SingleColumn>
      {showPopup ? (
        <PopUpNotification
          heading={t('OrderInvalidMessage', { defaultValue: 'Order not found' })}
          onClose={onNotificationClose}
        />
      ) : null}
    </StackNavigationBase>
  )
}

export { TrackerScene }
