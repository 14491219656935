import { motion, useSpring, useTransform, useViewportScroll } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { useBreakpoints } from '@dominos/hooks-and-hocs' //useDominosTheme
import { getFullUrl } from '@dominos/business/functions/menu/get-images'
import css from './product-card-image.less'

interface ProductCardImageProps {
  uri: string
  testID: string
}

export const ProductCardImage = ({ uri, testID }: ProductCardImageProps) => {
  const { isMobile } = useBreakpoints()

  const MARGIN_OFFSET = 50
  const IMAGE_HEIGHT = window.innerWidth * 0.6
  const isMobileLayout = isMobile
  useEffect(() => setImageHeight((ref.current && ref.current.height) || IMAGE_HEIGHT), [isMobileLayout])

  // Mobile View - image scroll fade
  const [imageHeight, setImageHeight] = useState<number>(IMAGE_HEIGHT)
  const { scrollY } = useViewportScroll()
  const yRange = useTransform(scrollY, [imageHeight - MARGIN_OFFSET, 0], [0, 1])
  const opacity = useSpring(yRange, { stiffness: 400, damping: 40 })
  const ref = useRef<HTMLImageElement>(null)

  const fullUri = getFullUrl(uri)

  return (
    <>
      <motion.img
        style={isMobileLayout ? { opacity } : { opacity: 1 }}
        ref={ref}
        data-testid={`${testID}.image`}
        className={css.image}
        src={fullUri}
        alt=''
      />
    </>
  )
}
