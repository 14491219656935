import { rootActions } from '@dominos/business'
import { countFromBasketLines } from '@dominos/business/functions/basket'
import {
  BoxedMessage,
  ContainerLayout,
  Error,
  GenericCard,
  KioskTermsAndConditions,
  MenuLegend,
  MenuSectionContainer,
  OfferManager,
  OmniBar,
  OrderButton,
  ProductCardAnimator,
  ValidationTextField,
} from '@dominos/components'
import { PopUpNotification } from '@dominos/components/notification'
import {
  useBasket,
  useBreakpoints,
  useFeatures,
  useHasTopMenuWrapped,
  useKiosk,
  useMenuCategory,
} from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { BasketScene } from '@dominos/scenes'
import { navigate } from '@reach/router'
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import menuPageErrors from './menu-page-errors'
import css from './menu-page.less'
import { getFilteredMenu } from './functions/get-filtered-menu'

// Includes 10px of padding
const NOTIFICATION_REFRESH_DELAY = 50

// eslint-disable-next-line max-lines-per-function
export const MenuPage = ({
  currentProduct,
  currentLine,
  menu,
  toggleProductCard,
  isProductVisible,
  testID,
  setSection,
  isEditing,
  swapping,
}: MenuPageProps) => {
  const dispatch = useDispatch()
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const isTopMenuHidden = useHasTopMenuWrapped()
  const menuCategory = useMenuCategory(menu?.pages[0])
  const { featureEnabled } = useFeatures()
  const [
    vspUpgradeButtonEnabled,
    vpimUpgradeButtonEnabled,
    enableMenuPlaceOrderAndShoppingCartButtons,
    storeMenuProductSearchEnabled,
    leftNavMenuEnabled,
  ] = featureEnabled(
    'VoucherSoftPinningMenuUpgradeButton',
    'VoucherPricingInMenuUpgradeButton',
    'EnableMenuPlaceOrderAndShoppingCartButtons',
    'StoreMenuProductSearch',
    'LeftNavMenu',
  )

  const { basket: basketData, error: basketError, pending: basketLoading, success } = useBasket()
  const [previousBasketQuantity, setPreviousBasketQuantity] = useState(0)
  const [notifyAddSuccess, setNotifyAddSuccess] = useState(false)
  const [refreshNotification, setRefreshNotification] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [search, setSearch] = useState('')

  const { t } = useTranslation('menu')

  // istanbul ignore next
  useEffect(() => {
    const basket = basketData
    if (basket.total !== null && basket.total >= 0) {
      const currentBasketQuantity = getQuantityOfItemsInBasket(basket)
      if (!firstLoad && currentBasketQuantity > previousBasketQuantity) {
        if (notifyAddSuccess) {
          setRefreshNotification(true)
          setNotifyAddSuccess(false)
        } else {
          setNotifyAddSuccess(true)
        }
      }
      setPreviousBasketQuantity(currentBasketQuantity)
    }
  }, [basketData.lines])

  useEffect(() => {
    setFirstLoad(false)
  })

  // istanbul ignore next
  useLayoutEffect(() => {
    let timer: NodeJS.Timeout
    if (refreshNotification) {
      timer = setTimeout(() => {
        setRefreshNotification(false)
        setNotifyAddSuccess(true)
      }, NOTIFICATION_REFRESH_DELAY)
    }

    return () => clearTimeout(timer)
  }, [refreshNotification])

  // istanbul ignore next
  useEffect(() => {
    if (isProductVisible) {
      toggleProductCardMenu()
    }
  }, [menuCategory])

  // istanbul ignore next
  const onNotificationClose = () => {
    setNotifyAddSuccess(false)
  }

  const itemCount = useMemo(() => countFromBasketLines(basketData.lines), [basketData.lines])

  // istanbul ignore next
  const handleErrorClose = () => {
    dispatch(rootActions.clearError())
  }

  // istanbul ignore next
  const toggleProductCardMenu = (menuItem?: MenuItemDependents, lineData?: BasketLineItem, swapping?: BasketLine) =>
    toggleProductCard && toggleProductCard(false, menuItem, lineData, swapping)
  const toggleProductCardBasket = (item?: MenuItemDependents, lineData?: BasketLineItem, swapping?: BasketLine) =>
    toggleProductCard && toggleProductCard(true, item, lineData, swapping)

  const basketNavigateHandler = () => {
    navigate(NavigationConstants.basket)
  }

  const searchMenu = useMemo(() => {
    if (!menu || !search) return undefined

    return getFilteredMenu(menu, search)
  }, [menu, search])

  if (!menu) {
    return null
  }

  return (
    <ContainerLayout
      testID={`${testID}.menu-data-container`}
      sidebar={<BasketScene onEditLine={toggleProductCardBasket} excludeNavigation={true} />}
      isMenuHidden={isTopMenuHidden}
      leftNavMenu={leftNavMenuEnabled}
    >
      {success && notifyAddSuccess ? (
        <PopUpNotification heading={t('Product Added Notification')} onClose={onNotificationClose} />
      ) : null}

      {basketError ? (
        <Error onClose={handleErrorClose} tns={t} errorDefinitions={menuPageErrors} error={basketError ?? null} />
      ) : null}

      <OfferManager locationCode={`menu.${menuCategory}`} />
      {vpimUpgradeButtonEnabled && !vspUpgradeButtonEnabled && menuCategory === 'coupon' && (
        <div style={{ paddingTop: '8px' }}>
          <BoxedMessage icon='exclamation' testID={`${testID}.menu-cta-message`} variant='contained' color='tertiary'>
            {t('MenuCallToAction', { defaultValue: 'Products marked "Upgrade" will incur an additional cost' })}
          </BoxedMessage>
        </div>
      )}

      {storeMenuProductSearchEnabled && (
        <GenericCard testID={`${testID}.menu-product-search`}>
          <ValidationTextField
            testID={testID}
            fieldName={'storeMenuProductSearch'}
            placeholder={t('StoreMenuProductSearch', { defaultValue: 'Store Menu Product Search' })}
            hasClearInputButton
            hideEmptyErrorContainer
            onChange={(test) => setSearch(Object.values(test)[0]?.value || '')}
            value={search}
          />
        </GenericCard>
      )}

      <MenuSectionContainer
        menu={searchMenu || menu}
        setSection={setSection}
        testID={testID}
        toggleProductCard={toggleProductCard}
      />
      <MenuLegend testID={'Menu-Legend-Card'} items={menu.legends} />
      {isKioskOrder && <KioskTermsAndConditions style={{ margin: '20px 0 0 0' }} />}
      {isMobile || isKioskOrder ? (
        enableMenuPlaceOrderAndShoppingCartButtons ? (
          <div className={css.omniBarContainer}>
            <OmniBar
              testID={'omni-bar.mobile'}
              itemCount={itemCount}
              price={basketData.total || 0}
              text={t('Place Order - Improved Omnibar')}
              loading={basketLoading}
            />
          </div>
        ) : (
          <div className={css.stickyButton}>
            <OrderButton
              testID={'place-order.mobile'}
              itemCount={itemCount}
              price={basketData.total || 0}
              onPress={basketNavigateHandler}
              text={!itemCount ? t('OrderButtonViewBasket', { defaultValue: 'View Basket' }) : t('View Order')}
              fill
              loading={basketLoading}
            />
          </div>
        )
      ) : null}
      <ProductCardAnimator
        testID='product-modal'
        isProductVisible={!!isProductVisible}
        onDismiss={toggleProductCardMenu}
        currentProduct={currentProduct}
        currentLine={currentLine}
        isEditing={isEditing}
        isMenuHidden={isTopMenuHidden}
        swapping={swapping}
      />
    </ContainerLayout>
  )
}

const getQuantityOfItemsInBasket = (basket: Basket) =>
  basket.lines.reduce((acc, line) => acc + (line.type === 'BasketLine' ? line.quantity : 0), 0)
