import React from 'react'
import { TFunction } from 'react-i18next'
import { ProductUpsellData } from '@dominos/components/product/product-upsell/product-upsell.interface'
import { createProductUpsellCardViewModel } from '@dominos/components/product/product-upsell/product-upsell-card/create-product-upsell-card-view-model'
import { ProductUpsellCardView } from '@dominos/components/product/product-upsell/product-upsell-card/product-upsell-card-view'

interface ProductUpsellCardProps {
  t: TFunction<'menu'>
  upsellData: ProductUpsellData
  onYesClicked: () => void
  onNoClicked: () => void
  isTestImgDevToggle: boolean
  productComponentUpsellVariation: string
}

const ProductUpsellCard: React.FC<ProductUpsellCardProps> = (props) => (
  <ProductUpsellCardView
    viewModel={createProductUpsellCardViewModel({ ...props })}
    onYesClicked={props.onYesClicked}
    onNoClicked={props.onNoClicked}
  />
)

export default ProductUpsellCard
