import { SplashScreen } from '@dominos/components/splash-screen'
import { useCustomer, useNativeAppData } from '@dominos/hooks-and-hocs'
import { Redirect, Router } from '@reach/router'
import React, { useEffect } from 'react'
import { NavigationConstants } from '../../common/navigation'
import { DeleteAccount } from './delete-account'
import styles from './my-account-layout.less'
import { MyDetails } from './my-details'
import { MyPassword } from './my-password'
import { MyRewards } from './my-rewards'
import { MySavedPayments } from './my-saved-payments'

import { LoginSceneMessage, postWebViewMessage } from '@dominos/business/functions/native-app'

const Routes = (redirect?: string) => (
  <>
    {redirect ? <Redirect noThrow from={'/'} to={redirect} /> : null}
    <MyRewards path={'my-rewards'} />
    <MyDetails path={'details'} />
    <MyPassword path={'password'} />
    <MySavedPayments path={'payment-details'} />
    <DeleteAccount path={'delete'} />
  </>
)

const NativeAppMyAccountLayout = () => {
  useNativeAppData()
  const { loading, isLoggedIn } = useCustomer()

  useEffect(() => {
    if (loading) {
      return
    }

    if (isLoggedIn === false) {
      postWebViewMessage<LoginSceneMessage>({ route: NavigationConstants.nativeAppLogin })
    }
  }, [loading, isLoggedIn])

  if (loading) {
    return <SplashScreen testID='native-app-my-account-layout-loading-splash' />
  }

  return (
    <div className={styles.page} data-testid='native-app-my-account-navigation'>
      <div className={styles.wrapper}>
        <Router className={styles.submenuMobile}>{Routes()}</Router>
      </div>
    </div>
  )
}

export { NativeAppMyAccountLayout }
