export const productDetailLocationType: string = 'ProductDetails'

export interface ProductUpsellData {
  media: UpsellMedia
  price: string | null | undefined
  code: string
  showBanner: boolean
  showPopUp: boolean
}

export enum ProductUpsellComponentType {
  Crust = 'Crust',
  Sauce = 'Sauce',
  Toppings = 'Topping',
}

export interface ProductUpsellProps extends BaseProductProps {
  currentProduct: BasketLineData | undefined
  productUpsellVariation?: ProductUpsellVariationTypes
  onUpsellSelected: (code: TouchableGridElement) => void
}

export enum ProductComponentUpsellLDVariation {
  VariationNone = 'variation-none', //Represents a variation that disable the upsell feature
  VariationEnable = 'variation-enable', // Represents a variation that enables the upsell feature. The action button is displayed without price text.
  VariationPriceEnable = 'variation-price-enable', // Represents a variation that enables the upsell feature. The action button is displayed with price text.
}

export enum ProductUpsellVariationTypes {
  VariationNone = 'variation-none',
  VariationBanner = 'variation-banner',
  VariationPopup = 'variation-popup',
}

interface UpsellMedia {
  name: Bff.Common.TextMedia
  description?: Bff.Common.TextMedia | null
  popupImage: Bff.Common.ImageMedia
  bannerImage: Bff.Common.ImageMedia
}

export interface UpsellProps {
  productUpsellPopupDismissed?: boolean
}
