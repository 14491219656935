import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useApolloClient } from '@apollo/client'
import { rootActions } from '@dominos/business'
import { featuresQuery } from '@dominos/business/queries/features'
import { useLaunchDarklyID } from '@dominos/hooks-and-hocs'

import { defaultFeatures, FeatureContext, FeatureResponse, FeatureToggleProps } from './feature-context'

interface FeatureProviderProps {
  children: ReactNode
  timeoutTime?: number
}

export const FeatureProvider = (props: FeatureProviderProps) => {
  const client = useApolloClient()

  const launchDarklyID = useLaunchDarklyID()
  const initFeatures = useSelector((state: RootReducer) => state.featureReducer.features || defaultFeatures)
  const dispatch = useDispatch()

  const [features, setFeatures] = useState(initFeatures)
  const [loading, setLoading] = useState(true)

  const countryCode = useSelector((state: RootReducer) => state.applicationReducer.countryCode || 'AU')

  const updateFeatures = useCallback(async () => {
    const variables = {
      userId: launchDarklyID,
    }
    const response = await client.query<FeatureResponse>({
      variables,
      query: featuresQuery,
      fetchPolicy: 'no-cache',
    })

    if (response?.data) {
      setFeatures(response.data.featureToggles)
      dispatch(rootActions.setFeatureToggles(response.data.featureToggles))
    }
    setLoading(false)
  }, [launchDarklyID, countryCode])

  useEffect(() => {
    if (props.timeoutTime) {
      const time = setTimeout(() => {
        updateFeatures()
      }, props.timeoutTime)

      return () => clearTimeout(time)
    }
    updateFeatures()

    return () => {}
  }, [launchDarklyID, countryCode])

  const featuresModel: FeatureToggleProps = useMemo(
    () => ({
      features,
      loading,
      update: updateFeatures,
    }),
    [features, loading, launchDarklyID],
  )

  return <FeatureContext.Provider value={featuresModel}>{props.children}</FeatureContext.Provider>
}
