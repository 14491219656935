import { CountryToggle } from '@dominos/components/toggles'
import { useFeatures, useLocalStorage } from '@dominos/hooks-and-hocs'
import { useState } from 'react'

export const useRememberMyDetails = () => {
  const { featureEnabled } = useFeatures()
  const [rememberMyDetailsEnabled] = featureEnabled('RememberMyDetails')
  const { storedValue, setValue } = useLocalStorage({ key: 'remember-my-details-checkbox' })

  const rememberMyDetails = CountryToggle({
    AU: { initialValue: storedValue ?? false },
    BE: { initialValue: storedValue ?? false },
    DE: { initialValue: storedValue ?? false },
    DK: { initialValue: storedValue ?? false },
    FR: { initialValue: storedValue ?? false },
    JP: { initialValue: storedValue ?? false },
    LU: { initialValue: storedValue ?? false },
    NL: { initialValue: storedValue ?? true },
    NZ: { initialValue: storedValue ?? false },
    SG: { initialValue: storedValue ?? false },
    MY: { initialValue: storedValue ?? false },
    default: { initialValue: storedValue ?? true },
  })

  const saveRememberMyDetailsCheck = (checkboxState: boolean) => setValue(checkboxState)

  const [rememberMyDetailsState, setRememberMyDetailsState] = useState<boolean>(
    rememberMyDetailsEnabled && rememberMyDetails.initialValue,
  )

  return {
    setRememberMyDetailsState,
    saveRememberMyDetailsCheck,
    rememberMyDetailsState,
  }
}
