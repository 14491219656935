import React, { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BoxedMessage } from '@dominos/components/boxed-message'
import { useFeatures, useKiosk, useServiceMethod } from '@dominos/hooks-and-hocs'
import { PaymentMethod } from '../payment-method'
import { PaymentMethodProps } from '../payment-method.interface'
import { CustomerRequiresChangeCheckbox } from './customer-requires-change-checkbox'

const cclDeliveryLegalText = {
  key: 'CCLDeliveryLegalText',
  defaultValue:
    'Please Note: A payment processing fee may apply to card payments made via mobile EFTPOS terminals on delivery.',
}
const cclPickupLegalText = {
  key: 'CCLPickupLegalText',
  defaultValue: 'Please Note: A payment processing fee may apply to card payments in store.',
}

export const PaymentCash = ({ paymentSetting, isCashChangeRequired, ...props }: PaymentMethodProps) => {
  const { t } = useTranslation('checkout')
  const { featureEnabled } = useFeatures()
  const serviceMethod = useServiceMethod()
  const { isKioskOrder } = useKiosk()

  const [cclLegalTextEnabled] = featureEnabled('CreditCardLaterLegalText')

  const cclLegalText = useMemo(
    () => (serviceMethod === 'Delivery' ? cclDeliveryLegalText : cclPickupLegalText),
    [serviceMethod],
  )

  switch (paymentSetting.paymentMethod) {
    case 'Cash':
      return (
        <PaymentMethod
          paymentSetting={paymentSetting}
          methodId={paymentSetting.paymentMethod}
          paymentButtonTitle={t('Pay Now')}
          paymentButtonIncludeTotal={true}
          {...props}
        >
          {isCashChangeRequired && (
            <Fragment>
              <BoxedMessage icon='disclaimer' testID='max-change-with-driver-text' variant='contained'>
                {t('MaxChangeWithDriverText', { defaultValue: 'Please Note: Drivers only carry $20 in change.' })}
              </BoxedMessage>
              <CustomerRequiresChangeCheckbox />
            </Fragment>
          )}
        </PaymentMethod>
      )
    case 'MobileEftpos':
    case 'CreditCardLater':
      return (
        <PaymentMethod
          paymentSetting={paymentSetting}
          methodId={paymentSetting.paymentMethod}
          paymentButtonTitle={t('Pay Now')}
          paymentButtonIncludeTotal={true}
          {...props}
        >
          {cclLegalTextEnabled && (
            <BoxedMessage
              icon='disclaimer'
              testID='ccl-legal-text'
              variant='contained'
              color='secondary'
              textSize={isKioskOrder ? 'large' : undefined}
            >
              {t(cclLegalText.key, { defaultValue: cclLegalText.defaultValue })}
            </BoxedMessage>
          )}
        </PaymentMethod>
      )
    default:
      return null
  }
}
