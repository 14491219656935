type Ingredient = Bff.Menu.old.Ingredient

/**
 * Manages a single topping modification to a product item.
 * Generates an array of topping changes by updating any
 * existing changes for the given topping or adding a new
 * modification.
 *
 * @example
 * ```typescript
 * // Generate new changes and capture
 * const changes = getChangesWithProductToppingQuantity(item, quantity, ingredient, toppings);
 * ```
 *
 * @param item product item
 * @param quantity (optional) Product topping total quantity
 * @param ingredient Ingredient item for change
 * @param toppings The topping that is being added/removed from product
 * @returns The toppings that are being added or removed as `BasketLineChange[]`
 */
// ! From `@dominos/business/functions`

import { getDefaultPizzaToppings } from './get-default-pizza-toppings'

// * Turns the topping that is being added or removed into a `BasketLineChange[]`
export const productChangesTransform = (
  product: ProductMenuItem,
  quantity: number,
  ingredient: Ingredient,
  toppings: BasketLineChange[] = [],
) => {
  if (!product) {
    return []
  }

  const defaultToppings = getDefaultPizzaToppings(product)

  const newChanges = [...toppings]
  const defaultIndex = defaultToppings.findIndex((d) => d.ingredient.code === ingredient.code)
  const action = quantity > 0 ? 'Add' : 'Remove'
  let adjustedQuantity = quantity

  if (
    (defaultIndex < 0 && quantity > 0) ||
    (defaultIndex >= 0 && quantity !== defaultToppings[defaultIndex].quantity)
  ) {
    if (defaultIndex >= 0) {
      if (action === 'Add') {
        adjustedQuantity = quantity - defaultToppings[defaultIndex].quantity
      } else {
        adjustedQuantity = defaultToppings[defaultIndex].quantity - quantity
      }
    }

    const existingChange = newChanges.find(({ code }) => ingredient.code === code)

    if (existingChange) {
      existingChange.quantity = adjustedQuantity
      existingChange.media = ingredient.media || { name: '' }
    } else {
      newChanges.push({
        action,
        quantity: adjustedQuantity,
        code: ingredient.code,
        media: ingredient.media || { name: '' },
      })
    }
  } else {
    newChanges.splice(
      newChanges.findIndex(({ code }) => ingredient.code === code),
      1,
    )
  }

  return newChanges
}
