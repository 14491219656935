import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getDonation } from '@dominos/business/functions'
import {
  CHARITY_SURCHARGE_CODE,
  RoundUpForCharityTrackerOptIn,
  RoundUpForCharityTrackerOptOut,
  useRoundUpForCharityDetails,
} from '@dominos/components/charity/round-up'
import { AutoScroll, useFeatures } from '@dominos/hooks-and-hocs'
import { CollapsableTitledCard } from '../..'
import { LineItems } from './line-items'
import OrderDetailsSkeleton from './order-details-skeleton'
import css from './order-details.less'
import { OrderTotal } from './order-total'
import { PaymentMethodDetails } from './payment-method-details'
import { ServiceMethodDetails } from './service-method-details'
import { OrderDetailedSummary } from '@dominos/components/basket/basket-container/order-details-card/order-detailed-summary'

const OrderDetails = ({ order }: { order?: Bff.Orders.Order }) => {
  const { t } = useTranslation('tracker')
  const { featureEnabled } = useFeatures()

  const [roundUpForCharityEnabled, adyenGivingEnabled, basketSummaryDetailedPricing] = featureEnabled(
    'charity-round-up',
    'AdyenGiving',
    'BasketSummaryDetailedPricing',
  )
  const charitySurcharge = order?.details?.basket?.surcharges?.find(
    (surcharge: BasketSurcharge) => surcharge.code === CHARITY_SURCHARGE_CODE,
  )

  const donationDetails = useRoundUpForCharityDetails()

  const donationAmount = getDonation(order, donationDetails)

  const cardStyles = {
    marginTop: '8px',
  }

  const { lines = [], total = 0, totalDiscount = 0 } = order?.details?.basket || {}
  const isOrderFree = lines.some(({ type }) => type === 'CouponLine') && !total && totalDiscount > 0
  const paymentMethods = useMemo(
    () =>
      isOrderFree
        ? [
            {
              media: { displayName: t('FreeOrderMethod', { defaultValue: 'None' }) },
            } as Bff.Orders.OrderDetails.Payment,
          ]
        : order?.details!.payments,
    [isOrderFree, order],
  )

  return (
    <AutoScroll id={'details'}>
      <CollapsableTitledCard testID={'OrderDetails'} primaryTitle={t('OrderDetails')} style={cardStyles} noPadding>
        {order ? (
          <>
            <section className={css.header}>
              <ServiceMethodDetails order={order} />
              <PaymentMethodDetails paymentMethods={paymentMethods} />
            </section>
            {order.details && (
              <>
                <section>
                  <LineItems lines={order.details.basket.lines} />
                </section>
                {basketSummaryDetailedPricing ? (
                  <section className={css.detailedSummaryContainer}>
                    <OrderDetailedSummary
                      testID={`orderDetailedSummary`}
                      isTrackingOrderDetail
                      basketData={order.details.basket}
                      roundUpForCharityEnabled={roundUpForCharityEnabled}
                      driverTip={order.details.header.tip}
                    />
                  </section>
                ) : (
                  <section className={css.total}>
                    <OrderTotal
                      basket={order.details.basket}
                      roundUpForCharityEnabled={roundUpForCharityEnabled}
                      driverTip={order.details.header.tip}
                    />
                  </section>
                )}
                {(roundUpForCharityEnabled || adyenGivingEnabled) &&
                  (charitySurcharge || donationAmount ? (
                    <RoundUpForCharityTrackerOptIn
                      testID={'OrderDetails'}
                      amount={donationAmount ?? charitySurcharge?.price}
                    />
                  ) : (
                    <RoundUpForCharityTrackerOptOut
                      testID={'OrderDetails'}
                      payments={order.details.payments}
                      storeNo={order.store?.storeNo}
                      serviceMethod={order.details.header.serviceMethod}
                      serviceMethodSubType={order.details.header.serviceMethodSubType}
                      orderTime={order.details.header.orderTime}
                    />
                  ))}
              </>
            )}
          </>
        ) : (
          <OrderDetailsSkeleton />
        )}
      </CollapsableTitledCard>
    </AutoScroll>
  )
}

export { OrderDetails }
