type DimensionSet =
  | Bff.Dimensions.SizeBaseDimensionSet
  | Bff.Dimensions.SizeDimensionSet
  | Bff.Dimensions.NonDimensionalSet
type CurrencyPrice = Bff.Common.CurrencyPrice<Bff.Products.DimensionalProductPrice<DimensionSet>>
import { findEntityByDimensionSet } from './find-entity-by-dimensionset'
export const calculatePriceDifferenceForDimensionChange = ({
  currentDimensionSet,
  dimensionType,
  dimensionCode,
  currencyPrices,
  createDimensionSet,
}: {
  currentDimensionSet: DimensionSet | null | undefined
  dimensionType: 'Size' | 'Base'
  dimensionCode: string
  currencyPrices: CurrencyPrice[]
  createDimensionSet: (
    previousDimensionSet: DimensionSet,
    dimensionType: 'Size' | 'Base',
    code: string,
    forced: boolean,
  ) => DimensionSet
}) => {
  if (!currentDimensionSet) return 0
  const extractCheapestPrice = (productPrice: Bff.Products.ProductPrice[] | undefined) =>
    (productPrice && (productPrice[0]?.promoPrice ?? productPrice[0]?.price)) || 0

  const targetDimensionSet = createDimensionSet(currentDimensionSet, dimensionType, dimensionCode, true)
  const dimensionalPrices = currencyPrices?.[0]?.prices

  if (!dimensionalPrices || !dimensionalPrices.length) return 0

  const currentPrice = extractCheapestPrice(findEntityByDimensionSet(dimensionalPrices, currentDimensionSet))
  const targetPrice = extractCheapestPrice(findEntityByDimensionSet(dimensionalPrices, targetDimensionSet))

  if (!currentPrice || !targetPrice) {
    return 0
  }

  return targetPrice - currentPrice
}
