import { useEffect } from 'react'
import { isNativeApp, oneTrustStyleOverride } from '@dominos/business/functions'
import { useBreakpoints, useFeatures } from '@dominos/hooks-and-hocs'

export const useOneTrustStyleOverride = () => {
  const { isMobile } = useBreakpoints()
  const isNative = isNativeApp()
  const { getBooleanVariation } = useFeatures()
  const universalAuthEnabled = getBooleanVariation('UniversalAuthentication')

  useEffect(() => {
    if ((universalAuthEnabled && isMobile) || isNative) {
      const { moveOTPreferenceBtnToRight, removeOverrideOTPreferenceBtnStyle } = oneTrustStyleOverride()
      moveOTPreferenceBtnToRight()

      return removeOverrideOTPreferenceBtnStyle
    }
  }, [])
}
