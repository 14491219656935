import { useMarket } from '@dominos/hooks-and-hocs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const noSauceCode = 'no-sauce'

const useSauceData = (
  ingredients: IngredientModel[],
  selectedIngredientBasketLineSwap: BasketLineSwap | undefined,
  allowNoSauce: boolean,
) => {
  const { t } = useTranslation('menu')
  const { getMediaForIngredient } = useMarket()
  const defaultIngredient = ingredients?.find((ingredient) => ingredient.inRecipe === true)

  const ingredientMapper = () => {
    const selectedItemCode = selectedIngredientBasketLineSwap?.add || defaultIngredient?.code
    const selectedItemPrice = ingredients.find((ingredient) => ingredient.code === selectedItemCode)?.price ?? 0
    const mappedIngredients =
      ingredients.map((sauce) => {
        const ingredientMedia = getMediaForIngredient('Sauce', sauce.code)

        return {
          code: sauce.code,
          name: ingredientMedia?.name.value ?? '',
          color: ingredientMedia?.colour?.value,
          price: sauce.price - selectedItemPrice,
        }
      }) ?? []
    if (allowNoSauce) {
      mappedIngredients.push({
        code: noSauceCode,
        name: t('No sauce'),
        color: undefined,
        price: 0 - selectedItemPrice,
      })
    }

    return mappedIngredients
  }

  return useMemo(
    () => ({
      items: ingredientMapper(),
      selectedItemCode:
        selectedIngredientBasketLineSwap?.add ??
        (selectedIngredientBasketLineSwap?.remove || (ingredients.length == 0 && allowNoSauce)
          ? noSauceCode
          : defaultIngredient?.code),
    }),
    [selectedIngredientBasketLineSwap, ingredients, allowNoSauce],
  )
}

export { noSauceCode, useSauceData }
