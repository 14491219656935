import { useApolloClient, useMutation } from '@apollo/client'
import { getCustomerId, idTokenUtils } from '@dominos/business/functions/customer'
import { logoutMutation } from '@dominos/business/queries/logout'
import { useAlert, useAuthentication, useFeatures, useForgeRock, useSwrve } from '@dominos/hooks-and-hocs'
import { useKeepTokenAlive } from '@dominos/hooks-and-hocs/customer'
import { useTranslation } from 'react-i18next'

export const useLogout = () => {
  const { deleteToken, isTokenLongLived } = idTokenUtils()
  const client = useApolloClient()
  const { t } = useTranslation('login')
  const { showAlert } = useAlert()
  const customerId = getCustomerId()
  const { stopPolling } = useKeepTokenAlive(customerId)
  const { reset } = useSwrve()
  const { setLoggedInUser } = useAuthentication()
  const { featureEnabled } = useFeatures()
  const [forgeRockIdentityProviderEnabled] = featureEnabled('ForgeRockIdentityProvider')
  const { logoutForgeRock } = useForgeRock()

  const handleSuccess = async (data: { logout: { tokenSubject: string } }) => {
    if (data?.logout?.tokenSubject) {
      deleteToken()
      setLoggedInUser(false)

      if (customerId && !isTokenLongLived()) {
        stopPolling()
      }
      reset()

      if (forgeRockIdentityProviderEnabled) {
        await logoutForgeRock()
      }

      return await client.clearStore()
    }
  }

  const handleError = () => {
    showAlert(t('onLogoutError', 'Unable to logout, please try again.'), 'onLogoutError')
  }

  const [logout, { loading }] = useMutation<{ logout: { tokenSubject: string } }, { orderId?: string }>(
    logoutMutation,
    {
      fetchPolicy: 'no-cache',
      onCompleted: handleSuccess,
      onError: handleError,
    },
  )

  return {
    logout,
    loading,
  }
}
