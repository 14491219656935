import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { deviceId } from '@dominos/business/functions/native-app'
import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { ApplicationConfiguration } from '@dominos/interfaces'
import { SHA256 } from 'crypto-js'
import { AnalyticSender, basketToEcommerceCart } from './analytics'
import { getOrderId } from './helpers'
import { SecuritySender } from './security'
import { isKioskOrderType } from '@dominos/business/functions/common'

const { CURRENCY_CODE } = getCountryConfig()

export const reportInteract = (
  sendSecurity: SecuritySender,
  sendAnalytics: AnalyticSender,
  { environment, language, countryCode }: ApplicationConfiguration,
  basket?: Basket,
  menu?: Menu,
  currentStore?: Bff.Stores.Store,
  currentOrder?: CurrentOrderDetailsReducerProps,
) => {
  const reportInteraction = (label: string, value?: string) => {}

  const reportScreenView = (
    url: string,
    component: string,
    userId?: string,
    userEmail?: string,
    userPhone?: string,
  ) => {
    const orderId = getOrderId(currentOrder)
    const { basketHeaderData, paymentMethod } = currentOrder || {}

    sendSecurity('Flow.ScreenView', {
      ScreenName: url,
      ScreenClass: component,
      'Order ID': orderId,
    })

    sendAnalytics('Page View', {
      environment: environment === 'PROD' ? 'prod' : 'staging',
      'Full Page Url': url,
      'Page Title': document.title,
      'App Device Id': deviceId,
      'User Id': userId,
      'User Hashed Email': userEmail ? SHA256(userEmail.toLowerCase()).toString() : undefined,
      'User Hashed Phone': userPhone ? SHA256(userPhone.toLowerCase()).toString() : undefined,
      Market: countryCode!,
      Platform: isKioskOrderType() ? 'kiosk' : 'web',
      'UI Language': language.toUpperCase() as `${Uppercase<BffContext.Languages>}`,
      'Store Name': currentStore?.media?.name,
      'Store Id': currentStore?.storeNo,
      'Service Method': basketHeaderData?.serviceMethod,
      'Service Method Subtype': basketHeaderData?.serviceMethodSubType,
      'Service Method Timing': basketHeaderData?.time ? new Date(basketHeaderData?.time).getTime() : 'ASAP',
      'Payment Method': paymentMethod?.paymentMethod,
      'Payment Provider': paymentMethod?.providerCode,
      'Ecommerce Cart': basketToEcommerceCart(basket, menu?.pages, CURRENCY_CODE),
      'Page Timestamp': new Date().getTime(),
      Order: { Id: orderId },
    })
  }

  const reportValidationMessage = (message: string, translationKey?: string, source?: string, severity?: string) => {
    const orderId = getOrderId(currentOrder)
    sendAnalytics('Validation Message', {
      'Validation Message': message,
      'Validation Message Code': translationKey,
      'Validation Message Source': source,
      'Validation Message Severity Level': severity,
    })

    sendSecurity(
      'Flow.Alert.Displayed',
      {
        TranslationKey: translationKey,
        Message: message,
        'Order ID': orderId,
      },
      true,
    )
  }

  const reportErrorModalDisplay = (message: string, errorCode: string) => {
    const orderId = getOrderId(currentOrder)
    sendSecurity('Flow.Alert.Displayed', {
      TranslationKey: errorCode,
      Message: message,
      'Order ID': orderId,
    })
  }

  const reportPaymentErrorMessage = (title: string, message: string, errorCode: string) => {
    sendAnalytics('Payment Error', {
      'Payment Error Title': title,
      'Payment Error Message': message,
      'Payment Error Code': errorCode,
    })
  }

  return {
    reportPaymentErrorMessage,
    reportScreenView,
    reportInteraction,
    reportValidationMessage,
    reportErrorModalDisplay,
  }
}
