import React from 'react'
import { useTranslation } from 'react-i18next'

import { AddressList } from '@dominos/components'
import { useCharacterLimit, useDominosTheme, useFeatures } from '@dominos/hooks-and-hocs'

import { AddressSearchButton } from './address-search-button'

import css from './address-search-results.less'

export interface AddressSearchResultsProps extends BaseProps {
  type: BffContext.ServiceMethods
  addresses: AddressLine[] | undefined
  searchValue: string | undefined
  isLoading: boolean
  itemLoadingKey: number | undefined
  hasError: boolean
  onSelectAddress: (line: AddressLine) => void
}

export const AddressSearchResults = ({
  testID,
  type,
  searchValue,
  addresses,
  isLoading,
  itemLoadingKey,
  hasError,
  onSelectAddress,
}: AddressSearchResultsProps) => {
  const { t } = useTranslation('delivery-address')
  const theme = useDominosTheme()
  const avgAddressCharacters = useCharacterLimit()
  const { featureEnabled } = useFeatures()
  const [autoCompleteDeliveryShowFallbackButtonWhenNoResults] = featureEnabled(
    'AutoCompleteDeliveryShowFallbackButtonWhenNoResults',
  )

  if (!searchValue || hasError) {
    return <div data-testid={testID} />
  }

  const showAddressSearchButton = () =>
    type === 'Delivery' ? (
      <AddressSearchButton
        testID={`${testID}.wrongAddress`}
        isLoading={isLoading}
        type={'WrongDeliveryAddress'}
        searchValue={searchValue}
      />
    ) : undefined

  const showFallbackAddressSearchButton = () =>
    autoCompleteDeliveryShowFallbackButtonWhenNoResults && searchValue.length > 0 && type === 'Delivery' ? (
      <AddressSearchButton testID={`${testID}.wrongAddress`} isLoading={isLoading} type={'WrongDeliveryAddress'} />
    ) : undefined

  if (addresses) {
    return (
      <div data-testid={testID}>
        <AddressList
          testID={`${testID}.results`}
          addresses={addresses}
          onPress={onSelectAddress}
          isLoading={isLoading}
          itemLoadingKey={itemLoadingKey}
          showBorderOnFirstItem
          swapNameAndAddress
        />

        {showAddressSearchButton()}
      </div>
    )
  }

  if (searchValue.length < avgAddressCharacters) {
    return (
      <div data-testid={testID}>
        <p
          data-testid={`${testID}.keepTyping`}
          className={css.loading}
          style={{ color: theme.colours.loadingTextColor }}
        >
          {t('AddressSearchLoading')}
        </p>
        {showFallbackAddressSearchButton()}
      </div>
    )
  }

  return <div data-testid={testID}>{showFallbackAddressSearchButton()}</div>
}
