import React from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'
import { NavigationConstants } from '@dominos/navigation'
import { AddressSearch } from './address-search'
import css from './auto-complete-address-scene.less'
import { useCountryCode, useFeatures, useReport } from '@dominos/hooks-and-hocs'
import { mapDeliveryAddressWithAdditionalFieldsToStoredCustomerAddress } from '@dominos/business/functions/address'
import { rootActions } from '@dominos/business'
import { useDispatch } from 'react-redux'

// TODO: rename to `AutoCompleteDeliveryScene`
export const AutoCompleteAddressScene = () => {
  const { t } = useTranslation('delivery-address')
  const { featureEnabled } = useFeatures()
  const [autoCompleteDisplayAdditionalFieldsCheckout, autoCompleteDeliveryAddressFieldsHidden] = featureEnabled(
    'AutoCompleteDeliveryAddressFieldsDisplayCheckout',
    'AutoCompleteDeliveryAddressFieldsHidden',
  )
  const dispatch = useDispatch()
  const { reportDeliveryAddressSelected } = useReport()
  const countryCode = useCountryCode()

  const onComplete = (deliveryAddress: DeliveryAddressRequest, storeDetails: StoreDetails) => {
    if (autoCompleteDeliveryAddressFieldsHidden) {
      navigateTimedOrder(deliveryAddress, storeDetails)
    } else {
      autoCompleteDisplayAdditionalFieldsCheckout
        ? navigateTimedOrder(deliveryAddress, storeDetails)
        : navigate(NavigationConstants.confirmAddress)
    }
  }

  const navigateTimedOrder = (deliveryAddressRequest: DeliveryAddressRequest, storeDetails: StoreDetails) => {
    if (!deliveryAddressRequest || !storeDetails || !storeDetails.storeNo || !countryCode) {
      return
    }

    const deliveryAddress: DeliveryAddress = {
      ...deliveryAddressRequest,
      ...{ storeNo: storeDetails.storeNo },
    }
    const customerAddress = mapDeliveryAddressWithAdditionalFieldsToStoredCustomerAddress(
      t,
      deliveryAddress,
      undefined,
      countryCode,
    )

    dispatch(rootActions.selectServiceMethod('Delivery'))
    dispatch(rootActions.addCustomerAddressToOrder(customerAddress))
    reportDeliveryAddressSelected()

    navigate(NavigationConstants.timedOrder)
  }

  return (
    <StackNavigationBase testID={'address-search-scene'} header={<SimpleNavigationTitle showAccount showBackButton />}>
      <div className={css.wrapper}>
        <AddressSearch
          testID={'address-search'}
          type={'Delivery'}
          title={t('AddressSearchTitle')}
          placeholder={t('AddressSearchPlaceholder')}
          onCompleteDelivery={onComplete}
        />
      </div>
    </StackNavigationBase>
  )
}
