import React, { useEffect, useState } from 'react'
import { ProductUpsellProps, ProductUpsellVariationTypes } from './product-upsell.interface'
import ProductUpsellCard from './product-upsell-card/product-upsell-card'
import ProductUpsellPopup from './product-upsell-popup/product-upsell-popup'
import { useDevToggles, useFeatures, useProductUpsellOffer, useReport } from '@dominos/hooks-and-hocs'
import { useTranslation } from 'react-i18next'
import { ProductUpsellConfirmation } from './product-upsell-confirmation'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'
import { useUpsell } from '@dominos/hooks-and-hocs'

const upsellDelayInMilliseconds = 1000
const upsellEndDelayInMilliseconds = 11000

const ProductUpsell = ({ productUpsellVariation, onUpsellSelected, currentProduct }: ProductUpsellProps) => {
  const dispatch = useDispatch()
  const { isEnabled } = useDevToggles()
  const isTestImgDevToggle = isEnabled['test-offers-images']
  const [isVisible, setIsVisible] = useState(false)
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
  const { productUpsellPopupDismissed } = useUpsell()
  const { t } = useTranslation('menu')
  const { reportProductUpsellEnabled, reportProductUpsellAdded, reportProductUpsellRemoved } = useReport()
  const { getStringVariation } = useFeatures()
  const productComponentUpsellVariation = getStringVariation('ProductComponentUpsellVariation')
  const upsellData = useProductUpsellOffer(currentProduct)

  useEffect(() => {
    let timeoutStartAnimation: NodeJS.Timeout
    let delayTimeoutEnd: NodeJS.Timeout
    switch (productUpsellVariation) {
      case ProductUpsellVariationTypes.VariationPopup:
        const visible = productUpsellPopupDismissed ? false : true
        timeoutStartAnimation = setTimeout(() => {
          setIsVisible(visible)
        }, upsellDelayInMilliseconds)

        if (visible) {
          delayTimeoutEnd = setTimeout(() => handleUpsellPopupTimeout(), upsellEndDelayInMilliseconds)
        }
        break
      default:
        setIsVisible(true)
    }
    reportProductUpsellEnabled()

    return () => {
      if (delayTimeoutEnd) clearTimeout(delayTimeoutEnd)
      if (timeoutStartAnimation) clearTimeout(timeoutStartAnimation)
    }
  }, [])

  const persistUpsellPopupDismissed = () => {
    dispatch(rootActions.updateProductUpsell({ productUpsellPopupDismissed: true }))
  }

  const handleUpsellConfirmation = () => {
    setIsConfirmationVisible(true)
    if (productUpsellVariation === ProductUpsellVariationTypes.VariationPopup) {
      setIsVisible(false)
    }
    reportProductUpsellAdded()
    if (upsellData) {
      onUpsellSelected({
        code: upsellData.code,
        name: upsellData.media.name.value,
      })
    }
  }

  const handleUpsellBannerRejection = () => {
    setIsVisible(false)
    reportProductUpsellRemoved()
  }

  const handleUpsellPopupRejection = () => {
    setIsVisible(false)
    persistUpsellPopupDismissed()
    reportProductUpsellRemoved()
  }

  const handleUpsellPopupTimeout = () => {
    setIsVisible(false)
    persistUpsellPopupDismissed()
  }

  return (
    <>
      {isVisible && upsellData && (
        <>
          {productUpsellVariation === ProductUpsellVariationTypes.VariationPopup && upsellData.showPopUp && (
            <ProductUpsellPopup
              upsellData={upsellData}
              onYesClicked={handleUpsellConfirmation}
              onNoClicked={handleUpsellPopupRejection}
              t={t}
              isTestImgDevToggle={isTestImgDevToggle}
              productComponentUpsellVariation={productComponentUpsellVariation}
            />
          )}
          {productUpsellVariation === ProductUpsellVariationTypes.VariationBanner && upsellData.showBanner && (
            <ProductUpsellCard
              upsellData={upsellData}
              onYesClicked={handleUpsellConfirmation}
              onNoClicked={handleUpsellBannerRejection}
              t={t}
              isTestImgDevToggle={isTestImgDevToggle}
              productComponentUpsellVariation={productComponentUpsellVariation}
            />
          )}
        </>
      )}
      {isConfirmationVisible && (
        <ProductUpsellConfirmation
          testID='product-upsell-confirmation'
          title={upsellData?.media.name.value || ''}
          description={t('ComponentUpsellConfirmationMessage', {
            defaultValue: 'Great! We have upgraded your item',
          })}
        />
      )}
    </>
  )
}

export { ProductUpsell }
