import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getNutritionalInfo } from '@dominos/business/functions/menu'
import { ActionButton, CollapsableTitledCard, isProductMenuItemNew } from '@dominos/components'
import { IframeCardPopup, useFeatures, useKiosk } from '@dominos/hooks-and-hocs'
import { AllergenInfo } from './allergen-info'
import { ProductNutritionalLine } from './product-nutritional-line'
import css from './product-nutritional.less'

export const ProductNutritionalCard = ({ testID, currentProduct }: ProductNutritionalProps) => {
  const { featureEnabled } = useFeatures()
  const { t } = useTranslation('menu')
  const { isKioskOrder } = useKiosk()

  const [showNutritionalInformationFrame, setShowNutritionalInformationFrame] = useState(false)

  // Product code can be substituted into the URL using the {{productCode}} placeholder
  // For example, https://www.dominos.com.au/nutritionals/nutritional-information-{{productCode}}
  const url = t('nutritional information url', {
    defaultValue: 'https://www.dominos.com.au/menu/nutritional-information',
    productCode: (currentProduct?.item && !isProductMenuItemNew(currentProduct.item) && currentProduct.item.code) ?? '',
  })

  const onPress = () => {
    if (isKioskOrder) {
      setShowNutritionalInformationFrame(true)
    } else {
      window.open(url)
    }
  }

  const [nutritionalEnabled, nutritionalInfoButtonEnabled] = featureEnabled(
    'nutritionals',
    'ProductNutritionalInformationButton',
  )
  const { productNutritionals, productAllergens } = nutritionalEnabled
    ? getNutritionalInfo(currentProduct)
    : { productNutritionals: null, productAllergens: [] }

  return (
    <>
      {nutritionalInfoButtonEnabled || productNutritionals ? (
        <CollapsableTitledCard
          testID={`${testID}.card`}
          primaryTitle={t('Nutritional & Allergens')}
          noPadding={false}
          startCollapsed={true}
        >
          {productNutritionals ? (
            <div className={css.nutritionalContainer} data-testid={`${testID}.nutritionals-container`}>
              <div>{t('NutritionalInformationDescription', { defaultValue: '' })}</div>
              <div className={css.nutritionalTitle}>{t('NutritionalValuePer100g')}</div>
              <ProductNutritionalLine info={productNutritionals} keyName='energyKJ' defaultUnit='kJ' />
              <ProductNutritionalLine info={productNutritionals} keyName='energyKcal' defaultUnit='kCal' />
              <ProductNutritionalLine info={productNutritionals} keyName='fat' defaultUnit='g' />
              <ProductNutritionalLine info={productNutritionals} keyName='fatSaturated' defaultUnit='g' />
              <ProductNutritionalLine info={productNutritionals} keyName='carbohydrate' defaultUnit='g' />
              <ProductNutritionalLine info={productNutritionals} keyName='sugars' defaultUnit='g' />
              <ProductNutritionalLine info={productNutritionals} keyName='fibre' defaultUnit='g' />
              <ProductNutritionalLine info={productNutritionals} keyName='protein' defaultUnit='g' />
              <ProductNutritionalLine info={productNutritionals} keyName='sodium' defaultUnit='mg' />
              <ProductNutritionalLine info={productNutritionals} keyName='weight' defaultUnit='g' />
              <ProductNutritionalLine info={productNutritionals} keyName='salt' defaultUnit='g' />
              <AllergenInfo info={productAllergens} testID={testID} />
            </div>
          ) : null}

          {nutritionalInfoButtonEnabled ? (
            <div className={css.buttonContainer}>
              <ActionButton
                data-testid={`${testID}.button`}
                testID={`${testID}.button`}
                text={t('View Nutritional Info & Allergens')}
                onPress={onPress}
                textFontWeight='bold'
              />
            </div>
          ) : null}

          {isKioskOrder && showNutritionalInformationFrame && (
            <IframeCardPopup
              onDismiss={() => setShowNutritionalInformationFrame(false)}
              testID={`${testID}.product-nutritional`}
              src={url}
            />
          )}
        </CollapsableTitledCard>
      ) : null}
    </>
  )
}
