import { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import { useDispatch } from 'react-redux'
import { NavigationConstants } from '@dominos/navigation'
import { useFeatures, useReport, useStoreSelect } from '@dominos/hooks-and-hocs'
import { useCurrentStore } from '@dominos/hooks-and-hocs/selectors'
import { rootActions } from '@dominos/business'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs/selectors'

export const useStartOrder = () => {
  const SERVICEMETHODDIVERTED = 'ServiceMethodDiverted'
  const DIVERTING = 'Diverting'
  const DIVERTED = 'Diverted'
  const ASAP = 'ASAP'

  const { featureEnabled } = useFeatures()
  const [serviceMethodOverrides] = featureEnabled('ServiceMethodOverrides')
  const dispatch = useDispatch()
  const currentStore = useCurrentStore()
  const currentOrderDetails = useCurrentOrderDetails()
  const { getStore, store: divertedToStore } = useStoreSelect()
  const { reportOrderTime, reportStoreDiverted } = useReport()
  const setStore = (store: Bff.Stores.Store) => dispatch(rootActions.storeSelected(store, true))
  const [currentOrderTime, setCurrentOrderTime] = useState<string | undefined>()
  const [divertState, setDivertState] = useState<'Diverting' | 'Diverted' | undefined>()

  //Swap current store with new store
  useEffect(() => {
    if (
      divertState === DIVERTING &&
      currentStore &&
      divertedToStore &&
      !sameStore(currentStore, divertedToStore) &&
      currentOrderDetails.selectedDeliveryAddress
    ) {
      reportStoreDiverted(currentStore, divertedToStore)
      setStore(divertedToStore)
      overrideCustomerAddressStoreNo(currentOrderDetails.selectedDeliveryAddress, divertedToStore.storeNo)
      setDivertState(DIVERTED)
    }
  }, [divertState, currentStore, divertedToStore])

  //Start Order and Reset state
  useEffect(() => {
    if (
      divertState === DIVERTED &&
      currentOrderTime &&
      currentStore &&
      divertedToStore &&
      sameStore(currentStore, divertedToStore) &&
      currentOrderDetails.basketHeaderData &&
      currentOrderDetails.basketHeaderData.storeNo === divertedToStore.storeNo
    ) {
      onStartOrderAction(currentOrderTime!)
      setCurrentOrderTime(undefined)
      setDivertState(undefined)
    }
  }, [divertState, currentStore, divertedToStore, currentOrderTime, currentOrderDetails])

  const onStartOrderAction = (currentOrderTime: string) => {
    const orderTime = currentOrderTime === ASAP ? null : currentOrderTime
    dispatch(rootActions.selectOrderTime({ orderTime: orderTime }))
    reportOrderTime(currentOrderTime)
    navigate(NavigationConstants.menu, {
      state: { time: orderTime },
    })
  }

  const startOrder = (selectedTime?: TimeSlotAvailability, openingHours?: StoreOrderTimeSlots) => {
    const orderTime = selectedTime?.slotTime ?? ASAP
    const status = selectedTime?.timeSlotStatus ?? openingHours?.currentStoreStatus
    const divertToStoreNo = selectedTime?.timeSlotDivertToStoreNo ?? openingHours?.currentStoreDivertToStoreNo

    if (serviceMethodOverrides && status === SERVICEMETHODDIVERTED && divertToStoreNo) {
      setCurrentOrderTime(orderTime)
      setDivertState(DIVERTING)
      getStore(divertToStoreNo!)

      return
    }

    onStartOrderAction(orderTime)
  }

  const sameStore = (originalStore: Bff.Stores.Store, newStore: Bff.Stores.Store) =>
    originalStore.storeNo === newStore.storeNo

  const overrideCustomerAddressStoreNo = (customerAddress: StoredCustomerAddress, newStoreNo: number) => {
    customerAddress.storeNo = newStoreNo
    dispatch(rootActions.addCustomerAddressToOrder(customerAddress))
  }

  return {
    startOrder,
    currentOrderTime,
    divertState,
  }
}
