import { CollapsableTitledCard } from '@dominos/components/cards/collapsable-titled-card'
import { useFeatures } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ServiceMethodDetailsDefinitions } from './service-method-details-definitions'

const cardStyles = {
  marginTop: '8px',
}

const isCarParkDeliverySubTypes = (serviceMethodSubType: Bff.Stores.ServiceMethodSubTypes): boolean =>
  ['CarParkDelivery', 'PickUpPoint', 'LockerPickUp', 'LockerPickUpMandatory'].includes(serviceMethodSubType)

const ServiceMethodDetails = ({
  orderId,
  serviceMethod,
  serviceMethodSubType,
  storePhoneNo,
}: {
  orderId: string
  serviceMethod: BffContext.ServiceMethods
  serviceMethodSubType: Bff.Stores.ServiceMethodSubTypes
  storePhoneNo: string | undefined
}) => {
  const { t } = useTranslation('tracker')

  const { featureEnabled } = useFeatures()
  const [pickupSubTypesEnabled, robotDeliveryEnabled] = featureEnabled('EnablePickupSubTypes', 'EnableRobotDelivery')

  if (isCarParkDeliverySubTypes(serviceMethodSubType) && !pickupSubTypesEnabled) {
    return null
  }

  if (serviceMethodSubType === 'RobotDelivery' && !robotDeliveryEnabled) {
    return null
  }

  const definition = ServiceMethodDetailsDefinitions[serviceMethodSubType]

  if (!definition) {
    return null
  }

  const { component: Component, ...restProps } = definition

  return (
    <CollapsableTitledCard
      testID='service-method-details-section'
      primaryTitle={t(serviceMethod === 'Pickup' ? 'PickUpDetails' : 'DeliveryDetails')}
      style={cardStyles}
      noPadding
    >
      <Component orderId={orderId} storePhoneNo={storePhoneNo} {...restProps} />
    </CollapsableTitledCard>
  )
}

export default ServiceMethodDetails
