import { rootActions } from '@dominos/business'
import {
  mapSearchAddressToAddressLine,
  mapSearchAddressToStoredCustomerAddress,
} from '@dominos/business/functions/address/address-mapping'
import { AddressSearchResultsContainer, SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'
import { useCountryCode, useFeatures, useReport } from '@dominos/hooks-and-hocs'
import { useOneTrust } from '@dominos/hooks-and-hocs/one-trust/one-trust-provider'
import { NavigationConstants } from '@dominos/navigation'
import { NavigateOptions, RouteComponentProps } from '@reach/router'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { TimedOrderData } from '../timed-order'
import { useNavigate } from '@reach/router'
import { AddressSearchResultsSceneLocation } from './navigation-state'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

export const AddressSearchResultsScene = ({ location }: RouteComponentProps<AddressSearchResultsSceneLocation>) => {
  const dispatch = useDispatch()
  const { reportDeliveryAddressSelected } = useReport()
  const consents = useOneTrust()
  const { featureEnabled } = useFeatures()
  const navigate = useNavigate()
  const [skipSingleAddressSelection] = featureEnabled('SkipSingleAddressResult')
  const { reportFallBackAddressSearchAddressSelected } = useReportAutoCompleteDelivery()

  const deliverySearchResponse = location?.state.deliverySearch
  const skipAddressSelectionResponse = location?.state.skipAddressSelection

  useEffect(() => {
    if (!deliverySearchResponse) {
      navigate(NavigationConstants.home)
    }
  }, [deliverySearchResponse])

  const countryCode = useCountryCode()
  const addresses = mapSearchAddressToAddressLine(countryCode, deliverySearchResponse)
  const skipAddressSelection =
    (skipSingleAddressSelection && addresses && addresses.length == 1) || skipAddressSelectionResponse

  useEffect(() => {
    if (skipAddressSelection) {
      selectAddress(addresses[0])
    }
  }, [addresses])

  const selectAddress = useCallback(
    (addressLine: AddressLine) => {
      const customerAddress =
        mapSearchAddressToStoredCustomerAddress(addressLine.additionalData) || addressLine.additionalData

      dispatch(rootActions.addCustomerAddressToOrder(customerAddress))
      dispatch(rootActions.selectServiceMethod('Delivery'))

      if (consents.Functional) {
        dispatch(rootActions.saveCustomerAddress(customerAddress))
      }
      reportFallBackAddressSearchAddressSelected(customerAddress)
      reportDeliveryAddressSelected()

      const navigationOptions: NavigateOptions<TimedOrderData> = {
        state: {
          navigationBackPath: skipAddressSelection ? NavigationConstants.deliveryAddress : undefined,
        },
      }
      navigate(NavigationConstants.timedOrder, navigationOptions)
    },
    [consents],
  )

  if (skipAddressSelection) {
    return null
  }

  return (
    <StackNavigationBase
      testID='address-search-results-scene'
      header={<SimpleNavigationTitle showAccount showBackButton />}
    >
      <div style={{ margin: '8px 8px 40px', display: 'flex', justifyContent: 'center' }}>
        <AddressSearchResultsContainer addresses={addresses} onPress={selectAddress} />
      </div>
    </StackNavigationBase>
  )
}
