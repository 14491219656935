import React, { useEffect, useState } from 'react'
import { ISwrveEmbeddedMessageConfig, SwrveSDK } from '@swrve/web-sdk'
import { useDispatch } from 'react-redux'

import { IPayLoad, SwrveClient, SwrveContext, SwrveEventKeys } from './swrve-context'
import { useCountryCode, useFeatures, useLaunchDarklyID, useReport } from '@dominos/hooks-and-hocs'
import { getCustomerId } from '@dominos/business/functions/customer'
import { isNativeApp } from '@dominos/business/functions/native-app/is-native-app'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { rootActions } from '@dominos/business'
import { ICampaign, SwrveCampaignPopup } from '@dominos/components/swrve-campaign-popup'

export const SwrveProvider = ({ children }: { children: React.ReactNode }) => {
  const [swrveCampaign, setSwrveCampaign] = useState<ICampaign>({
    shouldDisplay: false,
  })
  const { SWRVE_API_KEY: apiKey, SWRVE_STACK: stack, SWRVE_APP_ID } = getCountryConfig()
  const launchDarklyID = useLaunchDarklyID()
  const dispatch = useDispatch()
  const { featureEnabled } = useFeatures()
  const { reportGenericError } = useReport()
  const [isSwrveNotificationsEnabled] = featureEnabled('SwrveWebSdk')
  const countryCode = useCountryCode()
  useEffect(() => {
    if (isEnabled() && validConfig()) {
      const customerId = getCustomerId()
      const externalUserId = customerId || launchDarklyID
      initialise(externalUserId, () => onInitialisationCallback(!!customerId))
    }
  }, [launchDarklyID, countryCode, isSwrveNotificationsEnabled])

  const onInitialisationCallback = (isLoggedIn: boolean) => {
    SwrveSDK.userUpdate({
      country_code: countryCode!,
    })
    event('account_status', { loggedIn: String(isLoggedIn) })
  }

  const validConfig = () => [apiKey, stack, SWRVE_APP_ID].every(Boolean)
  const isEnabled = () => isSwrveNotificationsEnabled && !isNativeApp()

  const initialise = (externalUserId = launchDarklyID, onLoaded = () => {}, autoPushSubscribe = false) => {
    if (!isEnabled()) return

    const embeddedMessageConfig: ISwrveEmbeddedMessageConfig = {
      embeddedCallback: (message) => {
        try {
          const campaignMessage = JSON.parse(message.data)
          setSwrveCampaign({ shouldDisplay: true, campaign: campaignMessage })
        } catch (error) {
          reportGenericError({
            operationName: 'Failed to parse swrve campaign message',
            reason: message.data,
          })
        }
      },
    }

    try {
      SwrveSDK.initWithConfig({
        appId: parseInt(SWRVE_APP_ID as string),
        apiKey,
        stack,
        externalUserId,
        userVisibleOnly: true,
        autoPushSubscribe: autoPushSubscribe, //Prompts for push notification permission
        serviceWorker: 'SwrveWorker.js',
        embeddedMessageConfig,
      })
      SwrveSDK.checkCoreInstance().onCampaignLoaded(onLoaded)
      const swrveId = SwrveSDK.getUserId()
      dispatch(rootActions.updateWebPushNotification({ webPushNotificationClientId: swrveId }))
    } catch (error) {
      reportGenericError({
        operationName: 'Failed to initialise Swrve',
        reason: error,
      })
    }
  }

  const reset = (customerId?: string, onReset?: () => void, autoPushSubscribe?: boolean) => {
    if (!isEnabled()) return
    try {
      SwrveSDK.shutdown()
      initialise(customerId, onReset, autoPushSubscribe)
    } catch (error) {
      reportGenericError({
        operationName: 'Failed to reset Swrve',
        error,
      })
    }
  }

  const event = (eventName: SwrveEventKeys, payload?: IPayLoad) => {
    if (!isEnabled()) return

    SwrveSDK.event(eventName, payload)
  }

  const value: SwrveClient = {
    reset,
    event,
  }

  const handleCampaignClosePopup = () => {
    setSwrveCampaign({ shouldDisplay: false })
  }

  return (
    <SwrveContext.Provider value={value}>
      <SwrveCampaignPopup onClose={handleCampaignClosePopup} swrveCampaign={swrveCampaign} />
      {children}
    </SwrveContext.Provider>
  )
}
