import { getDimensionFromDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'
import { DimensionSetState } from 'packages/hooks-and-hocs'
type DimensionSet = Bff.Dimensions.DimensionSet

export const transformDimensionSetStateToBasketLinePartial = (
  defaultDimensionSet: DimensionSet | undefined,
  dimensionSetState: DimensionSetState,
) => {
  const defaultBase = getDimensionFromDimensionSet('Base', defaultDimensionSet)
  const selectedBase = dimensionSetState.getSelectedDimension('Base')
  const selectedSize = dimensionSetState.getSelectedDimension('Size')
  const hasBaseChanged = selectedBase !== defaultBase

  return {
    sizeCode: selectedSize ? selectedSize : null,
    ...(hasBaseChanged && selectedBase
      ? {
          base: { add: selectedBase, remove: defaultBase } as BasketLineSwap,
        }
      : {}),
  }
}
