import { LDContext, LDProvider, ProviderConfig } from 'launchdarkly-react-client-sdk'
import React, { useState } from 'react'
import { useConfigContext } from '@dominos/hooks-and-hocs'

interface LaunchDarklyContextProps {
  setLDContext: React.Dispatch<React.SetStateAction<LDContext | undefined>>
}

const LaunchDarklyContext = React.createContext<LaunchDarklyContextProps>({
  setLDContext: () => {},
})

export const LaunchDarklyProvider = ({ children }: PropsWithChildren) => {
  const { applicationConfig } = useConfigContext()
  const [context, setContext] = useState<LDContext>()

  const config: ProviderConfig = {
    clientSideID: applicationConfig.LAUNCH_DARKLY_CLIENT_SIDE_ID,
    options: {
      privateAttributes: ['email', 'ip'],
      sendEventsOnlyForVariation: true,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
      sendEventsOnFlagRead: true,
    },
    // this defers the SDK initialisation until context is set
    deferInitialization: true,
  }

  return (
    <LaunchDarklyContext.Provider value={{ setLDContext: setContext }}>
      <LDProvider {...config} context={context}>
        {children}
      </LDProvider>
    </LaunchDarklyContext.Provider>
  )
}

export const useLaunchDarklyContext = () => React.useContext(LaunchDarklyContext)
