import React, { useEffect } from 'react'
import { SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'
import { AddressProvider } from '@dominos/hooks-and-hocs/address/providers/address-provider'
import { DeliveryAddressSearchAutoComplete } from '@dominos/components/address/features/delivery/delivery-address-search-autocomplete'
import { useFeatures } from 'olo-feature-address'
import { useGeoLocation } from '@dominos/hooks-and-hocs'

export const DeliveryAddressSearchAutoCompleteScene = () => {
  const { featureEnabled } = useFeatures()
  const [autoCompleteCurrentLocation] = featureEnabled('DeliveryAddressSearch-UseCurrentLocation')
  const { location, permitted, getLocation, requestLocationPermission } = useGeoLocation()

  const shareLocation = async () => {
    await requestLocationPermission()
    await getLocation()
  }

  useEffect(() => {
    if (!autoCompleteCurrentLocation) return
    shareLocation()
  }, [])

  return (
    <StackNavigationBase
      testID={'address-search-scene'}
      header={<SimpleNavigationTitle showAccount showBackButton />}
      modification='map-view'
    >
      <AddressProvider>
        <DeliveryAddressSearchAutoComplete
          locationDetectionPermitted={autoCompleteCurrentLocation && permitted}
          detectedLocation={location}
        />
      </AddressProvider>
    </StackNavigationBase>
  )
}
