import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { SwapType } from '@dominos/interfaces'
import { AnalyticSender, UpsellFeatureType } from './analytics'
import { SecurityEventNames, SecuritySender, toProducts } from './security'
import { applicationName } from '@dominos/business/functions'

declare type ReportConvertibleProduct =
  | BasketLine[]
  | Bff.Orders.OrderDetails.Basket.ProductLine[]
  | ProductMenuItem[]
  | MenuItemDependents[]
  | BasketLineUnionDependancy[]

declare type CardType = 'Visa' | 'Mastercard' | 'AmericanExpress'
export const reportProduct = (
  sendSecurity: SecuritySender,
  sendAnalytics: AnalyticSender,
  currentOrder?: CurrentOrderDetailsReducerProps,
) => {
  const reportProductAddToCart = (lines: ReportConvertibleProduct, couponCode?: string) => {
    reportProductAction('Flow.Basket.ProductAddedToBasket', lines, couponCode)

    sendAnalytics('add_to_cart', {
      items: toProducts(lines, couponCode),
    })
  }

  const reportProductRemoveFromCart = (lines: ReportConvertibleProduct, couponCode?: string) => {
    reportProductAction('Flow.Basket.ProductRemovedFromBasket', lines, couponCode)

    sendAnalytics('remove_from_cart', {
      items: toProducts(lines, couponCode),
    })
  }

  const reportProductReplaceInCart = (
    add: ReportConvertibleProduct,
    remove: ReportConvertibleProduct,
    couponCode?: string,
    swapType?: SwapType,
  ) => {
    reportProductAction('Flow.Basket.ProductReplacedInBasket', add, couponCode, remove)

    sendAnalytics('remove_from_cart', {
      items: toProducts(remove, couponCode, swapType),
    })

    sendAnalytics('add_to_cart', {
      items: toProducts(add, couponCode, swapType),
    })
  }

  const reportUpsellProductAddToCart = (lines: ReportConvertibleProduct, upsellFeature: UpsellFeatureType) => {
    sendAnalytics('add_to_cart', {
      items: toProducts(lines),
      upsell_feature: upsellFeature,
    })
  }

  const reportProductAction = (
    name: SecurityEventNames,
    lines: ReportConvertibleProduct,
    couponCode?: string,
    replaced?: ReportConvertibleProduct,
  ) => {
    const data: IBasketEventParams = {
      'Order ID': currentOrder?.orderId,
      Items: toProducts(lines, couponCode),
    }
    if (replaced) {
      data.Replaced = toProducts(replaced, couponCode)
    }

    sendSecurity(name, data)
  }

  const reportProductClick = (products: ReportConvertibleProduct, listName?: string) => {}

  const reportProductView = (products: ReportConvertibleProduct, listName?: string) => {}

  const reportCheckoutCustomerDetails = (
    products: ReportConvertibleProduct,
    type?: BffContext.PaymentMethods,
    card?: CardType,
  ) => {}

  const reportCheckoutPaymentMethod = (
    products: ReportConvertibleProduct,
    type?: BffContext.PaymentMethods,
    card?: CardType,
  ) => {}

  const reportCheckoutPaymentForm = (
    products: ReportConvertibleProduct,
    type?: BffContext.PaymentMethods,
    card?: CardType,
  ) => {}

  const reportCheckoutProcessing = (
    products: ReportConvertibleProduct,
    type?: BffContext.PaymentMethods,
    card?: CardType,
  ) => {}

  const reportHardUpsellProductAddedToCart = (lines: ReportConvertibleProduct) => {
    sendAnalytics('side_added_from_hardupsell', {
      event: 'side_added_from_hardupsell',
      items: toProducts(lines),
      platform: applicationName(),
    })
  }

  const reportHardUpsellSkip = () => {
    sendAnalytics('hardupsell_skip', {
      event: 'hardupsell_skip',
      platform: applicationName(),
    })
  }

  const reportHardUpsellInitialised = () => {
    sendAnalytics('hardupsell_initialised', {
      event: 'hardupsell_initialised',
      platform: applicationName(),
    })
  }

  const reportProductUpsellEnabled = () => {
    sendAnalytics('premium_upsell_enabled')
  }

  const reportProductUpsellAdded = () => {
    sendAnalytics('premium_upsell_added')
  }

  const reportProductUpsellRemoved = () => {
    sendAnalytics('premium_upsell_removed')
  }

  const reportPortionSwap = (
    productCode: string,
    portionIndex: number,
    swappedInPortionProductCode: string | undefined,
    swappedOutPortionProductCode: string | undefined,
  ) => {
    sendAnalytics('portion_swap', {
      parent_item_id: productCode,
      swap_position: portionIndex,
      swapped_in_item_id: swappedInPortionProductCode,
      swapped_out_item_id: swappedOutPortionProductCode,
    })
  }

  return {
    reportPortionSwap,
    reportProductAddToCart,
    reportProductRemoveFromCart,
    reportProductReplaceInCart,
    reportUpsellProductAddToCart,
    reportProductClick,
    reportProductView,
    reportCheckoutCustomerDetails,
    reportCheckoutPaymentMethod,
    reportCheckoutPaymentForm,
    reportCheckoutProcessing,
    reportHardUpsellProductAddedToCart,
    reportHardUpsellSkip,
    reportHardUpsellInitialised,
    reportProductUpsellEnabled,
    reportProductUpsellAdded,
    reportProductUpsellRemoved,
  }
}
