import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { savedVouchersQuery } from '../../business/queries'
import { useFeatures } from '../features'

const useSavedVouchers = () => {
  const { featureEnabled } = useFeatures()
  const [savedVouchers] = featureEnabled('saved-vouchers')
  const [activeVouchers, setActiveVouchers] = useState<Bff.Vouchers.Voucher[]>([])
  const [expiredVouchers, setExpiredVouchers] = useState<Bff.Vouchers.Voucher[]>([])

  const withinTimeRange = (end: string, now: number) => new Date(end).getTime() - now > 0

  const withinAllowedRestrictions = (voucher: Bff.Vouchers.Voucher) => {
    if (!voucher.conditions.isRestrictedUse) {
      return true
    }

    return voucher.conditions.restrictions ? voucher.conditions.restrictions.remainingUses > 0 : true
  }

  const { data, error, loading } = useQuery<{ savedVouchers: Bff.Vouchers.Voucher[] }>(savedVouchersQuery, {
    fetchPolicy: 'no-cache',
    skip: !savedVouchers,
  })

  useEffect(() => {
    if (data && data.savedVouchers.length > 0) {
      const now = new Date().getTime()
      const active: Bff.Vouchers.Voucher[] = []
      const expired: Bff.Vouchers.Voucher[] = []
      data?.savedVouchers.forEach((voucher) => {
        const isActive = withinTimeRange(voucher.expiryDate, now) && withinAllowedRestrictions(voucher)
        isActive ? active.push(voucher) : expired.push(voucher)
      })

      setActiveVouchers(active)
      setExpiredVouchers(expired)
    }
  }, [data])

  return {
    activeVouchers,
    expiredVouchers,
    error,
    loading,
  }
}

export { useSavedVouchers }
