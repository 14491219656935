import { useMutation, useQuery } from '@apollo/client'
import { isNativeApp } from '@dominos/business/functions/native-app/is-native-app'
import { notifyNativeApp } from '@dominos/business/functions/native-app/notify-native-app'
import { customerLoyaltyQuery, loyaltyEnrollMutation } from '@dominos/business/queries'
import { rootActions } from '@dominos/business/root.actions'
import { getDeclinedVouchers } from '@dominos/components/voucher/voucher-input/voucher-input-helpers'
import { useAlert, useBasket, useCustomer, useFeatures } from '@dominos/hooks-and-hocs'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

const MAX_REDEMPTIONS = 5

export const useLoyalty = () => {
  const dispatch = useDispatch()
  const { customer } = useCustomer()
  const { t } = useTranslation('loyalty')
  const { showAlert } = useAlert()
  const { getBooleanVariation } = useFeatures()
  const [enrollCustomer, { loading: enrollCustomerLoading }] = useMutation(loyaltyEnrollMutation)
  const storeEnabled = useSelector(
    (state: RootReducer) =>
      state.storeReducer.currentStore &&
      state.storeReducer.currentStore.loyalty &&
      state.storeReducer.currentStore.loyalty.isEnabled,
  )
  const { pending: loadingBasket, basket, pendingLoyaltyCoupons, validationErrors } = useBasket()

  const { data: loyaltyData } = useQuery<{ customer: Bff.Customers.Customer | null }>(customerLoyaltyQuery, {
    variables: { customerId: customer?.id },
    skip: !customer?.id,
  })

  const appliedCoupons = useMemo(
    () => basket.lines.filter((line) => line.type === 'BasketCoupon' && line.isLoyaltyRedemption === true),
    [basket],
  )

  const enroll = useCallback(
    async (marketingConsent: boolean = false) =>
      enrollCustomer({ variables: { marketingConsent } }).catch(() => {
        showAlert(t('RewardsEnrollError', 'Unable to enroll, please try again.'), 'RewardsEnrollError')
      }),
    [enrollCustomer, showAlert],
  )

  const storeSelected = useSelector((state: RootReducer) => state.storeReducer.currentStore != null)
  const featureEnabled = getBooleanVariation('loyalty-backend')
  const loyaltyFeatureEnabled =
    (!storeSelected && featureEnabled) || (storeSelected && featureEnabled && !!storeEnabled)
  const loyalty = loyaltyData?.customer?.loyalty
  const isEnrolled = !!loyalty?.subscription?.isEnrolled
  const pointsUntilRedeemable = loyalty?.balance?.pointsToNextFreePizza
  const numAvailableLoyaltyPizzas = loyalty?.balance?.numOfFreePizzas || 0
  const totalRedeemedPizzas = appliedCoupons.length + pendingLoyaltyCoupons
  const totalAvailablePizzas = numAvailableLoyaltyPizzas - totalRedeemedPizzas

  const errors = useMemo(() => getDeclinedVouchers(validationErrors), [validationErrors])

  const isAvailable =
    loyaltyFeatureEnabled && isEnrolled && totalAvailablePizzas > 0 && totalRedeemedPizzas < MAX_REDEMPTIONS

  const loading = enrollCustomerLoading || loadingBasket

  const redeemPizza = () => {
    if (isAvailable) {
      if (isNativeApp()) {
        notifyNativeApp('redeem-loyalty-reward')
      } else {
        dispatch(rootActions.addLoyaltyCoupon())
      }
    }
  }

  const clearValidationErrors = () => dispatch(rootActions.clearValidationErrors())

  return {
    enroll,
    featureEnabled,
    storeEnabled,
    isAvailable,
    isEnrolled,
    errors,
    pointsUntilRedeemable,
    totalAvailablePizzas,
    totalRedeemedPizzas,
    clearValidationErrors,
    redeemPizza,
    loading,
  }
}
