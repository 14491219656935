import React, { useEffect, useState } from 'react'
import { useBreakpoints, useCountryCode } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { useTranslation } from 'react-i18next'
import { GenericCard } from '../../cards'
import { DeliveryAddressSearchByPostCodeComponent } from '../search-address-postcode-component/delivery-address-search-postcode-component'
import { ActionButton } from '../../buttons'
import { navigate } from '@reach/router'
import { AdditionalAddressFieldsComponent } from '../additional-address-fields-component/additional-address-fields-component'
import { mapSearchAddressToAddressLine } from '@dominos/business/functions'
import { AddressFieldsDependency, AddressInputFieldSettings } from '@dominos/interfaces/delivery-address'
import { AddressFieldsUpdate } from '../additional-address-fields-component/additional-address-fields.interface'
import { GenericSelect } from '@dominos/components/select/generic-select'

const SELECT_HEIGHT = 44

interface DeliveryAddressPostCodeContainerProps {
  postCodeLength: number
  numericValesOnly?: boolean
  maxNumberPostCodeResults: number
  addressFieldTypeOptions: AddressFieldTypeOptions[]
  addressFieldsFromAddressSearchResult?: AddressFieldsGroup
  additionalAddressInformationRequired?: AddressFieldsGroup
  hasClearInputButtonPostcodeSearch?: boolean
  hasClearInputButtonAdditionalAddressFields?: boolean
}

interface AddressFieldsGroup {
  additionalAddressFields: AddressInputFieldSettings[]
  addressFieldsDependency?: AddressFieldsDependency[]
}

interface AddressFieldTypeOptions {
  /**
   * This label will be translated,
   * ensure it aligns with a translation key.
   */
  optionLabel: string
  optionValue: string
  addressFieldNames: string[]
}

export const DeliveryAddressPostCodeContainer = ({
  postCodeLength,
  numericValesOnly,
  maxNumberPostCodeResults,
  addressFieldTypeOptions,
  addressFieldsFromAddressSearchResult,
  additionalAddressInformationRequired,
  hasClearInputButtonPostcodeSearch,
  hasClearInputButtonAdditionalAddressFields,
}: DeliveryAddressPostCodeContainerProps) => {
  const { isMobile } = useBreakpoints()
  const { t } = useTranslation('delivery-address')
  const countryCode = useCountryCode()
  const [searchAddress, setSearchAddress] = useState<SearchAddress | undefined>(undefined)
  const [hasValidAddressDetails, setHasValidAddressDetails] = useState<boolean>(false)
  const [additionalAddressDetails, setAdditionalAddressDetails] = useState<AddressFieldsUpdate | undefined>(undefined)
  const [additionalAddressFieldsDisplay, setAdditionalAddressFieldsDisplay] = useState<
    AddressInputFieldSettings[] | undefined
  >()
  const [selectedAddressType, setSelectedAddressType] = useState<string | undefined>()

  const setSelectedAddress = (address: SearchAddress | undefined) => {
    setSearchAddress(address)
  }
  const showContinueButton = searchAddress && selectedAddressType

  const addressFieldsToDisplayBasedOnAddressType = (value: string) =>
    addressFieldTypeOptions.find((x) => x.optionValue === value)?.addressFieldNames

  const selectedOptions: GenericSelectItem[] | undefined = addressFieldTypeOptions.map((addressOptions) => ({
    label: t(addressOptions.optionLabel),
    value: addressOptions.optionValue,
  }))

  const onSelectChange = (value: string) => {
    setSelectedAddressType(value)
    const fieldNames = addressFieldsToDisplayBasedOnAddressType(value)
    setAdditionalAddressFieldsDisplay(
      additionalAddressInformationRequired?.additionalAddressFields?.filter((x) =>
        fieldNames?.includes(x.addressFieldName),
      ),
    )
  }

  const navigateNextPage = () => {
    if (searchAddress) {
      const updatedSearchAddress = getSearchAddressWithAdditionalFields(
        searchAddress,
        additionalAddressDetails?.Address,
        countryCode,
      )
      navigate(NavigationConstants.addressSearchResults, {
        state: { deliverySearch: [updatedSearchAddress], skipAddressSelection: true },
      })
    }
  }

  useEffect(() => {
    setHasValidAddressDetails(isValidAddress(searchAddress, additionalAddressDetails))

    if (!searchAddress) {
      // resets address type when search address is cleared
      setSelectedAddressType(undefined)
      setAdditionalAddressFieldsDisplay(undefined)
    }
  }, [searchAddress, additionalAddressDetails])

  return (
    <GenericCard
      testID='delivery-address.Card'
      title={t('Delivery Address')}
      noPadding={true}
      width={isMobile ? magicStyles.mobile.staticCardWidth : magicStyles.desktop.staticCardWidth}
    >
      <div style={magicStyles.componentsWrapper}>
        <DeliveryAddressSearchByPostCodeComponent
          testID={'postal-code'}
          deliveryAddressSelectedCallBack={setSelectedAddress}
          numericValesOnly={numericValesOnly}
          maxNumberResultsDisplay={maxNumberPostCodeResults}
          postCodeLength={postCodeLength}
          hasClearInputButton={hasClearInputButtonPostcodeSearch}
        />
        {searchAddress && (
          <div style={magicStyles.addressFieldsWrapper}>
            <AdditionalAddressFieldsComponent
              testID={'street-field'}
              searchAddress={searchAddress}
              addressInputFieldsSettings={addressFieldsFromAddressSearchResult?.additionalAddressFields ?? []}
              hasClearInputButton={hasClearInputButtonAdditionalAddressFields}
            />
          </div>
        )}
        {searchAddress && (
          <div style={{ marginBottom: '15px' }}>
            <GenericSelect
              testID={'address-types.selector'}
              options={selectedOptions}
              placeholder={t('addressType')}
              selectedValue={selectedAddressType}
              height={SELECT_HEIGHT}
              onChange={onSelectChange}
            />
          </div>
        )}
        {searchAddress && additionalAddressFieldsDisplay && (
          <div style={magicStyles.addressFieldsWrapper}>
            <AdditionalAddressFieldsComponent
              testID={'additional-fields'}
              searchAddress={searchAddress}
              onChange={setAdditionalAddressDetails}
              addressInputFieldsSettings={additionalAddressFieldsDisplay}
              hasClearInputButton={hasClearInputButtonAdditionalAddressFields}
            />
          </div>
        )}
        {showContinueButton && (
          <ActionButton
            testID='address-confirmed-continue-button'
            onPress={navigateNextPage}
            text={t('Continue')}
            containerStyle={{ width: '100%', marginTop: '8.6px', backgroundColor: '#007AFF' }}
            textFontWeight='semibold'
            disabled={!hasValidAddressDetails}
          />
        )}
      </div>
    </GenericCard>
  )
}

const getAddressDisplay = (searchAddress: SearchAddress, countryCode?: BffContext.Countries) => {
  const result = mapSearchAddressToAddressLine(countryCode, [searchAddress])

  return result[0]?.name
}

const isValidAddress = (searchAddress: SearchAddress | undefined, addressFieldsUpdate?: AddressFieldsUpdate) =>
  (!!searchAddress && addressFieldsUpdate?.isValid) ?? false

const getSearchAddressWithAdditionalFields = (
  searchAddress: SearchAddress,
  address?: TAddress,
  countryCode?: BffContext.Countries,
): SearchAddress | undefined => {
  if (!searchAddress?.rawAddress) return undefined

  return getUpdatedSearchAddress(searchAddress, address, countryCode)
}

const getUpdatedSearchAddress = (
  searchAddress: SearchAddress,
  additionalAddressDetails?: TAddress,
  countryCode?: BffContext.Countries,
): SearchAddress => {
  const address: SearchAddress = {
    ...searchAddress,
    rawAddress: {
      ...searchAddress.rawAddress,
      unitNo: searchAddress.rawAddress.unitNo
        ? searchAddress.rawAddress.unitNo
        : additionalAddressDetails?.unitNo?.value ?? '',
      floorNo: searchAddress.rawAddress.floorNo ?? additionalAddressDetails?.floorNo?.value,
      buildingName: searchAddress.rawAddress.buildingName ?? additionalAddressDetails?.buildingName?.value,
      streetNo: searchAddress.rawAddress.streetNo,
      street: searchAddress.rawAddress.street,
      suburb: searchAddress.rawAddress.suburb,
      state: searchAddress.rawAddress.state,
      postCode: searchAddress.rawAddress.postCode,
    },
  }

  address.media = {
    displayAddress: getAddressDisplay(address, countryCode),
  }

  return address
}

const magicStyles = {
  mobile: {
    staticCardWidth: '360px',
  },
  desktop: {
    staticCardWidth: '376px',
  },
  addressFieldsWrapper: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  componentsWrapper: {
    paddingLeft: '14px',
    paddingRight: '14px',
    paddingBottom: '6px',
  },
}
