import { useValidation, useValidationJP } from '@dominos/business/functions/validation'
import { BoxedMessage, Checkbox, ValidationTextField } from '@dominos/components'
import { MobileField } from '@dominos/components/inputs/mobile-field'
import { useCountryCode, useCustomer, useFeatures, useKiosk, useLanguages } from '@dominos/hooks-and-hocs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { detailsFieldNames } from '../details-container-config'
import { MyDetailsFormProps } from './my-details-form.interface'
import styles from './my-details.less'

export const MyDetailsForm = (props: MyDetailsFormProps) => {
  const { isLoggedIn: isCustomerLoggedIn } = useCustomer()
  const { t } = useTranslation('checkout')
  const countryCode = useCountryCode()
  const { language } = useLanguages()
  const { isEmailValid, isRequired, isNameValid } = useValidation()
  const { validateIsKatakana } = useValidationJP()
  const { featureEnabled } = useFeatures()
  const { isKioskOrder } = useKiosk()

  const isJapan = !!countryCode && countryCode === 'JP'
  const isJALanguage = !!language && language === 'ja'
  const [companyFieldEnabled, nameRequiredLegalTextEnabled] = featureEnabled(
    'CustomerDetailsCompanyField',
    'CustomerDetailsNameLegalText',
  )

  const defaultValidationSet = {
    [detailsFieldNames.name]: false,
    [detailsFieldNames.alternateName]: false,
    [detailsFieldNames.email]: false,
    [detailsFieldNames.mobileNumber]: false,
  }
  const [validationState, setValidationState] = useState(defaultValidationSet)

  const handleRememberMyDetailsChange = (field: string | null, value: boolean) => {
    props.onChange(field!, value)
  }

  const handleChange =
    (key: string) => (updatedField: { [K: string]: { value?: string | boolean; isValid: boolean } }) => {
      props.onChange(key, updatedField[key].value)
      setValidationState((prev) => ({
        ...prev,
        [key]: updatedField[key].isValid,
      }))
    }

  useEffect(() => {
    const newValidationState = { ...validationState }

    if (!isJALanguage) {
      delete newValidationState[detailsFieldNames.alternateName]
    }

    if (isKioskOrder) {
      delete newValidationState[detailsFieldNames.mobileNumber]
      delete newValidationState[detailsFieldNames.email]
    }

    props.onFormValidationChange(Object.values(newValidationState).reduce((l, r) => l && r, true))
  }, [isJALanguage, validationState])

  return (
    <>
      <ValidationTextField
        key={detailsFieldNames.name}
        fieldName={detailsFieldNames.name}
        placeholder={t('Name')}
        testID={`${detailsFieldNames.name}.field`}
        validationRules={[isRequired, isNameValid]}
        defaultValue={props.initialDetails?.name}
        style={{ width: '100%' }}
        onChange={handleChange(detailsFieldNames.name)}
      />
      {nameRequiredLegalTextEnabled && (
        <BoxedMessage
          icon='disclaimer'
          testID='name-required-legal-text'
          variant='contained'
          color='secondary'
          textSize='large'
        >
          {t('NameRequiredLegalText', { defaultValue: 'A name is required to complete your order' })}
        </BoxedMessage>
      )}
      {isJapan && isJALanguage && (
        <ValidationTextField
          key={detailsFieldNames.alternateName}
          fieldName={detailsFieldNames.alternateName}
          placeholder={t('AlternateName')}
          testID={`${detailsFieldNames.alternateName}.field`}
          validationRules={[isRequired, validateIsKatakana]}
          defaultValue={props.initialDetails?.alternateName}
          style={{ width: '100%' }}
          onChange={handleChange(detailsFieldNames.alternateName)}
        />
      )}
      {!isKioskOrder && (
        <MobileField
          fieldName={detailsFieldNames.mobileNumber}
          defaultValue={props.initialDetails?.mobileNumber}
          style={{ width: '100%' }}
          onChange={handleChange(detailsFieldNames.mobileNumber)}
        />
      )}
      {companyFieldEnabled && !isKioskOrder && (
        <ValidationTextField
          key={detailsFieldNames.company}
          fieldName={detailsFieldNames.company}
          placeholder={t('Company')}
          testID={`${detailsFieldNames.company}.field`}
          style={{ width: '100%' }}
          onChange={handleChange(detailsFieldNames.company)}
        />
      )}
      {!isKioskOrder && (
        <ValidationTextField
          key={detailsFieldNames.email}
          fieldName={detailsFieldNames.email}
          placeholder={t('Email Address')}
          testID={`${detailsFieldNames.email}.field`}
          validationRules={[isRequired, isEmailValid]}
          defaultValue={props.initialDetails?.emailAddress}
          type='email'
          style={{ width: '100%' }}
          onChange={handleChange(detailsFieldNames.email)}
        />
      )}
      {!isKioskOrder && isCustomerLoggedIn === false && (
        <div style={{ marginBottom: '10px ' }}>
          <Checkbox
            name={detailsFieldNames.rememberMyDetails}
            testID={`${detailsFieldNames.rememberMyDetails}.field`}
            onChange={handleRememberMyDetailsChange}
            defaultChecked={!!props.initialRememberDetails}
          >
            {t('Remember My Details')}
          </Checkbox>
        </div>
      )}
      {!isJapan && <hr className={styles.hr} />}
    </>
  )
}
