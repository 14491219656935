import { AboutPricing, DetailButton, GenericCard, HomeAdvertSpot } from '@dominos/components'
import {
  useBreakpoints,
  useDeliveryAddresses,
  useDominosTheme,
  useFeatures,
  useIsB2BConfigLoaded,
  useReport,
} from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { StaticSvgIcon } from '@dominos/res/images'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import css from './home-container.less'
import { LoyaltyHomeRewards } from '../../loyalty/loyaltyTwo/LoyaltyHomeRewards'
import { useIsAuthenticated } from '@dominos/components/login-b2b/AuthProviderB2B'
import { B2BAuth } from '@dominos/components/login-b2b/B2BAuth'
import { rootActions } from '@dominos/business/root.actions'
import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'

import { validatePickupDeliveryButtons } from './functions'

export const HomeContainer = () => {
  const { reportInteraction } = useReport()
  const { isMobile } = useBreakpoints()
  const theme = useDominosTheme()
  const { t } = useTranslation('home')
  const { featureEnabled } = useFeatures()
  const dispatch = useDispatch()

  const isB2BConfigLoaded = useIsB2BConfigLoaded()

  const b2BAuth = B2BAuth
  const LoggedIn = useIsAuthenticated()

  const [showPickUp, setShowPickUp] = useState(false)
  const [showDelivery, setShowDelivery] = useState(false)

  const [
    showAboutOurPricingMessage,
    isB2BOrderEnabled,
    autoCompletePickupEnabled,
    autoCompleteDeliveryEnabled,
    autoCompleteListMapDeliveryAddress,
    showDeliveryAddress,
    autoCompleteDeliveryAddressSearchFeature,
  ] = featureEnabled(
    'ShowAboutOurPricingMessage',
    'IsB2BOrderEnabled',
    'AutoCompletePickupAddress',
    'AutoCompleteDeliveryAddress',
    'AutoCompleteListMapDeliveryAddress',
    'ShowDeliveryAddressListForOldCustomer',
    'DeliveryAddressSearch-Feature',
  )

  const addresses = useDeliveryAddresses()

  useEffect(() => {
    if (isB2BOrderEnabled) {
      B2BAuth.acquireToken()
      validateButtons()
    }
  }, [isB2BOrderEnabled, isB2BConfigLoaded])

  const validateButtons = () => {
    const buttonValidation = validatePickupDeliveryButtons(isB2BOrderEnabled)
    setShowPickUp(buttonValidation.localShowPickUp)
    setShowDelivery(buttonValidation.localShowDelivery)
  }

  const onPickup = () => {
    reportInteraction('Pickup')
    dispatch(rootActions.resetAutoCompleteScenario())
    navigate(
      autoCompletePickupEnabled ? NavigationConstants.pickupAddressAutoComplete : NavigationConstants.storeSearch,
    )
  }

  const onDelivery = () => {
    reportInteraction('Delivery')

    if (
      autoCompleteDeliveryAddressSearchFeature ||
      (autoCompleteDeliveryEnabled && autoCompleteListMapDeliveryAddress)
    ) {
      navigate(NavigationConstants.deliveryAddressAutoComplete)

      return
    }

    if (showDeliveryAddress && addresses.length === 0) {
      if (autoCompleteDeliveryEnabled) {
        navigate(NavigationConstants.deliveryAddressAutoComplete)
      } else {
        navigate(NavigationConstants.deliveryAddress)
      }
    } else {
      navigate(NavigationConstants.deliveryAddressList)
    }
  }

  const onLoginB2B = () => {
    b2BAuth.startAuthentication()
    validateButtons()
  }

  return (
    <>
      <div className={css.header}>
        <HomeAdvertSpot testID='home-advert-spot' />
      </div>
      <div
        className={css.grid}
        style={{ top: isMobile ? magicStyles.mobile.top : magicStyles.desktop.top }}
        data-testid='home-scene-card-wrapper'
      >
        <div
          data-testid='home-scene-card-spacer'
          style={{
            width: '100%',
            maxWidth: isMobile ? magicStyles.mobile.staticCardWidth : magicStyles.desktop.staticCardWidth,
          }}
        >
          <LoyaltyHomeRewards />
          {(!isB2BOrderEnabled || (isB2BOrderEnabled && LoggedIn)) && (
            <GenericCard width={'100%'} testID='Home.ServiceMethodCard' title={t('Start Your Order')}>
              {(!isB2BOrderEnabled || (isB2BOrderEnabled && LoggedIn && showDelivery)) && (
                <div className={css.deliveryWrapper}>
                  <DetailButton
                    testID='DeliveryServiceSelection'
                    icon={<StaticSvgIcon name='delivery' isUnstyled={true} />}
                    color={theme.colours.tooltipText}
                    title={t('Delivery')}
                    backgroundColor={theme.colours.delivery}
                    onPress={onDelivery}
                  />
                </div>
              )}
              {(!isB2BOrderEnabled || (isB2BOrderEnabled && LoggedIn && showPickUp)) && (
                <div className={css.pickUpWrapper}>
                  <DetailButton
                    testID='PickupServiceSelection'
                    icon={<StaticSvgIcon name='pickUp' isUnstyled={true} />}
                    color={theme.colours.tooltipText}
                    backgroundColor={theme.colours.pickup}
                    title={t('Pick Up')}
                    onPress={onPickup}
                  />
                </div>
              )}
            </GenericCard>
          )}

          <div>
            {isB2BOrderEnabled && !LoggedIn && (
              <GenericCard width={'100%'} testID='Home.LoginB2B' title={t('Login')}>
                <div className={css.deliveryWrapper}>
                  <DetailButton
                    testID='LoginB2BButton'
                    color={theme.colours.tooltipText}
                    title={t('Login B2B')}
                    backgroundColor={theme.colours.black}
                    onPress={onLoginB2B}
                  />
                </div>
              </GenericCard>
            )}
          </div>
        </div>
        {showAboutOurPricingMessage && (
          <div className={css.homeScreenAboutPricing}>
            <AboutPricing testID={'home-screen-about-pricing'} />
          </div>
        )}
      </div>
    </>
  )
}

const magicStyles = {
  mobile: {
    staticCardWidth: '360px',
    top: 310,
  },
  desktop: {
    staticCardWidth: '376px',
    top: 380,
  },
}
