import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './kiosk-terms-and-conditions.less'
import { CollapsableTitledCard, TermsConditionsDisclaimers, useFooterTranslations } from '@dominos/components'
import { KioskLegalLink } from '@dominos/components/kiosk'
import { useFeatures } from '@dominos/hooks-and-hocs'

export const KioskTermsAndConditions = ({ style }: { style?: React.CSSProperties }) => {
  const { t } = useTranslation('kiosk')
  const translations = useFooterTranslations()
  const testID = 'kiosk-terms-and-conditions'
  const { featureEnabled } = useFeatures()
  const [legalLinksEnable] = featureEnabled('KioskLegalLinks')

  return (
    <CollapsableTitledCard
      primaryTitle={t('TermsAndConditionsHeader', { defaultValue: 'Terms & Conditions' }).trim()}
      testID={testID}
      style={style}
      noPadding
      startCollapsed
    >
      <div className={css.contentWrapper}>
        <TermsConditionsDisclaimers testID={testID} />
        {legalLinksEnable &&
          translations.legalLinks.map((item) => (
            <div key={item.title} className={css.row}>
              <KioskLegalLink testID={testID} link={item.link} title={item.title} key={item.title} />
            </div>
          ))}
      </div>
    </CollapsableTitledCard>
  )
}
