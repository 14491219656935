import { BasketLineData } from '@dominos/business/functions/basket'
import {
  mapMenuItemToCrustItemData,
  mapMenuItemToOptionItemData,
  mapMenuItemToPortionSauceItemData,
  mapMenuItemToSauceItemData,
  mapMenuItemToSizeItemData,
} from '@dominos/business/functions/menu'
import {
  CrustIcon,
  isProductMenuItemNew,
  OptionIcon,
  ProductIngredientCard,
  SauceIcon,
  SizeIcon,
} from '@dominos/components'
import { useBreakpoints, useFeatures, useKiosk, useMenu } from '@dominos/hooks-and-hocs'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getScrollProvider, scrollTo } from '../../functions'
import { CustomisationCard } from '../customisation-card'
import { NUM_COLUMNS } from '../customisations.constants'
import { getVisibilityLogic } from './get-visibility-logic'
import { verifyIfProductIsPasta } from './verify-if-product-is-pasta'
import {
  ProductComponentUpsellLDVariation,
  ProductUpsellVariationTypes,
} from '@dominos/components/product/product-upsell/product-upsell.interface'
import { ProductUpsell } from '@dominos/components/product/product-upsell/product-upsell'

type ProductSize = Bff.Menu.old.ProductSize
type ProductMenuItem = Bff.Menu.old.ProductMenuItem
type PortionMenuItem = Bff.Menu.old.PortionMenuItem

// eslint-disable-next-line max-lines-per-function
export const CustomisationDefault = ({
  currentProduct,
  onSizeSelected,
  onRecipeSelected,
  onIngredientSelected,
  onSauceSelected,
  currentSize,
  item,
  restrictedHalfHalfEnabled,
  halfLeft,
  halfRight,
}: {
  currentProduct: BasketLineData
  onSizeSelected: (change: TouchableGridElement) => void
  onRecipeSelected: (key: 'sauce' | 'base' | 'options') => (change: TouchableGridElement) => void
  onIngredientSelected: (changes: BasketLineChange[]) => void
  onSauceSelected: (sauce: TouchableGridElement) => void
  currentSize?: ProductSize
  item?: { type: string; code: string }
  restrictedHalfHalfEnabled?: boolean
  halfLeft?: BasketLineData
  halfRight?: BasketLineData
}) => {
  const { isMobile } = useBreakpoints()
  const scrollRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('menu')
  const setScrollOffset = (distance: number) => scrollTo(distance, getScrollProvider(isMobile, scrollRef.current))
  const { menu } = useMenu()
  const { featureEnabled, getStringVariation } = useFeatures()
  const [pastaBaseLabelEnabled] = featureEnabled('PastaBaseLabel')
  const { isKioskOrder } = useKiosk()
  const productComponentUpsellVariation = getStringVariation('ProductComponentUpsellVariation')
  const isProductUpsellEnabled = productComponentUpsellVariation !== ProductComponentUpsellLDVariation.VariationNone

  const currentProductItem =
    currentProduct.item &&
    !isProductMenuItemNew(currentProduct.item) &&
    (currentProduct.item.type === 'Product' || currentProduct.item.type === 'Portion')
      ? currentProduct.item
      : undefined

  // TODO: we shouldn't have to check the type here! But for now it is ok until we refactor the ProductCard
  if (!currentProductItem) {
    return null
  }

  const VISIBILITY_LOGIC = getVisibilityLogic(
    menu!,
    currentProduct.item! as ProductMenuItem | PortionMenuItem,
    item,
    restrictedHalfHalfEnabled,
    halfLeft,
    halfRight,
  )

  const getBaseLabel = () =>
    pastaBaseLabelEnabled && menu && verifyIfProductIsPasta(menu, currentProductItem.code) ? t('PastaBase') : t('Base')

  return (
    <>
      {VISIBILITY_LOGIC.CUSTOMIZE_SIZE && (
        <CustomisationCard
          testID={'Size-Selector'}
          primaryTitle={t('Size')}
          numColumns={NUM_COLUMNS}
          mapMenuItemToItemData={mapMenuItemToSizeItemData}
          renderItem={SizeIcon}
          item={currentProductItem}
          onItemSelected={onSizeSelected}
          sizeCode={currentSize?.code || undefined}
        />
      )}

      {isProductUpsellEnabled && VISIBILITY_LOGIC.PRODUCT_UPSELL && (
        <ProductUpsell
          currentProduct={currentProduct}
          productUpsellVariation={ProductUpsellVariationTypes.VariationBanner}
          onUpsellSelected={onRecipeSelected('base')}
        />
      )}

      <CustomisationCard
        testID={'Crust-Selector'}
        primaryTitle={getBaseLabel()}
        numColumns={NUM_COLUMNS}
        mapMenuItemToItemData={mapMenuItemToCrustItemData}
        renderItem={CrustIcon}
        item={currentProductItem}
        onItemSelected={onRecipeSelected('base')}
        basketLineEdit={currentProduct.base}
        sizeCode={currentSize?.code || undefined}
      />

      {VISIBILITY_LOGIC.CUSTOMIZE_SAUCE && (
        <CustomisationCard
          testID={'Sauce-Selector'}
          primaryTitle={t('Sauce')}
          numColumns={NUM_COLUMNS}
          mapMenuItemToItemData={mapMenuItemToSauceItemData}
          renderItem={SauceIcon}
          item={currentProductItem}
          onItemSelected={onRecipeSelected('sauce')}
          basketLineEdit={currentProduct.sauce}
          sizeCode={currentSize?.code || undefined}
        />
      )}

      {VISIBILITY_LOGIC.CUSTOMIZE_PORTION_SAUCE && (
        <CustomisationCard
          testID={'Sauce-Selector'}
          primaryTitle={t('Sauce')}
          numColumns={NUM_COLUMNS}
          mapMenuItemToItemData={mapMenuItemToPortionSauceItemData(
            halfLeft?.item as ChangeableProductItem,
            halfRight?.item as ChangeableProductItem,
          )}
          renderItem={SauceIcon}
          item={currentProductItem}
          onItemSelected={onSauceSelected}
          basketLineEdit={currentProduct?.sauce}
          sizeCode={currentSize?.code || undefined}
        />
      )}

      {VISIBILITY_LOGIC.OPTIONS &&
        currentSize?.swaps?.options?.rule.max &&
        Array(currentSize?.swaps?.options?.rule.max)
          .fill(0)
          .map((__, index) => (
            <CustomisationCard
              key={index}
              testID={'Option-Selector'}
              primaryTitle={t('Option')}
              numColumns={NUM_COLUMNS}
              mapMenuItemToItemData={mapMenuItemToOptionItemData(index, t)}
              renderItem={OptionIcon}
              item={currentProductItem}
              onItemSelected={onRecipeSelected('options')}
              basketLineEdit={
                currentProduct.options?.find((op) => op.index === index && op.action === 'Add') ||
                currentProduct.options?.find((op) => op.index === index && op.action === 'Remove')
              }
              sizeCode={currentSize?.code || undefined}
              startExpanded={isKioskOrder && index === 0}
            />
          ))}

      {VISIBILITY_LOGIC.CUSTOMIZE_TOPPINGS && (
        <ProductIngredientCard
          primaryTitle={t('Ingredients on this Pizza')}
          item={currentProduct.item as ProductMenuItem}
          currentSizeCode={currentSize?.code}
          onItemChange={onIngredientSelected}
          toppingLineChange={currentProduct.toppings || []}
          onSectionChange={setScrollOffset}
        />
      )}
    </>
  )
}
