import { useLazyQuery } from '@apollo/client'
import { generateBatchAddressSearchQuery, generateBatchAddressSearchVariables } from '@dominos/business/queries'
import { useCountryCode } from '@dominos/hooks-and-hocs'
import { DocumentNode } from 'graphql'
import { useEffect, useState } from 'react'
import { mapBatchedAddresses } from './address-mappings'
import { AddressSearchResults } from './interfaces'

export const useBatchAddressSearch = (rawAddresses: (StoredCustomerAddress | Bff.Customers.CustomerAddress)[]) => {
  const countryCode = useCountryCode()
  const [addresses, setAddresses] = useState<StoredCustomerAddress[]>([])
  const hasAdditionalAddressFields: boolean = countryCode === 'JP' || countryCode === 'SG'

  const batchAddressesQuery: DocumentNode = generateBatchAddressSearchQuery(rawAddresses.length)
  const variables = generateBatchAddressSearchVariables(rawAddresses, countryCode)
  const [fetchBatchAddresses] = useLazyQuery<AddressSearchResults>(batchAddressesQuery, {
    fetchPolicy: 'no-cache',
    variables,
    onCompleted: (data: AddressSearchResults) =>
      setAddresses(mapBatchedAddresses(rawAddresses, data, hasAdditionalAddressFields)),
    onError: () => setAddresses([]),
  })

  useEffect(() => {
    if (rawAddresses.length > 0) {
      fetchBatchAddresses()
    }
  }, [rawAddresses.length])

  return addresses
}
