import {
  AccountCheckedIcon,
  AccountIcon,
  AccountNoNeckIcon,
  AppleIcon,
  BackspaceIcon,
  Bell,
  BouncingDots,
  Calendar,
  CallStore,
  CarParkDelivery,
  CartIcon,
  Change,
  Chevron,
  CircleHalfEmptyRight,
  Clock,
  ConfirmationTick,
  Cross,
  DeliveryIcon,
  Disclaimer,
  DominosLogoIcon,
  EdenredIcon,
  ExclamationIcon,
  FacebookIcon,
  GeoIcon,
  GiftCardPartialPaymentIcon,
  GMOAuMobileIcon,
  GMODocomoIcon,
  GMOLinPayIcon,
  GMORakutenIcon,
  GMOSoftbankIcon,
  GoogleIcon,
  HeaderLogoIcon,
  HouseIcon,
  InfoIcon,
  InfoIconSolid,
  ListIcon,
  LoadingOval,
  LogoutIcon,
  MagnifyingGlassIcon,
  MapArrowIcon,
  MapArrowIconSolid,
  MapArrowIconSolidGrey,
  MapIcon,
  MenuIcon,
  MinusIcon,
  MobileMPKIcon,
  MyOrderIcon,
  NewAddressIcon,
  NoLocationIcon,
  OffersIcon,
  OrderFailedIcon,
  OutsideDeliveryAreaIcon,
  OutsideStore,
  PaymentErrorIcon,
  PaypalIcon,
  PickUpIcon,
  PinIcon,
  PlusIcon,
  PortionEmptyBottomLeft,
  PortionEmptyBottomRight,
  PortionEmptyLeft,
  PortionEmptyRight,
  PortionEmptyTopLeft,
  PortionEmptyTopRight,
  QuestionIcon,
  RecentIcon,
  RecentIconLight,
  RewardsIcon,
  RewardsNavIcon,
  RobotDeliveryIcon,
  SavedIcon,
  SearchResultIcon,
  SelectionTick,
  SessionExpiredIcon,
  StoreDetailsDelivery,
  StoreDetailsPickUp,
  TerminalIcon,
  TouchIcon,
  TrackerChefIcon,
  TrackerCookingIcon,
  TrackerReceiptIcon,
  TrackerScooterIcon,
  TrackerStarIcon,
  TrackerStoreIcon,
  TrashCanIcon,
  UpgradeIcon,
  VouchersIcon,
  XMark,
} from './components'
import { CrossCloseDarkIcon } from './components/cross-close-dark'
import { GlobeIcon } from './components/globe-icon'
import {
  AppOnlyIcon,
  BundleIcon,
  BurgerIcon,
  CalzoneIcon,
  ChickenIcon,
  ChickenPiecesIcon,
  CondimentsIcon,
  DessertIcon,
  DrinksAndDessertsIcon,
  DrinksIcon,
  EntreesIcon,
  ForOneIcon,
  PastaIcon,
  PizzaIcon,
  PizzaRollsIcon,
  PizzaSandwichIcon,
  PromoIcon,
  SaladAndSoupIcon,
  SaladIcon,
  SandwichIcon,
  SetMenuIcon,
  ShakeIcon,
  SideIcon,
  SidesAndDessertsIcon,
  SignatureIcon,
  SnacksIcon,
  SnacksSaladIcon,
  TileIcon,
  VeganIcon,
} from './components/menu-category'

export const svgIcons = {
  account: AccountIcon,
  accountNoNeck: AccountNoNeckIcon,
  accountChecked: AccountCheckedIcon,
  appleLogo: AppleIcon,
  bell: Bell,
  bouncingDots: BouncingDots,
  calendar: Calendar,
  carParkDelivery: CarParkDelivery,
  callStore: CallStore,
  cart: CartIcon,
  change: Change,
  chevron: Chevron,
  clock: Clock,
  circleHalfEmptyRight: CircleHalfEmptyRight,
  confirmationTick: ConfirmationTick,
  cross: Cross,
  crossCloseDark: CrossCloseDarkIcon,
  headerLogo: HeaderLogoIcon,
  delivery: DeliveryIcon,
  disclaimer: Disclaimer,
  dominosLogoIcon: DominosLogoIcon,
  edenred: EdenredIcon,
  exclamation: ExclamationIcon,
  facebook: FacebookIcon,
  geoIcon: GeoIcon,
  globe: GlobeIcon,
  giftCardPartialPaymentIcon: GiftCardPartialPaymentIcon,
  gmoAuMobile: GMOAuMobileIcon,
  gmoDocomo: GMODocomoIcon,
  gmoRakuten: GMORakutenIcon,
  gmoLinePay: GMOLinPayIcon,
  gmoSoftbank: GMOSoftbankIcon,
  google: GoogleIcon,
  infoIcon: InfoIcon,
  infoIconSolid: InfoIconSolid,
  menu: MenuIcon,
  minus: MinusIcon,
  newAddressIcon: NewAddressIcon,
  offers: OffersIcon,
  order: MyOrderIcon,
  outsideStore: OutsideStore,
  orderFailed: OrderFailedIcon,
  paymentError: PaymentErrorIcon,
  paypal: PaypalIcon,
  pickUp: PickUpIcon,
  plus: PlusIcon,
  question: QuestionIcon,
  recentIcon: RecentIcon,
  recentIconLight: RecentIconLight,
  rewardsIcon: RewardsIcon,
  rewardsNavIcon: RewardsNavIcon,
  trashCan: TrashCanIcon,
  savedIcon: SavedIcon,
  searchResultIcon: SearchResultIcon,
  sessionExpiredIcon: SessionExpiredIcon,
  storeDetailsDelivery: StoreDetailsDelivery,
  storeDetailsPickUp: StoreDetailsPickUp,
  terminal: TerminalIcon,
  touch: TouchIcon,
  trackerChef: TrackerChefIcon,
  trackerCooking: TrackerCookingIcon,
  trackerReceipt: TrackerReceiptIcon,
  trackerScooter: TrackerScooterIcon,
  trackerStar: TrackerStarIcon,
  trackerStore: TrackerStoreIcon,
  halfEmptyLeft: PortionEmptyLeft,
  halfEmptyRight: PortionEmptyRight,
  portionEmptyIcon_0_2: PortionEmptyLeft,
  portionEmptyIcon_1_2: PortionEmptyRight,
  portionEmptyIcon_0_4: PortionEmptyTopLeft,
  portionEmptyIcon_1_4: PortionEmptyTopRight,
  portionEmptyIcon_2_4: PortionEmptyBottomLeft,
  portionEmptyIcon_3_4: PortionEmptyBottomRight,
  vouchers: VouchersIcon,
  magnifyingGlassIcon: MagnifyingGlassIcon,
  loadingOval: LoadingOval,
  logout: LogoutIcon,
  upgradeIcon: UpgradeIcon,
  house: HouseIcon,
  robotDelivery: RobotDeliveryIcon,
  backspaceIcon: BackspaceIcon,
  outsideDeliveryAreaIcon: OutsideDeliveryAreaIcon,
  noLocationIcon: NoLocationIcon,
  xMark: XMark,
  pinIcon: PinIcon,
  mobileMPKIcon: MobileMPKIcon,
  listIcon: ListIcon,
  mapIcon: MapIcon,
  mapArrowIcon: MapArrowIcon,
  mapArrowIconSolid: MapArrowIconSolid,
  mapArrowIconSolidGrey: MapArrowIconSolidGrey,
  selectionTick: SelectionTick,
}

export type svgIconsName = keyof typeof svgIcons

export const categoryIcons = {
  'Menu.Pizza': PizzaIcon,
  'Menu.Side': SideIcon,
  'Menu.Drink': DrinksIcon,
  'Menu.Drinks': DrinksIcon,
  'Menu.Dessert': DessertIcon,
  'Menu.Pasta': PastaIcon,
  'Menu.DrinksDesserts': DrinksAndDessertsIcon,
  'Menu.ForOne': ForOneIcon,
  'Menu.Subs': ForOneIcon,
  'Menu.SetMenus': SetMenuIcon,
  'Menu.Entrees': EntreesIcon,
  'Menu.Default': TileIcon,
  'Menu.Chicken': ChickenIcon,
  'Menu.SidesDesserts': SidesAndDessertsIcon,
  'Menu.Bundles': BundleIcon,
  'Menu.PizzaSandwiches': PizzaSandwichIcon,
  'Menu.Snacks': SnacksIcon,
  'Menu.AppOnly': AppOnlyIcon,
  'Menu.Promo': PromoIcon,
  'Menu.SnacksSalads': SnacksSaladIcon,
  'Menu.ChickenPieces': ChickenPiecesIcon,
  'Menu.Salad': SaladIcon,
  'Menu.PizzaRolls': PizzaRollsIcon,
  'Menu.Calz': CalzoneIcon,
  'Menu.Signatures': SignatureIcon,
  'Menu.Sandwiches': SandwichIcon,
  'Menu.Shakes': ShakeIcon,
  'Menu.SaladSoups': SaladAndSoupIcon,
  'Menu.Vegan': VeganIcon,
  'Menu.BestSeller': SetMenuIcon,
  'Menu.Burger': BurgerIcon,
  'Menu.Condiment': CondimentsIcon,
  'Menu.OnlineDeals': PromoIcon,
}

export type categoryIconsName = keyof typeof categoryIcons

export const pngIcons = {
  pickUp: { image: require('../../assets/pngs/Pickup.png') },
  delivery: { image: require('../../assets/pngs/Delivery.png') },
  headerLogo: { image: require('../../assets/pngs/Header_logo_black.png') },
  vouchers: { image: require('../../assets/pngs/Vouchers.png') },
  order: { image: require('../../assets/pngs/My Order.png') },
  menu: { image: require('../../assets/pngs/Menu.png') },
  account: { image: require('../../assets/pngs/Account.png') },
}

export type pngIconsName = keyof typeof pngIcons
