import { isNativeApp } from '@dominos/business/functions/native-app/is-native-app'
import {
  EnhancedEcommercePurchaseEvent,
  useFeatures,
  usePurchaseEvent,
  useSessionStorage,
} from '@dominos/hooks-and-hocs'
import { MyDetailsModelProps } from 'packages/interfaces'

export type nativeAppStoredPaymentIds = { orderId: string; orderPaymentId: string }
export type nativeAppStoredCustomerDetails = { customerDetails?: MyDetailsModelProps }

export const useNativeAppStoredData = () => {
  const {
    storedValue: nativeAppStoredCustomerDetails,
    setValue: setNativeAppStoredCustomerDetails,
    clearValue: clearNativeAppStoredCustomerDetails,
  } = useSessionStorage<nativeAppStoredCustomerDetails>({ key: 'native-app-stored-customer-details' })
  const {
    storedValue: nativeAppStoredPaymentIds,
    setValue: setNativeAppStoredPaymentIds,
    clearValue: clearNativeAppStoredPaymentIds,
  } = useSessionStorage<nativeAppStoredPaymentIds>({ key: 'native-app-stored-payment-ids' })

  const {
    storedValue: nativeAppStoredPurchaseEvent,
    setValue: setNativeAppStoredPurchaseEvent,
    clearValue: clearNativeAppStoredPurchaseEvent,
  } = useSessionStorage<EnhancedEcommercePurchaseEvent | undefined>({ key: 'native-app-stored-purchase-event' })

  const eventDetails = usePurchaseEvent()
  const { featureEnabled } = useFeatures()
  const [storeCustomerDetailsInSessionStorage, storePurchaseEventInSessionStorage] = featureEnabled(
    'NativeAppStoreCustomerDetailsInSessionStorage',
    'NativeAppStorePurchaseEventInSessionStorage',
  )

  const retrieveNativeAppCustomerDetails = (): nativeAppStoredCustomerDetails => nativeAppStoredCustomerDetails ?? {}

  const storeNativeAppCustomerDetails = (customerDetails: MyDetailsModelProps) => {
    if (storeCustomerDetailsInSessionStorage) {
      setNativeAppStoredCustomerDetails({ customerDetails })
    }
  }

  const removeNativeAppCustomerDetails = () => {
    if (nativeAppStoredCustomerDetails) {
      clearNativeAppStoredCustomerDetails()
    }
  }

  const retrieveNativeAppPaymentIds = (): nativeAppStoredPaymentIds =>
    nativeAppStoredPaymentIds ?? { orderId: '', orderPaymentId: '' }

  const storeNativeAppPaymentIds = (orderId: string, orderPaymentId: string) =>
    setNativeAppStoredPaymentIds({ orderId, orderPaymentId })

  const retrieveNativeAppPurchaseEvent = (): EnhancedEcommercePurchaseEvent | undefined =>
    nativeAppStoredPurchaseEvent ?? undefined

  const storeNativeAppPurchaseEvent = () => {
    if (storePurchaseEventInSessionStorage) {
      setNativeAppStoredPurchaseEvent(eventDetails)
    }
  }

  const removeNativeAppPurchaseEventAndPaymentIds = () => {
    if (nativeAppStoredPaymentIds) {
      clearNativeAppStoredPaymentIds()
    }
    if (nativeAppStoredPurchaseEvent) {
      clearNativeAppStoredPurchaseEvent()
    }
  }

  if (!isNativeApp()) {
    return {
      retrieveNativeAppCustomerDetails: (): nativeAppStoredCustomerDetails => ({}),
      storeNativeAppCustomerDetails: (customerDetails: MyDetailsModelProps) => {},
      removeNativeAppCustomerDetails: () => {},
      retrieveNativeAppPaymentIds: (): nativeAppStoredPaymentIds => ({ orderId: '', orderPaymentId: '' }),
      storeNativeAppPaymentIds: (orderId: string, paymentOrderId: string) => {},
      retrieveNativeAppPurchaseEvent: (): EnhancedEcommercePurchaseEvent | undefined => undefined,
      storeNativeAppPurchaseEvent: () => {},
      removeNativeAppPurchaseEventAndPaymentIds: () => {},
    }
  }

  return {
    retrieveNativeAppCustomerDetails,
    storeNativeAppCustomerDetails,
    removeNativeAppCustomerDetails,
    retrieveNativeAppPaymentIds,
    storeNativeAppPaymentIds,
    retrieveNativeAppPurchaseEvent,
    storeNativeAppPurchaseEvent,
    removeNativeAppPurchaseEventAndPaymentIds,
  }
}
