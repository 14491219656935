import { countFromBasketLines } from '@dominos/business/functions/basket'
import { CollapsableTitledCard, OrderAdjustments, OrderButton, OrderSubtotal, OrderTaxes } from '@dominos/components'
import { useCurrentStore, useDominosTheme, useFeatures, useKiosk } from '@dominos/hooks-and-hocs'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BasketLine } from '../../basket-line'
import { CouponLine } from '../../coupon-line'
import { DetailRow } from '../../detail-row'
import Surcharges from '../../surcharges/surcharges'
import { AboutPricing } from './about-pricing'
import { OrderDetails } from '../../order-details'
import css from './order-details-card.less'
import { LoyaltyCompletionTrack } from '../../../loyalty/loyaltyTwo/LoyaltyCompletionTrack'
import { OrderDetailedSummary } from './order-detailed-summary'

export interface OrderDetailsCardProps {
  testID: string
  orderData?: Partial<BasketData>
  basketError?: Error | string | null
  onPlaceOrder: () => void
  removeLine?: (lineItem: BasketCoupon | BasketLineItem) => void
  editLine?: BasketLineProps['onEdit']
  hideOrderButton?: boolean
  hideEditButton?: boolean
  hideSwapButton?: boolean
  hideRemoveButton?: boolean
  loading?: boolean
}

const OrderDetailsCard = ({
  testID,
  orderData,
  basketError,
  onPlaceOrder,
  removeLine,
  editLine,
  hideOrderButton = false,
  hideEditButton = false,
  hideSwapButton = false,
  hideRemoveButton = false,
  loading,
}: OrderDetailsCardProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('menu')
  const { featureEnabled } = useFeatures()
  const [
    showMinimumOrderValueEnabled,
    showVoucherNotBestValueErrorMessage,
    showAboutOurPricingMessage,
    showOrderDetailsPill,
    showOrderTaxes,
    basketSummaryDetailedPricing,
  ] = featureEnabled(
    'ShowMinimumOrderValue',
    'ShowVoucherNotBestValueErrorMessage',
    'ShowAboutOurPricingMessage',
    'ShowOrderDetailsPill',
    'ShowOrderTaxes',
    'BasketSummaryDetailedPricing',
  )
  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)
  const currentStore = useCurrentStore()
  const { isKioskOrder } = useKiosk()
  const itemCount = useMemo(() => countFromBasketLines(basketData.lines), [basketData.lines])
  const enableOrderButton = itemCount > 0 && !basketError

  const taxes = basketData.taxes || []
  const adjustments = basketData.adjustments || []

  const shouldShowOrderTaxDetails = !!basketData.priceExcludingTaxes && !!taxes.length && showOrderTaxes

  return (
    <CollapsableTitledCard
      primaryTitle={t('Order Details')}
      startCollapsed={false}
      noPadding={true}
      testID={`${testID}.order-details-card`}
    >
      <div className={css.orderDetails}>
        {!showOrderDetailsPill && !isKioskOrder && (
          <OrderDetails
            testID={`${testID}.order-details`}
            currentOrderTime={orderData && orderData.time}
            selectedStore={currentStore}
          />
        )}
        {(basketData.lines.length > 0 &&
          basketData.lines.map((line: BasketLine | BasketCoupon, idx: number) => {
            if (line.type === 'BasketLine') {
              return (
                <BasketLine
                  testID={`${testID}.line`}
                  key={`${line.productCode}.${idx}`}
                  lineData={line}
                  onRemove={removeLine}
                  onEdit={editLine}
                  hideEditButton={hideEditButton}
                  hideRemoveButton={hideRemoveButton}
                  hideSwapButton
                />
              )
            }

            if (line.type === 'BasketCoupon') {
              return (
                <CouponLine
                  testID={`${testID}.coupon.${line.code}`}
                  key={`${line.code}.${idx}`}
                  couponLine={line}
                  onEditLine={editLine}
                  onRemoveLine={removeLine}
                  onRemove={removeLine}
                  hideEditButton={hideEditButton}
                  hideRemoveButton={hideRemoveButton}
                  hideSwapButton={hideSwapButton}
                  showVoucherNotBestValueErrorMessage={showVoucherNotBestValueErrorMessage}
                />
              )
            }

            return null
          })) || (
          <div data-testid={`${testID}.prompt`} className={css.prompt}>
            {t('add some items prompt', { defaultValue: 'Add some items from the menu to get started!' })}
          </div>
        )}
        <LoyaltyCompletionTrack />
        {basketSummaryDetailedPricing ? (
          <OrderDetailedSummary testID={`${testID}.orderDetailedSummary`} basketData={basketData} />
        ) : (
          <div style={{ paddingLeft: '18px', paddingRight: '18px' }}>
            <Surcharges testID={`${testID}.surcharges`} surcharges={basketData.surcharges} />
            {shouldShowOrderTaxDetails && (
              <OrderSubtotal amount={basketData.priceExcludingTaxes!} testId={`${testID}.subtotal`} />
            )}
            {shouldShowOrderTaxDetails && <OrderTaxes taxes={taxes} testId={`${testID}.taxes`} />}
            {!!adjustments.length && <OrderAdjustments adjustments={adjustments} testId={`${testID}.adjustments`} />}
            <DetailRow testID={`${testID}.total-value`} label={t('TOTAL')} price={basketData.total} />

            {basketData.totalDiscount > 0 && (
              <DetailRow
                testID={`${testID}.discount-value`}
                label={t('YOU SAVED')}
                price={basketData.totalDiscount}
                textStyle={{ color: theme.colours.actionStandard }}
              />
            )}
            {showMinimumOrderValueEnabled && basketData.minimumPrice !== undefined && basketData.minimumPrice > 0 && (
              <DetailRow
                testID={`${testID}.minimum-order-value`}
                label={t('Minimum order amount')}
                price={basketData.minimumPrice}
                fontWeight='normal'
                textStyle={{ fontSize: '13px' }}
              />
            )}
          </div>
        )}
        {showAboutOurPricingMessage && <AboutPricing testID={testID} />}
        {!hideOrderButton ? (
          <div className={css.stickyButton}>
            <OrderButton
              testID={`${testID}.place-order`}
              itemCount={itemCount}
              price={basketData.total || 0}
              onPress={onPlaceOrder}
              disabled={!enableOrderButton}
              text={t('Place Order')}
              fill
              loading={loading}
            />
          </div>
        ) : (
          <div style={{ paddingBottom: 0 }} />
        )}
      </div>
    </CollapsableTitledCard>
  )
}

export { OrderDetailsCard }
