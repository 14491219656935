import React, { useRef } from 'react'
import css from './product-card-description.less'
import classNames from 'classnames'
import { CollapsableTitledCard } from '@dominos/components/cards/collapsable-titled-card'

interface ProductCardDescriptionProps {
  media: Bff.Products.ProductMedia | undefined
  testID: string
}

export const ProductCardDescription = ({ media, testID }: ProductCardDescriptionProps) => {
  const ref = useRef<HTMLParagraphElement>(null)

  return (
    <section className={css.productCard}>
      <CollapsableTitledCard
        primaryTitle={media?.name.value ?? ''}
        startCollapsed={false}
        noPadding={false}
        static
        testID={'product-details-card'}
      >
        <div ref={ref} data-testid={`${testID}.description`} className={classNames(css.description)}>
          {media?.description?.value ?? ''}
        </div>
      </CollapsableTitledCard>
    </section>
  )
}
