import { LoyaltyEnroll } from '@dominos/components/loyalty'
import { useAuthentication, useFeatures } from '@dominos/hooks-and-hocs'
import React from 'react'
import { SignupPrompt } from './signup-prompt'

export const Loyalty = ({
  enrolled,
  receiveUpdatesAndOffers,
  onCheck,
  onCheckReceiveUpdates,
}: {
  enrolled: boolean
  receiveUpdatesAndOffers: boolean
  onCheck: (x: boolean) => void
  onCheckReceiveUpdates: (x: boolean) => void
}) => {
  const { featureEnabled } = useFeatures()
  const [loyaltyEnabled, isLoyaltyMarketingEnabled] = featureEnabled('loyalty-backend', 'LoyaltyMarketing')
  const { isUserLoggedIn } = useAuthentication()

  if (!loyaltyEnabled) {
    return null
  }

  if (isUserLoggedIn === false) {
    return <SignupPrompt />
  }

  if (isUserLoggedIn) {
    return (
      <LoyaltyEnroll
        enrolled={enrolled}
        onCheck={onCheck}
        receiveUpdatesEnabled={receiveUpdatesAndOffers}
        onCheckReceiveUpdates={onCheckReceiveUpdates}
        showLoyaltyMarketing={isLoyaltyMarketingEnabled}
      />
    )
  }

  return null
}
