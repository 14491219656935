import { ApplicationContext } from '@dominos/hooks-and-hocs/application/application-context'
import _ from 'lodash'
import { useContext, useMemo, useState } from 'react'
import { FeatureContext, FeatureType, getDefault } from './feature-context'

export const useFeatures = () => {
  const features = useContext(FeatureContext)
  const applications = useContext(ApplicationContext)
  const [blendedFeatures, setBlendedFeatures] = useState<FeatureType[]>(getDefault(applications.countryCode || 'AU'))

  useMemo(() => {
    setBlendedFeatures({ ...getDefault(applications.countryCode || 'AU'), ...features.features })
  }, [applications.countryCode, features.features])

  const featureEnabled = (...feats: AllowedBooleanFeatures[]) =>
    feats.map((feature: AllowedBooleanFeatures) => getBooleanVariation(feature))

  const getBooleanVariation = (featureName: AllowedBooleanFeatures) =>
    getBooleanFromFeatures(featureName, blendedFeatures)

  const getStringVariation = (featureName: AllowedStringFeatures) => getStringFromFeatures(featureName, blendedFeatures)

  const getNumberVariation = (featureName: AllowedNumberFeatures) => getNumberFromFeatures(featureName, blendedFeatures)

  const getJSONVariation = <T>(featureName: AllowedJSONFeatures) => getJSONFromFeatures<T>(featureName, blendedFeatures)

  return {
    featureEnabled,
    getBooleanVariation,
    getNumberVariation,
    getStringVariation,
    getJSONVariation,
    blendedFeatures,
    updateFeatures: features.update,
  }
}

export const getNumberFromFeatures = (featureName: AllowedNumberFeatures, features: FeatureType[]) =>
  getFeatures(featureName, features)

export const getStringFromFeatures = (featureName: AllowedStringFeatures, features: FeatureType[]) =>
  getFeatures(featureName, features)

export const getBooleanFromFeatures = (featureName: AllowedBooleanFeatures, features: FeatureType[]) =>
  getFeatures(featureName, features) === 'true'

export const getJSONFromFeatures = <T>(featureName: AllowedJSONFeatures, features: FeatureType[]) =>
  JSON.parse(getFeatures(featureName, features)) as T

const getFeatures = (featureName: string, blendedFeatures: FeatureType[] = []) => {
  const feature = _.find(blendedFeatures, (blendedFeature) => blendedFeature.key === featureName)

  return feature ? feature.value : ''
}
