import React from 'react'
import { BoxedMessage } from '@dominos/components'
import { PortionProductCardBannerProps } from './portion-product-card-banner.interface'

const styles = {
  marginBottom: '8px',
}
export const PortionProductCardBanner = ({ testID, viewModel }: PortionProductCardBannerProps) =>
  !viewModel.bannerText ? null : (
    <div style={styles}>
      <BoxedMessage
        testID={`${testID}.portion-product-card-banner-message-box`}
        variant='contained'
        color='information'
        icon='exclamation'
        textSize={viewModel.isKioskOrder ? 'large' : undefined}
      >
        {viewModel.bannerText}
      </BoxedMessage>
    </div>
  )
