import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCustomer, useFeatures } from '@dominos/hooks-and-hocs'
import { HeroBanner } from '../hero-banner'

import css from './home-advert-spot.less'

export const HomeAdvertSpot = ({ testID }: { testID: string }) => {
  const { t } = useTranslation('home')
  const { featureEnabled } = useFeatures()
  const [customersEnabled] = featureEnabled('customers')
  const { customer, isLoggedIn } = useCustomer()

  const welcomeText = customersEnabled && isLoggedIn ? t('WELCOME BACK') : t('WELCOME')

  return (
    <div className={css.container} data-testid={`${testID}.wrapper`}>
      <HeroBanner testID={`${testID}.hero-image`} />
      <div className={css.textContainer}>
        <div data-testid={`${testID}.message`}>
          <p data-testid={`${testID}.welcome`} className={css.textWelcome}>
            {welcomeText}
          </p>
          <hr className={css.horizontalRule} />
          {customer ? (
            <p data-testid={`${testID}.name`} className={css.textName}>
              {customer.name}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  )
}
