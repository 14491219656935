import { getCustomerId, idTokenUtils } from '@dominos/business/functions/customer'
import { useKeepTokenAlive, useLogout } from '@dominos/hooks-and-hocs/customer'
import { useFeatures } from '@dominos/hooks-and-hocs/features'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs/selectors'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import { useCallback, useEffect, useState } from 'react'
import { IdleTimer } from './idle-timer'
import { PollingTimer } from './polling-timer'
import { CUSTOMER_IDLE_TIMEOUT, CUSTOMER_POLLING_INTERVAL } from './session-config'

export const useIdleTimer = () => {
  const { logout } = useLogout()
  const customerId = getCustomerId()
  const { isTokenLongLived } = idTokenUtils()
  const { startPolling, stopPolling } = useKeepTokenAlive(customerId)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const { featureEnabled } = useFeatures()
  const [isCustomerTokenRotationEnabled] = featureEnabled('CustomerAuthTokenRotation')
  const { orderId } = useCurrentOrderDetails()

  const togglePopup = () => {
    setShowPopup((state) => !state)
  }

  const handleStartPolling = () => {
    startPolling(CUSTOMER_POLLING_INTERVAL)
  }

  const handleInactivityTimeout = useCallback(() => {
    if (customerId) {
      logout({
        variables: {
          orderId,
        },
      }).then(() => {
        navigate(NavigationConstants.home)
        stopPolling()
        togglePopup()
      })
    }
  }, [customerId])

  useEffect(() => {
    if (isCustomerTokenRotationEnabled && customerId && !isTokenLongLived()) {
      window.__idle_timer__ = new IdleTimer(handleInactivityTimeout, CUSTOMER_IDLE_TIMEOUT)
      window.__polling_timer__ = new PollingTimer(handleStartPolling, stopPolling)
    }
  }, [customerId])

  return { showPopup, togglePopup }
}
