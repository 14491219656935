/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef, useState } from 'react'
import { AppleNative } from '../apple-button'
import { FacebookNative } from '../facebook-button'
import { GoogleNative } from '../google-button'

import css from '../auth-button-group.less'
import { useCurrentOrderDetails, useFeatures, useReport, useSocialAuth } from '@dominos/hooks-and-hocs'
import { ErrorInline, ErrorScope, useErrorContext } from '@dominos/components/error'

import { useLoginTranslations } from '../use-login-translations'
import { loginErrors } from '../login-errors'
import { NativeUniversalAuthProps } from './native-auth-button-group.interface'
import { EmailNativeButton } from '../email-button'
import { AuthMessageHelper, AuthResponse } from './auth-message-helper'
import { mapToCreateAccountProps } from './create-account-props'
import { getAppleSpecificLoggingPayload } from '../helper'

type ProviderType = BffContext.SocialLogins.Providers
const SocialProviders = ['Facebook', 'Google', 'Apple']

export const NativeAuthenticationButtonGroup = ({ testID, onRegister, onEmail }: NativeUniversalAuthProps) => {
  const [loginFailed, setLoginFailed] = useState(false)
  const { featureEnabled } = useFeatures()
  const [appleAuthEnabled, fbAuthEnabled, googleAuthEnabled] = featureEnabled(
    'AppleAuthNativeApp',
    'FacebookAuthNativeApp',
    'GoogleAuthNativeApp',
  )

  const userRef = useRef<AuthResponse['user']>()
  const currentProviderRef = useRef<ProviderType>()

  const t = useLoginTranslations()
  const otherLoginTexts = t.getOthers()

  const { reportLogin, reportRegistration } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const { notifyError } = useErrorContext()

  const { signIn, pending, authCustomerInfo, error } = useSocialAuth()

  const socialHandler = (provider: ProviderType) => () => {
    currentProviderRef.current = provider
    AuthMessageHelper.postAuthRequest(provider)
  }
  const isLoading = (provider: ProviderType) => currentProviderRef.current === provider && pending

  const getTranslationsByProvider = (provider: ProviderType) => {
    const [title, subtitle] = t.getSocials(provider)

    return {
      title,
      subtitle,
      description: otherLoginTexts.SignUpSocialDescription,
    }
  }

  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      if (!currentProviderRef.current || !SocialProviders.includes(currentProviderRef.current)) {
        return
      }

      const authResp = AuthMessageHelper.parseAuthResp(message)

      if (authResp?.user) {
        userRef.current = authResp.user
      }
      // only listen to messages upon social auth finished from native
      if (authResp) {
        signIn({
          orderId,
          provider: authResp.provider,
          providerToken: authResp.providerToken,
          enableLongLived: true,
        })
      } else {
        reportLogin({
          enableLongLived: true,
          status: 'fail',
          identityProvider: currentProviderRef.current!,
          order_id: orderId,
          authenticationSource: 'UniversalAuth',
          customerId: authCustomerInfo?.customerId,
          url: location.href,
          status_reason: 'Social: Failed to parse AuthResponse from native sdk',
        })
      }
    }

    window.addEventListener('message', handleMessage)

    return () => window.removeEventListener('message', handleMessage)
  }, [])

  useEffect(() => {
    if (authCustomerInfo) {
      // by the time this effect triggered, curProvider state can never be null
      // as we need to click any of the social buttons to get here
      if (authCustomerInfo.customerId) {
        reportLogin({
          enableLongLived: true,
          identityProvider: currentProviderRef.current!,
          status: 'success',
          order_id: orderId,
          authenticationSource: 'UniversalAuth',
          customerId: authCustomerInfo?.customerId,
          url: location.href,
        })
        AuthMessageHelper.postIdToken(authCustomerInfo.idToken)
      } else {
        reportRegistration({
          success: false,
          orderId,
          identityProvider: currentProviderRef.current!,
          note: 'Creating new account',
          authenticationSource: 'UniversalAuth',
          url: location.href,
          payload: getAppleSpecificLoggingPayload(currentProviderRef.current!, authCustomerInfo, userRef.current),
        })

        onRegister(
          mapToCreateAccountProps(
            currentProviderRef.current!,
            authCustomerInfo,
            userRef.current,
            getTranslationsByProvider,
          ),
        )
      }
    }
  }, [authCustomerInfo])

  useEffect(() => {
    if (error) {
      reportLogin({
        enableLongLived: true,
        status: 'fail',
        order_id: orderId,
        authenticationSource: 'UniversalAuth',
        customerId: authCustomerInfo?.customerId,
        url: location.href,
        identityProvider: currentProviderRef.current!,
        status_reason: error.message,
      })

      notifyError({
        error,
        definitions: loginErrors,
        handlers: {},
        scope: ErrorScope.CreateAccount,
      })

      setLoginFailed(true)
    }
  }, [error])

  return (
    <div data-testid={testID}>
      <div className={css.group}>
        <EmailNativeButton testID={`${testID}.email-native-signin`} onPress={onEmail} />
        {appleAuthEnabled && (
          <AppleNative
            testID={`${testID}.apple-native-signin`}
            loading={isLoading('Apple')}
            onSignIn={socialHandler('Apple')}
          />
        )}
        {googleAuthEnabled && (
          <GoogleNative
            testID={`${testID}.google-native-signin`}
            loading={isLoading('Google')}
            onSignIn={socialHandler('Google')}
          />
        )}
        {fbAuthEnabled && (
          <FacebookNative
            testID={`${testID}.facebook-native-signin`}
            loading={isLoading('Facebook')}
            onSignIn={socialHandler('Facebook')}
          />
        )}
      </div>
      {loginFailed && (
        <ErrorInline testID={`${testID}.error`} message={otherLoginTexts.CreateAccountGenericErrorText} />
      )}
    </div>
  )
}
