import { getProductRulesLogic } from '@dominos/business/functions/menu'
import { TFunction } from 'i18next'

/**
 * Validates the product ingredient quantity and fires
 * alerts when constraints violated
 * @param product Product menu item
 * @param quantity Total quantity of ingredients
 * @param previousQuantity Previous quantity of ingredients
 * @param t Translations for the menu scene
 * @returns Validation determiner
 */

export const validateProductWithChanges = (
  product: ProductMenuItem,
  quantity: number,
  previousQuantity: number,
  currents: IngredientItemProps[],
  t: TFunction,
  report?: (message: string, translationKey?: string, source?: string, severity?: string) => void,
) => {
  const rulesLogic = getProductRulesLogic(product, quantity, previousQuantity, currents, t)

  return !rulesLogic.some((r) => {
    const result = r.expression() && window.alert(`${r.title} \n\n${r.message}`)

    if (result !== false && report) {
      report(r.message)
    }

    return result !== false
  })
}
