import { rootActions } from '@dominos/business'
import { useFeatures, useFos, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate, useLocation } from '@reach/router'
import { FosEventInfoType } from 'olo-feature-fos'
import { FosInteractionEventType } from 'olo-feature-fos'
import { useDispatch } from 'react-redux'

export const useRestartOrder = ({
  report = true,
  fos = true,
  updateFeatureToggles = false,
}: { report?: boolean; fos?: boolean; updateFeatureToggles?: boolean } = {}) => {
  const { reportOrderReset } = useReport()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { sendFosEvent } = useFos()
  const { updateFeatures } = useFeatures()

  return () => {
    dispatch(rootActions.restartOrder())
    if (report) reportOrderReset()
    if (fos)
      sendFosEvent({
        type: FosEventInfoType.InteractionEvent,
        locationPath: pathname,
        eventType: FosInteractionEventType.OrderRestarted,
      })
    if (updateFeatureToggles) updateFeatures()
    navigate(NavigationConstants.home)
  }
}
