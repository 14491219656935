import React from 'react'
import {
  useCountDown,
  UseCountDownProps,
  useKioskStartInactivityTimer,
  useTimeout,
  UseTimeoutProps,
} from '@dominos/hooks-and-hocs'
import { KioskOrderPopup } from '@dominos/components/kiosk/kiosk-order-popup'
import { useTranslation } from 'react-i18next'
import { POPUP_SECONDS } from '@dominos/components/kiosk/kiosk-inactivity-config'
import { useRestartOrder } from '@dominos/components/footer/simple-footer/use-restart-order'

export const KioskInactivityDetector = ({ children }: React.PropsWithChildren<{}>) => {
  const resetOrder = useRestartOrder({ report: false, fos: false, updateFeatureToggles: true })
  const { t } = useTranslation('kiosk')
  const shouldStartTimer = useKioskStartInactivityTimer()

  const useCountDownProps: UseCountDownProps = {
    seconds: POPUP_SECONDS,
    onCountDownEnd: resetOrder,
    startCountDown: false,
  }
  const {
    secondsLeft: popupSecondsLeft,
    startCountDown: shouldShowResetPopup,
    kickOffCountDown,
    endCountDown,
  } = useCountDown(useCountDownProps)

  const useTimeoutParams: UseTimeoutProps = {
    onTimeout: kickOffCountDown,
    startTimeout: shouldStartTimer,
  }
  const { setupTimeout } = useTimeout(useTimeoutParams)

  const handlePopupClose = (callback: () => void) => () => {
    endCountDown()
    callback && callback()
  }

  return (
    <>
      {shouldShowResetPopup && (
        <KioskOrderPopup
          testId='kiosk-inactivity-detector-popup'
          popupTitle={''}
          popupMessage={t('KioskPopupSessionExpireMessage', {
            defaultValue: 'Your session is about to expire. <br />Do you want to continue?',
          })}
          onConfirm={handlePopupClose(setupTimeout)}
          onConfirmText={t('Continue', { defaultValue: 'Continue' })}
          onDismiss={handlePopupClose(resetOrder)}
          onDismissText={`${t('Reset', { defaultValue: 'Reset' })} (${popupSecondsLeft})`}
          icon='order'
        />
      )}
      {children}
    </>
  )
}
