import React from 'react'

import { getCountryConfig } from '@dominos/business/functions/common'

import auFeatures from './static-data/au.json'
import beFeatures from './static-data/be.json'
import deFeatures from './static-data/de.json'
import dkFeatures from './static-data/dk.json'
import frFeatures from './static-data/fr.json'
import jpFeatures from './static-data/jp.json'
import luFeatures from './static-data/lu.json'
import myFeatures from './static-data/my.json'
import nlFeatures from './static-data/nl.json'
import nzFeatures from './static-data/nz.json'
import sgFeatures from './static-data/sg.json'

export const getDefault = (country: BffContext.Countries = getCountryConfig().COUNTRY) => {
  switch (country) {
    case 'BE':
      return beFeatures.data.featureToggles
    case 'DE':
      return deFeatures.data.featureToggles
    case 'DK':
      return dkFeatures.data.featureToggles
    case 'FR':
      return frFeatures.data.featureToggles
    case 'JP':
      return jpFeatures.data.featureToggles
    case 'LU':
      return luFeatures.data.featureToggles
    case 'MY':
      return myFeatures.data.featureToggles
    case 'NL':
      return nlFeatures.data.featureToggles
    case 'NZ':
      return nzFeatures.data.featureToggles
    case 'SG':
      return sgFeatures.data.featureToggles
    default:
      return auFeatures.data.featureToggles
  }
}

export const defaultFeatures = getDefault()

export type FeatureType = typeof defaultFeatures[number]

export interface FeatureToggleProps {
  features: FeatureType[]
  loading: boolean
  update: () => void
}

export interface FeatureResponse {
  featureToggles: FeatureType[]
}

const featureToggleModel: FeatureToggleProps = {
  features: defaultFeatures,
  loading: true,
  update: () => {},
}

export const FeatureContext = React.createContext<FeatureToggleProps>(featureToggleModel)
