import { useOneTrust } from '@dominos/hooks-and-hocs/one-trust'
import {
  useAllowedLastOrderNavigationPath,
  useStartUserJourneyOnMenuEnabled,
} from '@dominos/hooks-and-hocs/last-order-handler'
import { useLocation } from '@reach/router'
import { convertQueryStringToObject } from '@dominos/business/functions/text'
import {
  ConsentNotGiven,
  DeeplinkNotSupported,
  FeatureNotEnabled,
  NavigationPathNotSupported,
  SJMFailedReason,
} from '@dominos/hooks-and-hocs/logging'

export const useAllowedToUseLastOrder = (): {
  isAllowedToUseLastOrder: boolean | undefined
  unsuccessfulReason: SJMFailedReason | undefined
} => {
  const consents = useOneTrust()
  const startUserJourneyOnMenuEnabled = useStartUserJourneyOnMenuEnabled()

  const { allowedLastOrderNavigationPath } = useAllowedLastOrderNavigationPath()
  const { search } = useLocation()

  const { storeNo } = convertQueryStringToObject(search) ?? { storeNo: '' }

  const isAllowedToUseLastOrder =
    startUserJourneyOnMenuEnabled === undefined
      ? startUserJourneyOnMenuEnabled
      : consents.Functional && !!startUserJourneyOnMenuEnabled && !storeNo && allowedLastOrderNavigationPath

  let unsuccessfulReason: SJMFailedReason | undefined
  if (isAllowedToUseLastOrder === false) {
    unsuccessfulReason =
      (!allowedLastOrderNavigationPath && NavigationPathNotSupported) ||
      (!consents.Functional && ConsentNotGiven) ||
      (!startUserJourneyOnMenuEnabled && FeatureNotEnabled) ||
      DeeplinkNotSupported
  }

  return {
    isAllowedToUseLastOrder,
    unsuccessfulReason,
  }
}
