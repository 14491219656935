import { TFunction } from 'react-i18next'

export type CreateProductBuildYourOwnViewProps = {
  viewModel: ProductBuildYourOwnViewModel
  testID: string
  onClick: () => void
}

type CreateProductBuildYourOwnViewModelProps = {
  t: TFunction<'menu'>
  color: string
}

type ProductBuildYourOwnViewModel = {
  buildYourOwnTitle: string
  createHalfHalfText: string
  createQuattroText: string
  color: string
}

export const createProductBuildYourOwnViewModel = ({
  t,
  color,
}: CreateProductBuildYourOwnViewModelProps): ProductBuildYourOwnViewModel => {
  const buildYourOwnTitle = t('ProductCard.BuildYourOwn')
  const createHalfHalfText = t('ProductCard.CreateHalfHalf')
  const createQuattroText = t('ProductCard.CreateQuattro')

  return { buildYourOwnTitle, createHalfHalfText, createQuattroText, color }
}
