import gql from 'graphql-tag'

export const availableStorePaymentMethodsQuery = gql`
  query availableStorePaymentMethodsQuery(
    $storeNo: Int!
    $serviceMethod: ServiceMethodEnum
    $serviceMethodSubType: ServiceMethodSubTypeEnum
    $orderTime: String
    $withDonationEnabled: Boolean = false
  ) {
    availablePayments(
      storeNo: $storeNo
      serviceMethod: $serviceMethod
      serviceMethodSubType: $serviceMethodSubType
      orderTime: $orderTime
    ) {
      accountId
      providerCode
      paymentMethod
      savedPayment
      pickupEnabled
      deliveryEnabled
      donationEnabled @include(if: $withDonationEnabled)
      surchargeValue
      surchargeType
      tipTheDriverEnabled
      completeSplitPaymentEnabled
      properties {
        key
        value
      }
    }
  }
`
