import { rootActions } from '@dominos/business'
import { countFromBasketLines } from '@dominos/business/functions/basket'
import { InlineSingleItemOffer, OrderButton } from '@dominos/components'
import {
  useBasket,
  useBasketValidation,
  useCurrentOrderDetails,
  useFeatures,
  useNativeAppStoredData,
  useTermsAndConditionsInitialState,
} from '@dominos/hooks-and-hocs'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CustomerOrderDetails } from './details-container'

import { FormValue } from './details-container.interface'
import styles from './details-container.less'
import { mapEClub } from './helpers'
import { PickUpType } from './service-method-type'
import { TermsAndConditions } from './terms-and-conditions'

interface PickUpDetailsFormProps {
  customerOrderDetails: CustomerOrderDetails
  isMyDetailsValid: boolean
  eClub: { [key: string]: boolean }
  testID: string
  onComplete: () => void
}

const PickUpDetailsForm = ({
  customerOrderDetails,
  isMyDetailsValid,
  eClub,
  testID,
  onComplete,
}: PickUpDetailsFormProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('checkout')
  const { featureEnabled } = useFeatures()
  const [checkoutOffersEnabled] = featureEnabled('CheckoutMyDetailsOffers')
  const { basket: basketData, error: basketError, pending: basketLoading } = useBasket()
  const { basketHeaderData: currentOrderData } = useCurrentOrderDetails()
  const { isBasketValid } = useBasketValidation(t)
  const { storeNativeAppCustomerDetails } = useNativeAppStoredData()

  const [serviceMethodSubType, setServiceMethodSubType] = useState<Bff.Stores.ServiceMethodSubTypes | undefined>(
    currentOrderData?.serviceMethodSubType ?? undefined,
  )
  const [carDetails, setCarDetails] = useState<{ [key: string]: FormValue }>({
    carMakeAndModel: currentOrderData?.carMakeAndModel,
    carColour: currentOrderData?.carColour,
  })

  const [isCarDetailsValid, setIsCarDetailsValid] = useState<boolean>(true)

  const [termsValid, handleTermsAndConditionsChange] = useTermsAndConditionsInitialState()

  const itemCount = useMemo(() => countFromBasketLines(basketData.lines), [basketData.lines])

  const shouldOrderButtonDisabled =
    !!basketError || !basketData || !termsValid || !isMyDetailsValid || !isCarDetailsValid

  const handleCarDetailsChange = (field: string | null, value: FormValue) => {
    setCarDetails((prev) => ({ ...prev, [field!]: value }))
  }

  const placeOrder = () => {
    dispatch(rootActions.selectServiceMethodSubType(serviceMethodSubType))

    if (serviceMethodSubType === 'CarParkDelivery') {
      dispatch(rootActions.updateCarDetails(carDetails))
    }

    const customerDetails = {
      ...customerOrderDetails,
      eclub: mapEClub(eClub),
    }
    dispatch(rootActions.saveCustomerDetails(customerDetails))
    storeNativeAppCustomerDetails(customerDetails)

    onComplete()
  }

  const onPlaceOrderPress = () => {
    if (isBasketValid()) {
      placeOrder()
    }
  }

  return (
    <div>
      {checkoutOffersEnabled && (
        <InlineSingleItemOffer
          testID={`${testID}.checkout-offers`}
          filterLocation={{
            locationType: 'Page',
            locationCode: 'PaymentSelection',
            showBanner: true,
          }}
        />
      )}

      {currentOrderData?.storeNo && currentOrderData?.time !== undefined && (
        <PickUpType
          checkedOption={serviceMethodSubType}
          onChecked={setServiceMethodSubType}
          onFormValidationChange={setIsCarDetailsValid}
          {...(serviceMethodSubType === 'CarParkDelivery'
            ? { onChange: handleCarDetailsChange, initialValue: carDetails }
            : {})}
        />
      )}

      <TermsAndConditions
        testID={`${testID}.terms-and-conditions`}
        onTermsChange={handleTermsAndConditionsChange}
        isValid={termsValid}
      />

      <div className={styles.orderButtonContainer}>
        <OrderButton
          testID={`${testID}.place-order.button`}
          itemCount={itemCount}
          price={basketData.total || 0}
          onPress={onPlaceOrderPress}
          disabled={shouldOrderButtonDisabled}
          text={t('Place Order')}
          fill
          loading={basketLoading}
        />
      </div>
    </div>
  )
}

export { PickUpDetailsForm }
