import React, { useEffect, useState } from 'react'

import { NOT_SELECTED_OPTION_CODE } from '@dominos/business/functions/menu'
import { CollapsableTitledCard, TouchableGrid } from '@dominos/components'

export const CustomisationCard = <T extends TouchableGridElement>(props: CustomisationCardPropsWeb<T>) => {
  const {
    mapMenuItemToItemData,
    item,
    primaryTitle,
    numColumns,
    onItemSelected,
    basketLineEdit,
    testID,
    sizeCode,
    startExpanded = false,
  } = props

  const { elements, selected, defaultItem } = mapMenuItemToItemData(item, sizeCode)
  const [selectedState, setSelectedState] = useState<T | undefined>(selected)
  const [toggleExpanded, setToggleExpanded] = useState<boolean>(startExpanded)

  useEffect(() => {
    if (selected?.code !== selectedState?.code) {
      setSelectedState(selected)
    }
  }, [item, sizeCode])

  if (basketLineEdit) {
    let selectedCode: string | null
    if (isBasketLineChange(basketLineEdit)) {
      if (basketLineEdit.action === 'Remove') {
        selectedCode = NOT_SELECTED_OPTION_CODE
      } else {
        selectedCode = basketLineEdit.code
      }
    } else {
      selectedCode = basketLineEdit.add
    }

    const foundElement = elements.find((el) => el.code === selectedCode)
    const editedSelection = foundElement ?? defaultItem

    if (!!editedSelection && editedSelection.code !== (selectedState && selectedState.code)) {
      setSelectedState(editedSelection)

      // The item wasn't found in the elements so we need to default it.
      if (!foundElement && onItemSelected) {
        onItemSelected(editedSelection)
      }
    }
  }

  const toggleExpandedHandler = (expanded: boolean) => {
    setToggleExpanded(expanded)
  }

  const onPressItemHandler = (product: T) => {
    setToggleExpanded(!toggleExpanded)
    setSelectedState(product)
    if (onItemSelected) {
      onItemSelected(product)
    }
  }

  const renderItem = (product: T) => <props.renderItem {...product} />

  return (
    <>
      {elements.length ? (
        <CollapsableTitledCard
          testID={`customisation.${testID}`}
          noPadding={true}
          startCollapsed={!toggleExpanded}
          primaryTitle={primaryTitle}
          secondaryTitle={selectedState && selectedState.name}
          onToggleExpanded={toggleExpandedHandler}
        >
          <TouchableGrid
            testID={`customisation.touchable.${testID}`}
            data={elements}
            selectedItem={(selectedState && selectedState.code) || ''}
            numColumns={numColumns}
            renderItem={renderItem}
            onPressItem={onPressItemHandler}
          />
        </CollapsableTitledCard>
      ) : null}
    </>
  )
}

const isBasketLineChange = (basketLineEdit: BasketLineSwap | BasketLineChange): basketLineEdit is BasketLineChange =>
  (basketLineEdit as BasketLineChange).action !== undefined
