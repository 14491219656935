import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import { useLazyQuery } from '@apollo/client'

import { rootActions } from '@dominos/business'
import { individualStoreQuery, individualStoreQueryV2 } from '@dominos/business/queries'
import { useFeatures, useOneTrust, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'

interface IndividualStoreResponse {
  store: Bff.Stores.Store
  orderingTimes: StoreOrderTimes
}

interface IndividualStoreResponseV2 {
  store: Bff.Stores.Store
  orderingTimeSlots: StoreOrderTimeSlots
}

export interface HandleStoreDeepLinkHandlerProps {
  storeNo: string
}

export const useStoreDeepLinkHandler = () => {
  const { featureEnabled } = useFeatures()
  const { reportOrderTime } = useReport()
  const dispatch = useDispatch()
  const consents = useOneTrust()
  const [individualStore, { data: individualStoreQueryData, error }] = useLazyQuery<IndividualStoreResponse>(
    individualStoreQuery,
    {
      fetchPolicy: 'no-cache',
      onError: () => {},
    },
  )
  const [individualStoreV2, { data: individualStoreQueryDataV2, error: errorV2 }] =
    useLazyQuery<IndividualStoreResponseV2>(individualStoreQueryV2, {
      fetchPolicy: 'no-cache',
      onError: () => {},
    })

  const [timedOrderCapQueryEnabled] = featureEnabled('TimedOrderCapQuery')
  const { store } = timedOrderCapQueryEnabled ? individualStoreQueryDataV2 || {} : individualStoreQueryData || {}
  const isAsapAvailable = timedOrderCapQueryEnabled
    ? individualStoreQueryDataV2?.orderingTimeSlots.currentStoreStatus === 'Available' ||
      individualStoreQueryDataV2?.orderingTimeSlots.currentStoreStatus === 'ServiceMethodDiverted'
    : individualStoreQueryData?.orderingTimes.isAsapAvailable

  const handle = ({ storeNo }: HandleStoreDeepLinkHandlerProps) => {
    if (timedOrderCapQueryEnabled) {
      individualStoreV2({
        variables: {
          storeNo: parseInt(storeNo),
          serviceMethod: 'Pickup',
          orderTime: null,
        },
      })

      return
    }

    individualStore({
      variables: {
        storeNo: parseInt(storeNo),
        serviceMethod: 'Pickup',
        orderTime: null,
      },
    })
  }

  useEffect(() => {
    if ((timedOrderCapQueryEnabled ? individualStoreQueryDataV2 : individualStoreQueryData) && store) {
      dispatch(rootActions.storeSelected(store, consents.Functional))

      if (store.serviceMethods.pickup) {
        dispatch(rootActions.selectServiceMethod('Pickup'))
      } else {
        navigate(NavigationConstants.home)

        return
      }

      if (isAsapAvailable) {
        dispatch(rootActions.selectOrderTime({ orderTime: null }))
        reportOrderTime('ASAP')
        navigate(NavigationConstants.menu, {
          state: { time: null },
        })
      } else {
        navigate(NavigationConstants.timedOrder)

        return
      }
    }
  }, [store, individualStoreQueryDataV2, individualStoreQueryData])

  return {
    handle,
    error: errorV2 || error,
  }
}
