import { createFosEventsHandler } from '@dominos/business/functions/fos'
import { useSecurity } from '@dominos/hooks-and-hocs/logging/security'
import { useSelector } from 'react-redux'

export const useFos = () => {
  const rootReducer = useSelector((state: RootReducer) => state)
  const { sendSecurity } = useSecurity()

  const { sendFosEvents } = createFosEventsHandler(rootReducer, sendSecurity)

  return { sendFosEvent: sendFosEvents }
}
