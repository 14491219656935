import React, { useEffect } from 'react'
import css from './auth-callback-container.less'
import { navigate, useLocation } from '@reach/router'
import { NavigationConstants } from '@dominos/navigation'
import {
  useCurrentOrderDetails,
  useForgeRock,
  useReport,
  useSessionStorage,
  useSetLoggedInUser,
} from '@dominos/hooks-and-hocs'
import { sendTokenToNativeApp } from '@dominos/business/functions/native-app'
import { ReportLoginData } from '@dominos/hooks-and-hocs/logging'
import { idTokenUtils } from '@dominos/business/functions/customer/id-token-utils'
import { SplashScreen } from '@dominos/components/splash-screen'

type ReportingStatusType = ReportLoginData['status']

const authenticationSource = 'ForgeRock'

export const AuthCallbackContainer = () => {
  const { setLoggedInUser } = useSetLoggedInUser()
  const { orderId } = useCurrentOrderDetails()
  const { reportLogin } = useReport()
  const { getForgeRockAuthToken, logoutForgeRock, getForgeRockTokenFromStorage, forceFetchForgeRockToken } =
    useForgeRock()
  const { decodeToken, deleteToken } = idTokenUtils()
  const { storedValue: previousPage, clearValue } = useSessionStorage({ key: 'forgerock-callback-path' })
  const { search, href } = useLocation()

  useEffect(() => {
    const getForgeRockToken = async () => {
      const params = new URLSearchParams(search)

      const [code, state, error, accountDeleted] = ['code', 'state', 'error', 'account_deleted'].map((key) =>
        params.get(key),
      )

      if (error) {
        deleteToken()
      }

      if (accountDeleted) {
        handleDeleteAccountCallback()

        return
      }

      if (!code || !state) {
        return navigateToPreviousPage()
      }

      const oAuth2Tokens = await getForgeRockAuthToken(code, state)
      const forgeRockStorageStage = await getForgeRockTokenFromStorage()

      if (!oAuth2Tokens && !forgeRockStorageStage) {
        // When forgot password journey continues on a new tab it doesn't return valid token
        // In that case we need to fetch token again
        // https://backstage.forgerock.com/support/tickets?id=104732
        await forceFetchForgeRockToken()

        return
      }

      if (!oAuth2Tokens) {
        reportData('fail', {
          status_reason: 'Failed to get ForgeRock auth token',
          customerId: undefined,
          payload: { code, state },
        })

        return navigateToPreviousPage()
      }
      const tokenPayload = decodeToken(oAuth2Tokens.accessToken)
      if (!tokenPayload) {
        reportData('fail', {
          status_reason: 'Failed to decode ForgeRock auth token',
          customerId: undefined,
          payload: { accessToken: oAuth2Tokens.accessToken },
        })

        return navigateToPreviousPage()
      }
      const loggedInUserSuccess = await setLoggedInUser(oAuth2Tokens.accessToken, tokenPayload.customerId)

      let status: ReportingStatusType = 'success'
      if (!loggedInUserSuccess) {
        logoutForgeRock()
        status = 'fail'
      }

      reportData(status, {
        customerId: tokenPayload.customerId,
      })
      sendTokenToNativeApp(oAuth2Tokens.accessToken)

      navigateToPreviousPage()
    }
    getForgeRockToken()
  }, [])

  const navigateToPreviousPage = () => {
    navigate(previousPage ?? NavigationConstants.home)
    clearValue()
  }

  const handleDeleteAccountCallback = () => {
    deleteToken()
    logoutForgeRock()
    navigate(NavigationConstants.home)
  }

  const reportData = (
    status: ReportingStatusType,
    data: Pick<ReportLoginData, 'customerId' | 'status_reason' | 'payload'>,
  ) => {
    const logPayload: ReportLoginData = {
      status: status,
      identityProvider: 'ForgeRock',
      authenticationSource,
      customerId: data.customerId,
      url: href,
      order_id: orderId,
      status_error: data.status_reason,
      payload: data.payload,
    }
    reportLogin(logPayload)
  }

  return (
    <div className={css.header} data-testid='auth-callback-container'>
      <SplashScreen testID='auth-callback-container-loading-splash' />
    </div>
  )
}
