import { useFeatures } from '@dominos/hooks-and-hocs/features'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EnergyKJUnitDivider } from '../energy-kj-unit-divider'

interface EnergyKJUnitProps extends BaseProps {
  kj?: number | null | ''
  divider?: string
  showEnergyUnitDivider?: boolean
}

export const EnergyKJUnit = ({ testID, kj, divider, showEnergyUnitDivider = true }: EnergyKJUnitProps) => {
  const { getBooleanVariation } = useFeatures()
  const kjEnabled = getBooleanVariation('ProductKJ')

  const { t } = useTranslation('menu')

  return kj && kjEnabled ? (
    <div>
      {showEnergyUnitDivider && <EnergyKJUnitDivider testID={`${testID}.divider`} divider={divider} />}
      <span data-testid={testID}>
        {`${kj}${t('energyKJ-unit', {
          defaultValue: 'kJ',
        })}^`}
      </span>
    </div>
  ) : null
}
