import { useEffect, useMemo, useState } from 'react'
import { LayoutItem } from 'simple-keyboard-layouts/build/interfaces'
import Japanese from 'simple-keyboard-layouts/build/layouts/japanese'
import French from 'simple-keyboard-layouts/build/layouts/french'
import English from 'simple-keyboard-layouts/build/layouts/english'
import { Language, useLanguages } from '@dominos/hooks-and-hocs/languages'

interface LayoutMapping {
  default: LayoutItem
  [key: string]: LayoutItem
}

interface LayoutItemWithName {
  layoutName: string
  layoutItem: LayoutItem
}

export interface KeyboardLayout extends LayoutItemWithName {
  language: Language
  display: { [button: string]: string }
}

const VirtualKeyboardLayoutMapping: PartialRecord<BffContext.Languages, LayoutMapping> = {
  ja: { default: Japanese },
  fr: { default: French },
  en: { default: English },
}

const baseDisplay = {
  '{close}': ' ',
  '{shift}': 'Shift',
  '{lock}': 'Caps',
  '{bksp}': 'Backspace',
  '{enter}': 'Enter',
  '{tab}': 'Tab',
  '{space}': ' ',
}

const getLayoutDisplayName = (language: Language, layoutName: string) => {
  if (layoutName !== 'default') {
    // Add custom lay out display name
  }

  return language.value.split('-')[0].toUpperCase()
}

export const useVirtualKeyboardLayout = () => {
  const { language, languages } = useLanguages()
  const [selectedLayout, setSelectedLayout] = useState<KeyboardLayout>()

  const keyboardLayouts: KeyboardLayout[] = useMemo(() => {
    const hasMultiLayouts =
      languages
        .map((lang) => lang.value.split('-')[0] as BffContext.Languages)
        .map((code) => Object.keys(VirtualKeyboardLayoutMapping[code] ?? {}).length)
        .reduce((prev, curr) => prev + curr, 0) > 1

    return languages
      .map((lang) =>
        getLayoutItems(lang, hasMultiLayouts).map((layoutItem) => ({
          ...layoutItem,
          language: lang,
          display: {
            ...baseDisplay,
            '{switchLanguage}': `<div class='switchIcon'></div><span>${getLayoutDisplayName(
              lang,
              layoutItem.layoutName,
            )}</span>`,
          },
        })),
      )
      .reduce((prev, curr) => [...prev, ...curr], [])
  }, [])

  const switchLayout = (index: number) => {
    setSelectedLayout(keyboardLayouts[index])
  }

  useEffect(() => {
    const selected = keyboardLayouts.findIndex(
      (layout) => layout.language.value.split('-')[0] === language && layout.layoutName === 'default',
    )
    if (selected === -1 && keyboardLayouts.length > 0) {
      switchLayout(0)
    } else {
      switchLayout(selected)
    }
  }, [])

  return { keyboardLayouts, selectedLayout, switchLayout }
}

const getLayoutItems = (language: Language, hasMultiLayouts: boolean): LayoutItemWithName[] => {
  const layoutMapping = VirtualKeyboardLayoutMapping[language.value.split('-')[0] as BffContext.Languages]

  return !layoutMapping ? [] : mapLayoutItems(layoutMapping, hasMultiLayouts)
}

const mapLayoutItems = (layoutMapping: LayoutMapping, hasMultiLayouts: boolean): LayoutItemWithName[] =>
  Object.entries(layoutMapping).map(([name, item]) => {
    const mappedLayoutItem: LayoutItemWithName = { layoutName: name, layoutItem: { layout: {} } }

    Object.keys(item.layout).forEach((key) => {
      const layoutLength = item.layout[key].length
      if (layoutLength > 0) {
        mappedLayoutItem.layoutItem.layout[key] = [...item.layout[key]]
        mappedLayoutItem.layoutItem.layout[key][layoutLength - 1] +=
          (hasMultiLayouts ? ' {switchLanguage}' : '') + ' {close}'
      }
    })

    return mappedLayoutItem
  })
