import { useFeatures } from '@dominos/hooks-and-hocs'

export interface FeatureToggles {
  paypalEnabled: boolean
  gmoEnabled: boolean
  applePayEnabled: boolean
  googlePayEnabled: boolean
  giftCardEnabled: boolean
  edenredEnabled: boolean
  deliveryGuaranteeEnabled: boolean
  splitPaymentsEnabled: boolean
  cashChangeRequiredEnabled: boolean
  paypalDirectEnabled: boolean
  isB2BOrderEnabled: boolean
  adyenClickToPayEnabled: boolean
  afterpayEnabled: boolean
  grabPayEnabled: boolean
  fpxEnabled: boolean
}

const usePaymentFeatureToggles = (): FeatureToggles => {
  const { featureEnabled } = useFeatures()
  const [
    paypalEnabled,
    gmoEnabled,
    applePayEnabled,
    googlePayEnabled,
    giftCardEnabled,
    edenredEnabled,
    deliveryGuaranteeEnabled,
    splitPaymentsEnabled,
    cashChangeRequiredEnabled,
    paypalDirectEnabled,
    isB2BOrderEnabled,
    adyenClickToPayEnabled,
    afterpayEnabled,
    grabPayEnabled,
    fpxEnabled,
  ] = featureEnabled(
    'paypal-enabled',
    'gmo-enabled',
    'apple-pay-enabled',
    'google-pay-enabled',
    'gift-card-enabled',
    'edenred-enabled',
    'delivery-guarantee',
    'SplitPayments',
    'CashChangeRequired',
    'PayPalDirectEnabled',
    'IsB2BOrderEnabled',
    'AdyenClickToPay',
    'AfterpayEnabled',
    'GrabPayEnabled',
    'FPXEnabled',
  )

  return {
    paypalEnabled,
    gmoEnabled,
    applePayEnabled,
    googlePayEnabled,
    giftCardEnabled,
    edenredEnabled,
    deliveryGuaranteeEnabled,
    splitPaymentsEnabled,
    cashChangeRequiredEnabled,
    paypalDirectEnabled,
    isB2BOrderEnabled,
    adyenClickToPayEnabled,
    afterpayEnabled,
    grabPayEnabled,
    fpxEnabled,
  }
}

export default usePaymentFeatureToggles
