import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'
import { Observable, Subscriber } from 'rxjs'
import { apolloClient } from '../apollo'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const graphQLQuery = <U>(query: any, config: GraphConfig, securitySender: SecuritySender) =>
  new Observable((observer: Subscriber<U>) => {
    apolloClient(config, securitySender)
      .query<U>({ query, fetchPolicy: 'no-cache', variables: config.variables })
      .then((out) => {
        out && out.data ? observer.next(out.data) : observer.error(out.errors)
      })
      .catch((err) => {
        observer.error(err)
      })
  })
