import { IUseAddressFieldsContainerProps, useAddressFieldsContainer } from 'olo-feature-address'
import React from 'react'
import { AddressField } from '../address-field'
import { GenericSelect } from '@dominos/components/select/generic-select/generic-select'

const SELECT_HEIGHT = 44
export type AddressFieldsContainerProp = IUseAddressFieldsContainerProps

export const AddressFieldsContainer = (props: AddressFieldsContainerProp) => {
  const { addressFields, fieldsControlDropDown } = useAddressFieldsContainer(props)

  if (!addressFields) return <></>

  const genericItems = fieldsControlDropDown?.dropDownItems.map((item) => ({ value: item.value, label: item.label }))

  const style = getStyles()

  return (
    <div style={style.addressFieldsContainer}>
      {fieldsControlDropDown && (
        <div style={style.formControlDropDown}>
          <GenericSelect
            testID={`AddressFieldsContainer.FieldsControlDropDown`}
            options={genericItems}
            placeholder={fieldsControlDropDown.dropDownItems[0].label}
            selectedValue={fieldsControlDropDown.selectedValue}
            height={SELECT_HEIGHT}
            onChange={fieldsControlDropDown.onChange}
          />
        </div>
      )}
      {addressFields.map((field, index) => (
        <AddressField
          key={index}
          addressAttributeName={field.addressAttributeName}
          onChange={field.onChange}
          initialValue={field.initialValue}
          fieldInputHandling={field.fieldInputHandling}
          fieldValidationRules={field.fieldValidationRules}
          inputWidth={field.inputWidth}
        />
      ))}
    </div>
  )
}

interface Style {
  addressFieldsContainer: React.CSSProperties
  formControlDropDown: React.CSSProperties
}

const getStyles = (): Style => ({
  addressFieldsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '10px',
  },
  formControlDropDown: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 100%',
  },
})
