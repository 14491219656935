import React, { Fragment, useMemo } from 'react'

import {
  getIngredientWithCode,
  productChangesTransform,
  productIngredientsTransform,
  validateProductWithChanges,
} from '@dominos/business/functions/product'
import { CollapsableTitledCard, ProductIngredientItem } from '@dominos/components'
import { useBreakpoints, useReport } from '@dominos/hooks-and-hocs'
import { useFeatures } from '@dominos/hooks-and-hocs'
import { useTranslation } from 'react-i18next'
import css from './product-ingredient-card.less'

const ANIMATION_DELAY = 800
const LINE_HEIGHT_MOBILE = 41
const LINE_HEIGHT_DESKTOP = 71

export const ProductIngredientCard = (props: IngredientCollapsableCardProps) => {
  let timer: NodeJS.Timeout
  const { primaryTitle, item, onItemChange, toppingLineChange, onSectionChange } = props
  const { isMobile } = useBreakpoints()
  const { t } = useTranslation('menu')

  const { featureEnabled } = useFeatures()
  const LINE_HEIGHT = isMobile ? LINE_HEIGHT_MOBILE : LINE_HEIGHT_DESKTOP
  let currentToppingLineChange: BasketLineChange[] = [...toppingLineChange]
  const currentSize = useMemo(
    () =>
      item?.sizes?.find(({ code }) => code === props.currentSizeCode) ??
      item?.sizes?.find(({ code }) => code === item.defaultSize),
    [item, props.currentSizeCode],
  )

  const [isEditToppingExpandedByDefault] = featureEnabled('ExpandEditToppingsByDefault')
  const { currents, possibles } = useMemo<{ currents: IngredientItemProps[]; possibles: IngredientItemProps[] }>(
    () => productIngredientsTransform(currentToppingLineChange, currentSize),
    [onItemChange, props.currentSizeCode],
  )

  const { reportValidationMessage } = useReport()

  const didChangeQuantity = (quantity: number, ingredientCode: string, previousQuantity: number) => {
    // The possible toppings available for this pizza
    const product = { ...item }
    const swaps = currentSize?.swaps

    // Return window.alert() if min/max reached
    if (
      !item ||
      !swaps ||
      !swaps.toppings ||
      !swaps.toppings.ingredients ||
      // Return window.alert() if min/max reached
      !validateProductWithChanges(product, quantity, previousQuantity, currents, t, reportValidationMessage)
    ) {
      return
    }

    const { ingredient, isRecipeIngredient } = getIngredientWithCode(currentSize, ingredientCode)

    if (ingredient) {
      const toppings = productChangesTransform(product, quantity, ingredient, currentToppingLineChange)
      currentToppingLineChange = [...toppings]

      if (!isRecipeIngredient) {
        maybeWaitForUserRetraction(currentToppingLineChange, previousQuantity, quantity)
      } else if (onItemChange) {
        onItemChange(currentToppingLineChange)
      }
    }

    return true
  }

  const maybeWaitForUserRetraction = (toppings: BasketLineChange[], previousQuantity: number, quantity: number) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if (onSectionChange) {
        const { currents: newCurrents } = productIngredientsTransform(toppings, currentSize)
        const difference = newCurrents.length - currents.length
        onSectionChange(difference * LINE_HEIGHT)
      }
      if (onItemChange) {
        onItemChange(toppings)
      }
    }, getAnimationDelay(previousQuantity, quantity)) as unknown as NodeJS.Timeout
  }

  // sets the delay time if new topping being added or current topping being removed completely
  const getAnimationDelay = (previousQuantity: number, quantity: number) => {
    if (previousQuantity === 0 || (previousQuantity === 1 && quantity === 0)) {
      return ANIMATION_DELAY
    }

    return 0
  }

  const currentIngredients = currents.map((currentIngredient) => (
    <ProductIngredientItem
      {...currentIngredient}
      key={`current.ingredient.${currentIngredient.code}`}
      testID='current.ingredient'
      showPrice={false}
      showMinimumQuantity={true}
      onQuantityChange={didChangeQuantity}
    />
  ))

  const possibleIngredients = possibles.map((possibleIngredient) => (
    <ProductIngredientItem
      {...possibleIngredient}
      key={`possible.ingredient.${possibleIngredient.code}`}
      testID='possible.ingredient'
      onQuantityChange={didChangeQuantity}
    />
  ))

  return currents.length > 0 ? (
    <CollapsableTitledCard
      testID='product-ingredient-card'
      primaryTitle={primaryTitle}
      startCollapsed={!isEditToppingExpandedByDefault}
      noPadding={false}
    >
      <IngredientListSection key='currentIngredientSection' list={currentIngredients} />
      {possibles.length > 0 && currentSize?.swaps?.toppings?.rule.allowAdd !== false && (
        <IngredientListSection
          key='possibleIngredientSection'
          sectionHeader={t('AddIngredients', { defaultValue: 'Add Ingredients' })}
          list={possibleIngredients}
        />
      )}
    </CollapsableTitledCard>
  ) : null
}

const IngredientListSection = ({ sectionHeader, list }: { sectionHeader?: string; list: JSX.Element[] }) => (
  <Fragment>
    {!!sectionHeader && (
      <h3 data-testid='Ingredient-Grid-Item.Section-Header' key='header' className={css.sectionHeader}>
        {sectionHeader}
      </h3>
    )}
    {list}
  </Fragment>
)
