import React from 'react'
import { useDominosTheme } from '@dominos/hooks-and-hocs/'
import css from './product-ingredient-item.less'
import { LeftAdjustmentButtonWithPrice, RightAdjustmentButtonWithQuantity } from './partials'

export const ProductIngredientItem = React.memo(
  ({
    testID,
    title,
    thumb,
    code,
    quantity,
    value,
    showPrice = true,
    showMinimumQuantity,
    maximumReached,
    minimumReached,
    allowAdd,
    allowRemove,
    onQuantityChange,
  }: IngredientItemProps & BaseProps) => {
    const theme = useDominosTheme()

    const styles = {
      title: { color: theme.colours.dominosSlate },
    }

    const handleAdjustmentButtonClick = (adjustmentQuantity: number) => {
      if (onQuantityChange) {
        const adjustedQuantity = quantity + adjustmentQuantity
        onQuantityChange(adjustedQuantity, code, quantity)
      }
    }

    return (
      <div data-testid={`${testID}.container`} className={css.row}>
        <div className={css.wrapper}>
          {/* Highlighted Box */}
          <div className={css.moveableWrapper}>
            <div className={css.content}>
              {/* Thumbnail */}
              <div className={css.imageTitleGroup}>
                <img data-testid={`${testID}.thumb`} className={css.thumb} src={thumb} alt='' />
                <div style={styles.title} className={css.title}>
                  <p data-testid={`ingredient.title.${code}`}>{title.toUpperCase()}</p>
                </div>
              </div>
              <LeftAdjustmentButtonWithPrice
                testID={`${testID}.${code}`}
                maximumReached={maximumReached}
                minimumReached={minimumReached}
                value={value}
                showPrice={!!showPrice}
                showMinimumQuantity={!!showMinimumQuantity}
                onChange={handleAdjustmentButtonClick}
                isAddAllowed={allowAdd}
                isRemoveAllowed={allowRemove}
              />
            </div>
          </div>
          {(quantity > 0 || (!!showMinimumQuantity && quantity === 0)) && (
            <RightAdjustmentButtonWithQuantity
              testID={`${testID}.${code}`}
              quantity={quantity}
              isAddAllowed={allowAdd}
              maximumReached={maximumReached}
              onChange={handleAdjustmentButtonClick}
            />
          )}
        </div>
      </div>
    )
  },
)
