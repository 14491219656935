import { Operation } from '@apollo/client'
import { FeatureType } from '@dominos/hooks-and-hocs/features/feature-context'
import { getBooleanFromFeatures } from '@dominos/hooks-and-hocs/features/use-features'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'

export const AKAMAI_CACHE_STATUS_HEADER = 'akamai-cache-status'

const isOperationAllowed = (operationName: string, featureToggles?: FeatureType[]): boolean => {
  switch (operationName) {
    case 'swapSetProducts':
      return featureToggles != undefined && getBooleanFromFeatures('VoucherSoftPinningLogCacheMetrics', featureToggles)
    case 'menu':
      return featureToggles != undefined && getBooleanFromFeatures('MenuLogCacheMetrics', featureToggles)
    default:
      return false
  }
}

export const akamaiCacheStatusHandler = (
  headers: Headers,
  operation: Operation,
  securitySender: SecuritySender,
  featureToggles?: FeatureType[],
) => {
  const headerCacheStatus = headers.get(AKAMAI_CACHE_STATUS_HEADER)

  if (!headerCacheStatus || !isOperationAllowed(operation.operationName, featureToggles)) {
    return
  }

  const akamaiCacheStatuses = headerCacheStatus.split(',')

  securitySender('Akamai.Cache.Status', {
    header: AKAMAI_CACHE_STATUS_HEADER,
    message: headerCacheStatus,
    child: extractAkamaiCacheStatus(akamaiCacheStatuses, 'child'),
    parent: extractAkamaiCacheStatus(akamaiCacheStatuses, 'parent'),
    graphOperationName: operation.operationName,
    variables: operation.variables,
  })
}

/**
 * Value set by Akamai
 * ref: https://techdocs.akamai.com/property-mgr/docs/return-cache-status
 *
 * @param statuses format: {status} from {type}
 * @param type 'child' or 'parent'
 *
 * @returns string | undefined
 */
const extractAkamaiCacheStatus = (statuses: string[], type: 'child' | 'parent'): string | undefined =>
  statuses
    .find((status) => status.toLocaleLowerCase().trim().endsWith(`from ${type}`))
    ?.replace(`from ${type}`, '')
    .trim()
