import { CollapsableTitledCard } from '@dominos/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGPSTracker } from '../use-gps-tracker'
import { getGOLOPickupIframeUrl } from './get-golo-pickup-iframe-url'
import styles from './golo-pickup.less'
import { useFeatures } from '@dominos/hooks-and-hocs'
import { OrderStatus } from '@dominos/hooks-and-hocs/order/order-types'
import { getCountryConfig } from '@dominos/business/functions/common'

interface GOLOPickupProps {
  id?: string
  order: Bff.Orders.Order
  serviceMethod: string
}

const START_TRACKING_STATUSES: OrderStatus[] = [
  OrderStatus.Cooking,
  OrderStatus.Making,
  OrderStatus.Remaking,
  OrderStatus.Ready,
  OrderStatus.Complete,
]
const STOP_TRACKING_STATUS = OrderStatus.Complete

const GOLOPickup = ({ id, order, serviceMethod }: GOLOPickupProps) => {
  const { featureEnabled } = useFeatures()
  const [PanduAmbilPotongQTrackerEnabled] = featureEnabled('PanduAmbilPotongQTracker')
  const { PANDU_AMBIL_POTONG_Q_URL } = getCountryConfig()

  const { t } = useTranslation('tracker')
  const displayPickupIframe = PANDU_AMBIL_POTONG_Q_URL !== 'null'
  // TODO: rename the useGPSTracker hook as it has nothing to do with GPS tracker anymore. It is just a conditional
  // status display hook.
  const { startTrack } = useGPSTracker(displayPickupIframe, order.id, START_TRACKING_STATUSES, STOP_TRACKING_STATUS)

  const iframeUrl = getGOLOPickupIframeUrl(
    PANDU_AMBIL_POTONG_Q_URL,
    order.store?.storeNo!,
    order!.details!.header!.pulseId!,
    order.details!.header.orderTime!,
  )

  if (
    serviceMethod !== 'Pickup' ||
    !PanduAmbilPotongQTrackerEnabled ||
    !displayPickupIframe ||
    !iframeUrl ||
    !startTrack
  ) {
    return null
  }

  return (
    <CollapsableTitledCard
      testID={'golo-pickup'}
      primaryTitle={t('GoloPickupOptionsTrackerTitle', { defaultValue: 'Pick up options' })}
      noPadding
      style={{ marginBottom: '8px', overflow: 'hidden' }}
    >
      <div id={id} className={styles.iframeWrapper}>
        <iframe
          src={iframeUrl}
          data-testid='golo-pickup-iframe'
          style={{ width: '100%', height: '100%', borderWidth: '0' }}
        />
      </div>
    </CollapsableTitledCard>
  )
}

export { GOLOPickup }
