/* eslint-disable rxjs/no-internal */
import { graphQLMutation, graphQLQuery } from '@dominos/apollo'
import { rootActions, rootEpic, rootReducer } from '@dominos/business'
import React from 'react'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import { Persistor, persistReducer, persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { ajax } from 'rxjs/internal/observable/dom/ajax'
import { basePersistConfig } from './base-persistant-config'
import { useConfigContext, useVendorsContext } from '@dominos/hooks-and-hocs'

export let persistor: Persistor
let state: RootReducer

export const WithPersistentStore = ({ children }: { children: React.ReactElement }) => {
  const { applicationEnv, hasLocationPermission } = useConfigContext()
  const { sendSecurity } = useVendorsContext()

  const epicMiddleware = createEpicMiddleware({
    dependencies: { graphQLMutation, graphQLQuery, securitySender: sendSecurity, getJSON: ajax.getJSON },
  })
  const persistedReducer = persistReducer(basePersistConfig, rootReducer)
  const store = createStore(persistedReducer, {}, composeWithDevTools(applyMiddleware(epicMiddleware)))
  store.subscribe(() => {
    state = store.getState()
  })
  epicMiddleware.run(rootEpic)

  persistor = persistStore(store, undefined, () => {
    state = store.getState()
    store.dispatch(rootActions.applicationEnvironment(applicationEnv))
    store.dispatch(rootActions.applicationStarted({ hasLocationPermission }))
  })

  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>{children}</Provider>
    </PersistGate>
  )
}

export const getState = () => state
