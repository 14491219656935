import React, { ReactNode } from 'react'
import { useFeatures } from '@dominos/hooks-and-hocs'
import { HomeContainer } from '@dominos/components'
import { useIsAuthenticated } from '@dominos/components/login-b2b/AuthProviderB2B'

export const B2BAuthenticationHandler = ({ children }: { children: ReactNode }) => {
  const { featureEnabled } = useFeatures()

  const [IsB2BOrderEnabled] = featureEnabled('IsB2BOrderEnabled')

  const isLoggedIn = useIsAuthenticated()

  if (!IsB2BOrderEnabled || isLoggedIn) {
    return <>{children}</>
  }

  return <HomeContainer />
}
