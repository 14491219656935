/* eslint-disable max-lines-per-function */
import { isNativeApp } from '@dominos/business/functions'
import { sendTokenToNativeApp } from '@dominos/business/functions/native-app'
import { useValidation } from '@dominos/business/functions/validation'
import {
  ActionButton,
  ErrorScope,
  getErrorCodeFromApolloError,
  MDTargetBlankAnchor,
  useErrorContext,
  ValidationTextField,
} from '@dominos/components'
import {
  LoginCredentials,
  useAlert,
  useEmailLogin,
  useFeatures,
  useRecaptcha,
  useReport,
  useSetLoggedInUser,
} from '@dominos/hooks-and-hocs'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs/selectors/use-current-order-details'
import Markdown from 'markdown-to-jsx'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ForgottenPassword } from '../forgotten-password'
import css from './email-login.less'
import { loginErrors } from '../login-errors'
import KeepMeLoggedIn from './keep-me-logged-in'
import { ReportLoginData } from '@dominos/hooks-and-hocs/logging'
import { AuthenticationSource } from '@dominos/interfaces'

const fieldNames = {
  email: 'Email',
  password: 'Password',
} as { [key: string]: string }

interface EmailLoginFormFields {
  Email?: ValidationField
  Password?: ValidationField
}

interface Props {
  authenticationSource?: AuthenticationSource
  enableLongLived: boolean
  keepMeLoggedIn: boolean
  toggleKeepMeLoggedIn?: React.Dispatch<React.SetStateAction<boolean>>
  onReset?: () => void
  onSuccess?: () => void
}

const EmailLogin = ({
  authenticationSource = 'Legacy',
  enableLongLived,
  keepMeLoggedIn,
  toggleKeepMeLoggedIn,
  onReset,
  onSuccess,
}: Props) => {
  const [loginInput, setLoginInput] = useState<EmailLoginFormFields>()
  const [loginFailed, setLoginFailed] = useState<boolean>(false)
  const [customer, setCustomer] = useState<string>()
  const { isEmailValid, isRequired } = useValidation()
  const { t } = useTranslation('login')
  const { showAlert } = useAlert()
  const isFromNativeApp = isNativeApp()
  const { notifyError } = useErrorContext()

  const { setLoggedInUser } = useSetLoggedInUser()
  const { reportLogin } = useReport()
  const { executeV3, renderCheckboxV2, getV2Token, resetCheckboxV2 } = useRecaptcha()
  const { featureEnabled } = useFeatures()
  const [recaptchaEnabled, keepMeLoggedInEnabled] = featureEnabled('Recaptcha', 'KeepMeLoggedIn')

  const { orderId } = useCurrentOrderDetails()

  const recaptchaAction: BffContext.RecaptchaAction = 'Login'

  const callBackOnLoginSuccess = async (idToken: string, customerId: string) => {
    setCustomer(customerId)

    const loggedInUserSuccess = await setLoggedInUser(idToken, customerId)

    if (loggedInUserSuccess) {
      reportLogin({ ...logPayload, enableLongLived, status: 'success' })
      onSuccess?.()
    } else {
      reportLogin(logPayload)
      setLoginFailed(true)
    }

    sendTokenToNativeApp(idToken)
  }
  const { login, error, loading, useRecaptchaV2, shouldResetCheckbox } = useEmailLogin(callBackOnLoginSuccess)

  const logPayload: ReportLoginData = {
    status: 'fail',
    identityProvider: 'Email',
    authenticationSource,
    customerId: customer,
    url: location.href,
    order_id: orderId,
  }

  useEffect(() => {
    const errorCode = getErrorCodeFromApolloError(error)

    if (error && errorCode) {
      const errorMessages = error.graphQLErrors.map((err) => err.message).join('\n')
      reportLogin({ ...logPayload, status_reason: errorMessages })

      if (loginErrors[errorCode]) {
        notifyError({
          error,
          definitions: loginErrors,
          handlers: {},
          scope: ErrorScope.LoginAccount,
        })
      } else {
        setLoginFailed(true)
      }
    }
  }, [error])

  useEffect(() => {
    if (error?.networkError) {
      reportLogin({ ...logPayload, status_reason: error.networkError.message })
      showAlert(t('UnexpectedErrorOnEmailLogin'), 'UnexpectedErrorOnEmailLogin')
    }
  }, [error?.networkError])

  useEffect(() => {
    if (shouldResetCheckbox) {
      resetCheckboxV2()
    }
  }, [shouldResetCheckbox])

  const onChange = (updatedField: { [key: string]: ValidationField }) => {
    setLoginFailed(false)
    const key = Object.keys(updatedField)[0]

    const newValue = {
      ...loginInput,
      [key]: updatedField[key],
    }

    setLoginInput(newValue)
  }

  const updateKeepMeLoggedIn = () => {
    toggleKeepMeLoggedIn && toggleKeepMeLoggedIn((keep) => !keep)
  }

  const onLoginClicked = async () => {
    if (loginInput && loginInput.Email?.value && loginInput.Password?.value) {
      let loginCredentials: LoginCredentials = {
        orderId,
        enableLongLived,
        email: loginInput.Email.value,
        password: loginInput.Password.value,
      }

      if (recaptchaEnabled) {
        const token = useRecaptchaV2 ? getV2Token() : await executeV3(recaptchaAction)
        loginCredentials = {
          ...loginCredentials,
          recaptchaToken: token,
          useRecaptchaV3: !useRecaptchaV2,
          recaptchaAction,
        }
      }

      await login(loginCredentials)
    }
  }

  return (
    <form>
      <h2 className={css.subheading}>{t('EmailLogin', { defaultValue: 'Log in with email' })}</h2>
      <ValidationTextField
        testID={`${fieldNames.email}.field`}
        fieldName={fieldNames.email}
        placeholder={t('Email')}
        validationRules={[isRequired, isEmailValid]}
        onChange={onChange}
        type='email'
        style={{ width: '100%' }}
        autoCapitalize={'none'}
      />
      <ValidationTextField
        testID={`${fieldNames.password}.field`}
        fieldName={fieldNames.password}
        placeholder={t('Password')}
        validationRules={[isRequired]}
        onChange={onChange}
        style={{ width: '100%' }}
        secureTextEntry={true}
      />

      <ForgottenPassword onForgot={onReset} />

      {recaptchaEnabled && (
        <Markdown
          data-testid='RecaptchaPolicy.Text'
          className={css.recaptchaPolicy}
          options={{ overrides: { a: MDTargetBlankAnchor } }}
        >
          {t('recaptchaPolicy', {
            defaultValue:
              'This site is protected by reCAPTCHA and the Google [Privacy Policy](https://policies.google.com/privacy) and [Terms of Service](https://policies.google.com/terms) apply',
          })}
        </Markdown>
      )}

      <div id='recaptcha-container' />
      {useRecaptchaV2 && renderCheckboxV2(recaptchaAction, 'recaptcha-container')}
      {loginFailed && <p className={css.errorMessage}>{t('LoginFailedError')}</p>}

      {!isFromNativeApp && keepMeLoggedInEnabled && (
        <KeepMeLoggedIn keepMeLoggedIn={keepMeLoggedIn} updateKeepMeLoggedIn={updateKeepMeLoggedIn} />
      )}

      <ActionButton
        testID='login.button'
        onPress={onLoginClicked}
        text={t('LogInButton', { defaultValue: 'Log In' })}
        containerStyle={{ width: '100%', marginTop: '10px', height: 54 }}
        textFontWeight='semibold'
        loading={loading}
        disabled={!(loginInput && loginInput.Email?.isValid && loginInput.Password?.isValid) || loading}
      />
    </form>
  )
}

export { EmailLogin }
