import { ApolloError } from '@apollo/client'
import { Bff as BffErrors } from 'bff-errors'
import { isNativeApp } from '@dominos/business/functions/native-app'
import { OrderStatus, useFos, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import { AtLeastOneErrorHandlers } from '@dominos/components/error'
import { useDispatch } from 'react-redux'
import {
  orderAlreadyPlacedErrorCloseHandler,
  paymentErrorHandler,
  recoverablePaymentErrorHandler,
  unrecoverableErrorCloseHandler,
  unrecoverablePaymentErrorHandler,
} from '../payment-container'

export const useProcessingErrorHandlers = (orderId: string): AtLeastOneErrorHandlers => {
  const { reportGenericError, reportPaymentErrorMessage } = useReport()
  const { sendFosEvent } = useFos()
  const dispatch = useDispatch()

  const recoverableErrorNavigation = () => {
    isNativeApp()
      ? navigate(`${NavigationConstants.nativeAppCheckoutDetails}?basketId=${orderId}`)
      : navigate(NavigationConstants.checkoutPayment)
  }

  const graphErrorHandler = (error: ApolloError) => {
    switch (error.graphQLErrors[0]?.extensions?.code) {
      case BffErrors.Errors.ORDER_ALREADY_PLACED:
        return {
          handleErrorDisplayed: orderAlreadyPlacedErrorCloseHandler,
        }
      case BffErrors.Errors.UNABLE_TO_CONTACT_STORE:
      case BffErrors.Errors.UNABLE_TO_CONTACT_STORE_TO_PRICE:
      case BffErrors.Errors.UNABLE_TO_PLACE_AT_STORE:
      case BffErrors.Errors.UNABLE_TO_PRICE_AT_STORE:
        return {
          handleErrorDisplayed: unrecoverablePaymentErrorHandler(reportPaymentErrorMessage),
          handleErrorClosed: unrecoverableErrorCloseHandler(sendFosEvent, dispatch),
        }
      case BffErrors.Errors.PAYMENT_DETAILS_EXPIRED:
      case BffErrors.Errors.PAYMENT_NOT_APPROVED:
      case BffErrors.Errors.PAYMENT_DETAILS_INCORRECT:
      case BffErrors.Errors.PAYMENT_NOT_PROCESSED:
      case BffErrors.Errors.PAYMENT_TIMEOUT:
      case BffErrors.Errors.PAYMENT_VALIDATION:
      case BffErrors.Errors.PAYMENT_INSUFFICIENT:
      case BffErrors.Errors.UNABLE_TO_CONTACT_PAYMENT_PROVIDER:
      case BffErrors.Errors.PAYMENT_PROVIDER_UNKNOWN:
      case BffErrors.Errors.PAYMENT_SESSION_EXPIRED:
        return {
          handleErrorDisplayed: paymentErrorHandler(reportPaymentErrorMessage, sendFosEvent),
          handleErrorClosed: recoverableErrorNavigation,
        }
      default:
        return {
          handleErrorDisplayed: recoverablePaymentErrorHandler(reportPaymentErrorMessage),
          handleErrorClosed: recoverableErrorNavigation,
        }
    }
  }

  const statusErrorHandler = (status: OrderStatus) => {
    reportGenericError({ status })
    switch (status) {
      case OrderStatus.Failed:
      case OrderStatus.Cancelled:
        return {
          handleErrorDisplayed: unrecoverablePaymentErrorHandler(reportPaymentErrorMessage),
          handleErrorClosed: unrecoverableErrorCloseHandler(sendFosEvent, dispatch),
        }
      case OrderStatus.Unknown:
      case OrderStatus.PaymentFailed:
      default:
        return {
          handleErrorDisplayed: recoverablePaymentErrorHandler(reportPaymentErrorMessage),
          handleErrorClosed: recoverableErrorNavigation,
        }
    }
  }

  return {
    graphErrorHandler,
    statusErrorHandler,
  }
}
