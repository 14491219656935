import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getCountryConfig } from '@dominos/business/functions'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'
import fetch from 'cross-fetch'
import {
  afterwareResponseLink,
  akamaiHeadersLink,
  authLink,
  b2BErrorHandler,
  debounceLink,
  dpeHeadersLink,
  errorLink,
  persistedQueryLink,
  resetIdleTimerLink,
  retryLink,
  timeoutLink,
} from './links'

const inMemoryCache = new InMemoryCache({
  typePolicies: {
    CustomerLoyalty: {
      merge: true,
    },
  },
})

const config = getCountryConfig()
const retryEnabled = config.APOLLO_RETRY == 'true'

export interface PersistedQueryDevToggles {
  isPersistedQueryEnabled: boolean
  isUseGETForHashedQueriesEnabled: boolean
}

export const apolloClient = (
  setup: GraphSetup,
  securitySender: SecuritySender,
  { isPersistedQueryEnabled, isUseGETForHashedQueriesEnabled }: PersistedQueryDevToggles = {
    isPersistedQueryEnabled: false,
    isUseGETForHashedQueriesEnabled: false,
  },
) =>
  new ApolloClient({
    cache: inMemoryCache,
    link: ApolloLink.from([
      akamaiHeadersLink,
      dpeHeadersLink(setup),
      authLink,
      debounceLink,
      retryLink(retryEnabled, securitySender),
      onError(b2BErrorHandler),
      errorLink(securitySender),
      resetIdleTimerLink,
      timeoutLink,
      ...(isPersistedQueryEnabled ? [persistedQueryLink(isUseGETForHashedQueriesEnabled)] : []),
      afterwareResponseLink(securitySender).concat(
        createHttpLink({
          fetch,
          uri: setup.url,
        }),
      ),
    ]),
  })

const exportFunctions = {
  apolloClient,
}

export default exportFunctions
