import React, { useEffect, useMemo, useState } from 'react'
import styles from './hard-upsell-container.less'
import { HardUpsellActionButtons } from '../hard-upsell-bottom-button/hard-upsell-action-buttons'
import SelectableProductCard from './selectable-product-card/selectable-product-card'
import { navigate } from '@reach/router'
import { NavigationConstants } from '@dominos/navigation'
import { useDispatch, useSelector } from 'react-redux'
import { countFromBasketLines } from '@dominos/business/functions/basket'
import { useUpsellOffer } from '@dominos/hooks-and-hocs/offers'
import { rootActions } from '@dominos/business'
import { HardUpsellItem } from '../types'
import { useTranslation } from 'react-i18next'
import { UpsellFeatureType, useReport } from '@dominos/hooks-and-hocs'

type HardUpsellContainerProps = BaseProps

const UPSELL_OFFER_OPTIONS = {
  allowedLayout: 'MultiItemGrid',
  locationType: 'Page',
  offerLocationCode: 'PaymentSelection',
}
export const HardUpsellContainer = ({ testID }: HardUpsellContainerProps) => {
  const dispatch = useDispatch()
  const hardUpsellOffers = useUpsellOffer(UPSELL_OFFER_OPTIONS)
  const [offerItems, setOfferItems] = useState<HardUpsellItem[]>()
  const [isItemSelected, setIsItemSelected] = useState<boolean>(false)
  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)
  const itemCount = useMemo(() => countFromBasketLines(basketData.lines), [basketData.lines])
  const {
    reportHardUpsellProductAddedToCart,
    reportHardUpsellSkip,
    reportHardUpsellInitialised,
    reportUpsellProductAddToCart,
  } = useReport()
  const hasBasketItems = itemCount > 0
  const { t } = useTranslation('basket')

  const handleCheckChange = (index: number) => {
    setOfferItems((prevItems) => {
      const updatedItems = prevItems?.map((item, i) => (i === index ? { ...item, isSelected: !item.isSelected } : item))
      const selected = updatedItems?.filter((item) => item?.isSelected)
      if (selected) {
        setIsItemSelected(selected?.length > 0)
      }

      return updatedItems
    })
  }

  useEffect(() => {
    setOfferItems(hardUpsellOffers)
  }, [hardUpsellOffers])

  useEffect(() => {
    if (!hasBasketItems) {
      navigate(NavigationConstants.menu)
    } else {
      reportHardUpsellInitialised()
    }
  }, [])

  const handleSkip = () => {
    reportHardUpsellSkip()
    navigate(NavigationConstants.checkout)
  }

  const handleAddToOrder = () => {
    const selected = offerItems?.filter((item) => item?.isSelected)
    if (selected) {
      const selectedItems: BasketLine[] = selected?.map((item) => ({
        quantity: 1,
        productCode: item.linkedItem?.itemCode,
        itemNo: 0,
        type: 'BasketLine',
        media: {
          name: item.name,
        },
        totalPrice: null,
        sizeCode: item.linkedItem.subItemCode,
      }))

      dispatch(rootActions.addLinesToBasket({ add: selectedItems }))
      reportHardUpsellProductAddedToCart(selectedItems)
      reportUpsellProductAddToCart(selectedItems, UpsellFeatureType.hard_upsell)
      navigate(NavigationConstants.checkout)
    }
  }

  return (
    <div className={styles.container} data-testid={testID}>
      <h1 data-testid={`${testID}.title`} className={styles.titleText}>
        {t('HardUpsellTitle')}
      </h1>

      <div data-testid={`${testID}.product-container`} className={styles.productContainer}>
        {offerItems?.map((item, index) => (
          <SelectableProductCard
            testID={`${testID}.item.${item.id}`}
            offerItem={item}
            key={index}
            onSelected={() => handleCheckChange(index)}
          />
        ))}
      </div>
      <div className={styles.buttonsContainer}>
        <HardUpsellActionButtons
          testID={'hard-upsell'}
          isItemSelected={isItemSelected}
          onSkip={handleSkip}
          onAddToOrder={handleAddToOrder}
        />
      </div>
    </div>
  )
}

export default HardUpsellContainer
