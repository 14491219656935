import React from 'react'
import { FeatureFlagType } from '../feature-flag.interface'
import { useIdentifyContext } from './use-identify-context'
import { useFeatureFlags } from './use-feature-flags'

interface LaunchDarklyFlagsContextProps {
  flags: FeatureFlagType
}

const LaunchDarklyFlagsContext = React.createContext<LaunchDarklyFlagsContextProps>({
  flags: {},
})

export const LaunchDarklyFlagsProvider = ({ children }: PropsWithChildren) => {
  useIdentifyContext()
  const { flags } = useFeatureFlags()

  return <LaunchDarklyFlagsContext.Provider value={{ flags }}>{children}</LaunchDarklyFlagsContext.Provider>
}

export const useLaunchDarklyFlagsContext = () => React.useContext(LaunchDarklyFlagsContext)
