import React, { useState } from 'react'
import { ActionButton } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { KioskOrderPopup } from '@dominos/components/kiosk'
import { useTranslation } from 'react-i18next'
import { useRestartOrder } from '@dominos/components/footer/simple-footer/use-restart-order'

export const ResetOrderButton = () => {
  const theme = useDominosTheme()
  const [isResetPopupVisible, setResetPopupVisible] = useState(false)
  const resetOrder = useRestartOrder({ report: false, fos: false, updateFeatureToggles: true })

  const onPressResetButton = () => {
    setResetPopupVisible(true)
  }
  const { t } = useTranslation('kiosk')

  return (
    <>
      <ActionButton
        text={t('ResetOrder', { defaultValue: 'Reset Order' })}
        onPress={onPressResetButton}
        testID={'reset-order-button'}
        containerStyle={{
          backgroundColor: theme.colours.web.grey,
          color: theme.colours.tooltipText,
          height: 55,
        }}
        textFontWeight={'semibold'}
        textSize='1rem'
      />
      {isResetPopupVisible && (
        <KioskOrderPopup
          testId='reset-order-popup'
          popupTitle={''}
          popupMessage={t('KioskPopupResetOrderMessage', {
            defaultValue: 'Are you sure you want to reset  <br /> your order?',
          })}
          onConfirm={() => {
            setResetPopupVisible(false)
          }}
          onConfirmText={t('Continue', { defaultValue: 'Continue' })}
          onDismiss={resetOrder}
          onDismissText={t('Reset', { defaultValue: 'Reset' })}
          closeModalOnClick={false}
          icon='order'
        />
      )}
    </>
  )
}
