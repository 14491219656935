import { useDominosTheme, useFeatures } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DetailRow } from '@dominos/components/basket/detail-row'
import css from './order-detailed-summary.less'
import Surcharges from '@dominos/components/basket/surcharges/surcharges'
import { CHARITY_SURCHARGE_CODE } from '@dominos/components/charity'
import { calculateOrderSurchargeTotal } from '@dominos/business/functions/basket/calculate-order-surcharge-total'
import { calculateOrderUpgradeTotal } from '@dominos/business/functions/basket/calculate-order-upgrade-total'

interface OrderDetailedSummaryProps {
  testID: string
  basketData: Bff.Orders.OrderDetails.Basket | Basket
  isTrackingOrderDetail?: boolean
  roundUpForCharityEnabled?: boolean
  driverTip?: number
}

export const OrderDetailedSummary = ({
  testID,
  isTrackingOrderDetail,
  basketData,
  roundUpForCharityEnabled,
  driverTip,
}: OrderDetailedSummaryProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation(['basket', 'menu'])
  const { featureEnabled } = useFeatures()
  const [showMinimumOrderValueEnabled] = featureEnabled('ShowMinimumOrderValue')

  const filteredSurcharges =
    isTrackingOrderDetail && roundUpForCharityEnabled
      ? basketData?.surcharges.filter((surcharge: BasketSurcharge) => surcharge.code !== CHARITY_SURCHARGE_CODE)
      : basketData?.surcharges
  const charitySurcharge = basketData?.surcharges?.find(
    (surcharge: BasketSurcharge) => surcharge.code === CHARITY_SURCHARGE_CODE,
  )
  const displayTotal =
    isTrackingOrderDetail && roundUpForCharityEnabled && charitySurcharge
      ? (basketData?.total || 0) - charitySurcharge.price
      : basketData?.total

  const upgradeAmount = calculateOrderUpgradeTotal(basketData)
  const surchargeTotalAmount = calculateOrderSurchargeTotal(filteredSurcharges ?? [])
  const originalPrice = (displayTotal || 0) + (basketData?.totalDiscount || 0) - upgradeAmount - surchargeTotalAmount

  const shouldShowOriginalPrice = basketData?.totalDiscount || upgradeAmount || surchargeTotalAmount ? true : false
  const shouldShowSurcharge = surchargeTotalAmount > 0
  const shouldShowMinimumOrderValue =
    showMinimumOrderValueEnabled &&
    !isTrackingOrderDetail &&
    (basketData as Basket).minimumPrice !== undefined && // The type of 'basketData' is always 'Basket' type when 'isTrackingOrderDetail' is set to true.
    ((basketData as Basket).minimumPrice || 0) > 0
  const isPriceStrikethrough = originalPrice > (displayTotal || 0)

  const styles = {
    originalPrice: {
      color: '#cf343d',
      fontSize: '12px',
    },
    defaultText: {
      color: theme.colours.buttonDisabledText,
      fontSize: '12px',
      paddingTop: '3px',
    },
    orderTotal: {
      color: theme.colours.buttonDisabledText,
      fontSize: '14px',
    },
    minimumPrice: {
      fontSize: '12px',
      paddingInline: '10px',
      paddingTop: '8px',
    },
  }

  return (
    <div data-testid={`${testID}.container`}>
      <div className={css.summaryContainer}>
        {shouldShowOriginalPrice && (
          <DetailRow
            testID={`${testID}.original-price-value`}
            label={t('OriginalPrice', { defaultValue: 'Original Price' })}
            price={originalPrice}
            textStyle={styles.originalPrice}
            isPriceStrikethrough={isPriceStrikethrough}
            fontWeight={'normal'}
          />
        )}
        {(basketData?.totalDiscount || 0) > 0 && (
          <DetailRow
            testID={`${testID}.discount-value`}
            label={t('VoucherDiscounts', { defaultValue: 'Voucher Discounts' })}
            price={basketData?.totalDiscount}
            textStyle={styles.defaultText}
            amountPrefix='-'
          />
        )}
        {upgradeAmount > 0 && (
          <DetailRow
            testID={`${testID}.upgrades-value`}
            label={t('Upgrades', { defaultValue: 'Upgrades' })}
            price={upgradeAmount}
            textStyle={styles.defaultText}
            amountPrefix='+'
            fontWeight={'normal'}
          />
        )}
        {shouldShowSurcharge && (
          <Surcharges
            testID={`${testID}.surcharges`}
            surcharges={filteredSurcharges}
            textStyle={styles.defaultText}
            showPrefix
            excludeVoucherDiscount
          />
        )}
        {shouldShowOriginalPrice && <div className={css.divider} />}
        <DetailRow
          testID={`${testID}.total-value`}
          label={t('OrderTotal')}
          price={displayTotal}
          textStyle={styles.orderTotal}
        />
      </div>
      {driverTip && (
        <DetailRow
          testID={`order-total.driver-tip-value`}
          label={t('menu:DRIVER TIP')}
          price={driverTip}
          textStyle={styles.minimumPrice}
          fontWeight={'normal'}
        />
      )}
      {shouldShowMinimumOrderValue && (
        <DetailRow
          testID={`${testID}.minimum-order-value`}
          label={t('Minimum order amount')}
          price={(basketData as Basket).minimumPrice} // The type of 'basketData' is always 'Basket' type when 'isTrackingOrderDetail' is set to true.
          fontWeight='normal'
          textStyle={styles.minimumPrice}
        />
      )}
    </div>
  )
}
