import { FORGEROCK_CONSTANT, ForgerockJourney } from '@dominos/business/constants'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'

export const buildForgerockUrl = (urlParams: IForgerockUrlParams, journey: ForgerockJourney): string => {
  const forgeRockAmURL = getCountryConfig().FORGEROCK_AM_URL
  const locale = `${urlParams.language}-${urlParams.countryCode}`
  const realm = FORGEROCK_CONSTANT.REALM_PATH
  const indexType = FORGEROCK_CONSTANT.INDEX_TYPE

  return `${forgeRockAmURL}XUI/?realm=${realm}&authIndexType=${indexType}&authIndexValue=${journey}&market=${urlParams.countryCode}&locale=${locale}&application=${urlParams.application}&ForceAuth=true`
}
