import { FeatureContext, useFeatures } from '@dominos/hooks-and-hocs'
import { useContext, useEffect, useState } from 'react'
import { StartUserJourneyOnMenuFeature, useReport } from '@dominos/hooks-and-hocs/logging'

export const useStartUserJourneyOnMenuEnabled = () => {
  const { loading: featureLoading } = useContext(FeatureContext)
  const { getBooleanVariation } = useFeatures()
  const [startUserJourneyOnMenuEnabled, setStartUserJourneyOnMenuEnabled] = useState<boolean | undefined>(undefined)
  const { reportFeatureTimeout } = useReport()

  useEffect(() => {
    if (!featureLoading) {
      setStartUserJourneyOnMenuEnabled((prev) => {
        // only update state if not already set by timeout
        if (prev === undefined) {
          return getBooleanVariation('StartUserJourneyOnMenu')
        }

        return prev
      })
    }
  }, [featureLoading])

  useEffect(() => {
    // timeout to read initial feature value if feature service too slow to respond
    const timer = setTimeout(() => {
      setStartUserJourneyOnMenuEnabled((prev) => {
        // only update state if not already set by feature loading
        if (prev === undefined) {
          const featureValue = getBooleanVariation('StartUserJourneyOnMenu')
          reportFeatureTimeout(StartUserJourneyOnMenuFeature, featureValue)

          return featureValue
        }

        return prev
      })
    }, 2000)

    return () => clearTimeout(timer)
  }, [])

  return startUserJourneyOnMenuEnabled
}
