import { allergensMockData, nutritionalsMockData } from '@dominos/business/functions/nutritional/index'
import {
  BottomBar,
  CountryToggle,
  ProductCustomiser,
  ProductEditor,
  ProductPortions,
  useProductContext,
} from '@dominos/components'
import { createBasketLineSwap, useBaseData, useSauceData, useSizeData } from '@dominos/components/product/functions'
import {
  createPortionProductCardBannerViewModel,
  PortionProductCardBanner,
  PortionProductCardTitle,
  ProductCardDescription,
  ProductCardImage,
} from '@dominos/components/product/portion-product-card'
import { ProductNutritionalCard } from '@dominos/components/product/product-nutritional-feature-product'
import {
  ProductAllergen,
  ProductNutritionals,
} from '@dominos/components/product/product-nutritional-feature-product/product-nutritional.interface'
import { useBreakpoints, useFeatures, useKiosk, useProduct } from '@dominos/hooks-and-hocs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import css from './portion-product-card.less'

export const PortionProductCard = ({ testID, onDismiss, addToBasket, isEditing }: PortionProductCardProps) => {
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const isMobileLayout = isMobile || isKioskOrder
  const {
    dimensionSetState,
    productData,
    portions,
    handlePortionChange,
    createBasketLine,
    isValidForBasket,
    initialQuantity,
    resetPortion,
    formattedPrice,
    formattedPromoPrice,
    handlePortionCustomisations,
  } = useProductContext()
  const [customisingPortionIndex, setCustomisingPortionIndex] = useState<number | undefined>()
  const { featureEnabled } = useFeatures()
  const showContainsAllergensOnly = CountryToggle({
    FR: true,
    default: false,
  })
  const sizeData = useSizeData(productData, dimensionSetState)
  const baseData = useBaseData(productData, dimensionSetState)
  const [nutritionalEnabled, nutritionalInfoButtonEnabled] = featureEnabled(
    'nutritionals',
    'ProductNutritionalInformationButton',
  )
  const { t } = useTranslation('menu')
  const [selectedIngredientBasketLineSwap, setSelectedIngredientBasketLineSwap] = useState<BasketLineSwap | undefined>()
  useEffect(() => {
    setSelectedIngredientBasketLineSwap(
      customisingPortionIndex === undefined ? undefined : portions?.[customisingPortionIndex]?.sauce,
    )
  }, [customisingPortionIndex])
  //TODO: getNutritionalInfo() functionality needed to added with state menagement card
  const { productNutritionals, productAllergens } = nutritionalEnabled
    ? {
        productNutritionals: nutritionalsMockData as ProductNutritionals,
        productAllergens: allergensMockData as ProductAllergen[],
      }
    : { productNutritionals: null, productAllergens: [] }
  const portionData = useProduct(
    customisingPortionIndex !== undefined ? portions?.[customisingPortionIndex]?.productCode : undefined,
    undefined,
    true,
  )
  const ingredients = portionData.getIngredients('Sauce', dimensionSetState?.selectedDimensionSet)
  const ingredientsTypeRules = portionData.getIngredientTypeRule('Sauce', dimensionSetState?.selectedDimensionSet)
  const defaultIngredient = ingredients?.find((ingredient) => ingredient.inRecipe === true)
  const sauceData = useSauceData(ingredients, selectedIngredientBasketLineSwap, ingredientsTypeRules?.minQuantity === 0)
  const onSizeChange = (sizeCode: string) => {
    dimensionSetState?.handleDimensionChange('Size', sizeCode, true)
  }
  const onBaseChange = (baseCode: string) => {
    dimensionSetState?.handleDimensionChange('Base', baseCode)
  }
  const addToBasketHandler = (quantity: number) => {
    addToBasket(createBasketLine(quantity))
    onDismiss(true)
  }
  const onSauceChange = (sauceCode: string) => {
    setSelectedIngredientBasketLineSwap(createBasketLineSwap(sauceCode, defaultIngredient?.code))
  }
  const savePortionCustomisationsHandler = () => {
    customisingPortionIndex !== undefined &&
      handlePortionCustomisations(customisingPortionIndex, 'sauce', selectedIngredientBasketLineSwap)
    setCustomisingPortionIndex(undefined)
  }
  const handleCustomisePortion = (portionIndex: number) => {
    setCustomisingPortionIndex(portionIndex)
  }

  return customisingPortionIndex !== undefined ? (
    <ProductEditor
      testID={`${testID}.portion-editor`}
      onDismiss={onDismiss}
      showBackButton={false}
      showCloseButton={false}
    >
      <ProductCardImage testID={testID} uri={portionData.media?.largeImage.uri ?? ''}></ProductCardImage>
      <div data-testid={`${testID}.portion-editor-content`} className={css.portionEditorContent}>
        <ProductCardDescription testID={testID} media={portionData.media}></ProductCardDescription>
        <ProductCustomiser sauceData={sauceData} onSauceChange={onSauceChange} />
        {/* TODO: Add Topping selector components should be added here */}
      </div>
      <BottomBar
        testID={`${testID}.portion-bottom-bar`}
        onComplete={savePortionCustomisationsHandler}
        initialQuantity={1}
        isPortionProduct={true}
        forceDisabled={false}
      />
    </ProductEditor>
  ) : (
    <ProductEditor
      testID={`${testID}.product-editor`}
      onDismiss={onDismiss}
      showBackButton={!!isMobileLayout}
      showCloseButton={!isMobileLayout}
    >
      <div data-testid={`${testID}.product-editor-content}`} className={css.productEditorContent}>
        <PortionProductCardTitle
          testID={testID}
          title={productData?.media?.name.value || ''}
          price={formattedPrice}
          promoPrice={formattedPromoPrice}
        />
        <PortionProductCardBanner testID={testID} viewModel={createPortionProductCardBannerViewModel({ t })} />
        <ProductPortions
          t={t}
          testID={`${testID}.product-portions`}
          portions={portions}
          products={productData?.getPossiblePortionProducts(dimensionSetState?.selectedDimensionSet) || []}
          onPortionProductChange={handlePortionChange}
          customisable={productData?.isPortionCustomisable(dimensionSetState?.selectedDimensionSet) || false}
          onPortionReset={resetPortion}
          onPortionCustomise={handleCustomisePortion}
          isKioskOrder={isKioskOrder}
        />
        <ProductCustomiser
          sizeData={sizeData}
          onSizeChange={onSizeChange}
          baseData={baseData}
          onBaseChange={onBaseChange}
        />
        <ProductNutritionalCard
          nutritionals={productNutritionals}
          allergens={productAllergens}
          t={t}
          showContainsAllergensOnly={showContainsAllergensOnly}
          testID='nutritional-card'
          showInfoButton={nutritionalInfoButtonEnabled}
        />
      </div>
      <BottomBar
        testID={`${testID}.bottom-bar`}
        onComplete={addToBasketHandler}
        isEditing={isEditing}
        initialQuantity={initialQuantity}
        isPortionProduct={false}
        forceDisabled={!isValidForBasket}
      />
    </ProductEditor>
  )
}
