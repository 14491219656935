const createBasketLineSwap = (selectedCode: string, defaultCode: string | undefined): BasketLineSwap | undefined => {
  if (selectedCode === defaultCode) {
    return undefined
  }

  return {
    add: selectedCode,
    remove: defaultCode,
  } as BasketLineSwap
}

export { createBasketLineSwap }
