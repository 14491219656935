import { useEffect, useState } from 'react'
import { useFeatures } from '@dominos/hooks-and-hocs/features/use-features'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

export type DeliveryViewMode = 'List' | 'Map'

export const useDeliverySearchViewSwitcher = () => {
  const { featureEnabled } = useFeatures()
  const [mapViewEnabled] = featureEnabled('AutoCompleteDeliveryDefaultMapView')
  const [currentDeliverySearchView, setCurrentDeliverySearchView] = useState<DeliveryViewMode>('List')
  const { reportSelectedMapsView, reportSelectedListView } = useReportAutoCompleteDelivery()

  const toggleViewChange = () => {
    if (currentDeliverySearchView === 'Map') {
      setCurrentDeliverySearchView('List')
      reportSelectedListView()
    } else {
      setCurrentDeliverySearchView('Map')
      reportSelectedMapsView()
    }
  }

  useEffect(() => {
    setCurrentDeliverySearchView(mapViewEnabled ? 'Map' : 'List')
  }, [mapViewEnabled])

  return { currentDeliverySearchView, toggleViewChange }
}
