import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { EnhancedEcommercePurchaseEvent, useMenu, usePurchaseEvent } from '@dominos/hooks-and-hocs'
import { ApplicationConfiguration } from '@dominos/interfaces'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { useSelector } from 'react-redux'
import { analytics } from './analytics'
import { reportCheckout } from './report-checkout'
import { reportCustomer } from './report-customer'
import { reportError } from './report-error'
import { reportInteract } from './report-interact'
import { reportPage } from './report-page'
import { reportProduct } from './report-product'
import { reportTracker } from './report-tracker'
import { getSecurityCommonParams, security, useSecurityContext } from './security'
import { reportStartJourneyOnMenu } from './report-start-journey-on-menu'
import { reportEventBanners } from './report-event-banners'
import { reportFeature } from './report-feature'

export const report = (
  applicationInsights: ApplicationInsights | null,
  settings: ApplicationConfiguration,
  appStoredPurchaseEvent: EnhancedEcommercePurchaseEvent,
  basket?: Basket,
  currentStore?: Bff.Stores.Store,
  currentOrder?: CurrentOrderDetailsReducerProps,
  menu?: Menu,
) => {
  const { sendSecurity } = security(applicationInsights, getSecurityCommonParams(settings))
  const { sendAnalytics } = analytics()

  return {
    ...reportCustomer(sendSecurity, sendAnalytics),
    ...reportInteract(sendSecurity, sendAnalytics, settings, basket, menu, currentStore, currentOrder),
    ...reportCheckout(sendSecurity, sendAnalytics, appStoredPurchaseEvent, basket, currentStore, currentOrder),
    ...reportProduct(sendSecurity, sendAnalytics, currentOrder),
    ...reportPage(sendSecurity, sendAnalytics, settings, basket, menu, currentStore, currentOrder),
    ...reportError(sendSecurity, currentOrder),
    ...reportTracker(sendSecurity, currentOrder),
    ...reportStartJourneyOnMenu(sendSecurity, sendAnalytics, currentOrder),
    ...reportEventBanners(sendAnalytics),
    ...reportFeature(sendSecurity, currentOrder),
  }
}

export const useReport = () => {
  const { client } = useSecurityContext()
  const settings = useSelector((store: RootReducer) => store.applicationReducer)
  const { basket } = useSelector((store: RootReducer) => store.basketReducer)
  const { currentStore } = useSelector((store: RootReducer) => store.storeReducer)
  const currentOrder = useSelector((state: RootReducer) => state.currentOrderDetailsReducer)
  const { menu } = useMenu()
  const eventDetails = usePurchaseEvent()

  return report(client, settings, eventDetails, basket, currentStore, currentOrder, menu)
}
