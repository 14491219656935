import { ErrorCodes } from '@dominos/business'
import {
  PortionProductCard,
  ProductCard,
  ProductCardLoader,
  RecipeProductCard,
  useProductContext,
} from '@dominos/components'
import { useAlert, useDevToggles, useFeatures } from '@dominos/hooks-and-hocs'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const ProductCardSwitcher = ({
  currentProduct,
  addToBasket,
  lineData,
  isEditing,
  onDismiss,
}: ProductCardSwitcherProps) => {
  const { productData } = useProductContext()
  const { featureEnabled } = useFeatures()
  const { isEnabled } = useDevToggles()
  const { t } = useTranslation('menu')
  const { showAlert } = useAlert(() => alertCallBack())
  const alertCallBack = () => onDismiss()

  const [toggleHalfHalfPortionProductCardEnabled] = featureEnabled('HalfHalfPortionProductCardEnabled')

  const isPortionProduct =
    productData?.isPortionProduct &&
    (productData?.defaultPortions?.length === 4 ||
      (productData?.defaultPortions?.length === 2 && toggleHalfHalfPortionProductCardEnabled))

  useEffect(() => {
    productData?.errored && showAlert(`${t('ProductLoadFailedErrorMessage')} [${ErrorCodes.PRODUCT_LOAD_FAILED}]`)
  }, [productData?.errored])

  if (productData?.loading) {
    return <ProductCardLoader testID='product-card-loader' />
  }

  if (!productData?.loading && !productData?.errored) {
    if (isPortionProduct) {
      return (
        <PortionProductCard
          testID='portion-product-card'
          onDismiss={onDismiss}
          isEditing={isEditing}
          addToBasket={addToBasket}
        />
      )
    }

    if (isEnabled['enable-recipe-product-card'] && productData?.type === 'Recipe') {
      return <RecipeProductCard testID='recipe-product-card' onDismiss={onDismiss} />
    }

    return (
      <ProductCard
        testID='product-card'
        item={currentProduct}
        onDismiss={onDismiss}
        addToBasket={addToBasket}
        lineData={lineData}
        isEditing={isEditing}
      />
    )
  }

  return null
}
