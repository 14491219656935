import { rootActions } from '@dominos/business'
import { createFosEventsHandler, isCurrentOrderValid } from '@dominos/business/functions'
import { buildBasketRequestFromOrder, buildValidateBasketRequest } from '@dominos/business/functions/basket'
import { createConfig } from '@dominos/business/functions/epics'
import { getBooleanFromFeatures } from '@dominos/hooks-and-hocs/features/use-features'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import { FosEventInfoType } from 'olo-feature-fos'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'
import { ActionsObservable, Epic, StateObservable } from 'redux-observable'
import { Observable, of, pipe } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { getType, isActionOf } from 'typesafe-actions'
import { validateBasketMutation } from '../queries'

const notTrackerPage = () =>
  !window.location.pathname.startsWith('/tracker') && !window.location.pathname.startsWith('/nativeAppTracker')

export const validateBasketEpic: Epic = (
  action$: ActionsObservable<RootActions>,
  store$: StateObservable<RootReducer>,
  { graphQLMutation, securitySender }: IReduxInjected,
) =>
  action$.pipe(
    filter(
      isActionOf([
        rootActions.validateBasket.request,
        rootActions.saveOrder,
        rootActions.addLinesToBasket,
        rootActions.removeLineFromBasket,
        rootActions.replaceBasketLine,
        rootActions.addCoupons,
        rootActions.addDeepLinkCoupon,
        rootActions.addLoyaltyCoupon,
        rootActions.selectOrderTime,
        rootActions.removeCoupon,
        rootActions.basketFatal,
        rootActions.updateLanguage,
        rootActions.updatePaymentMethod,
      ]),
    ),
    filter(notTrackerPage),
    switchMap((action) => {
      if (!isCurrentOrderValid(store$.value)) {
        securitySender(
          'Flow.ValidateBasket.InvalidOrder',
          {
            message: 'Order restarted and user redirected back to home page',
            path: window.location.href,
          },
          true,
        )
        navigate(NavigationConstants.home)

        return of(rootActions.restartOrder())
      }

      let allowAutoExclusiveCoupon
      const withSalePrice =
        store$.value.currentOrderDetailsReducer.basketHeaderData?.serviceMethod === 'Pickup' &&
        store$.value.featureReducer.features &&
        getBooleanFromFeatures('SalePriceForPickup', store$.value.featureReducer.features)
      if (
        action.type === getType(rootActions.selectOrderTime) ||
        action.type === getType(rootActions.addCoupons) ||
        action.type === getType(rootActions.addDeepLinkCoupon) ||
        action.type === getType(rootActions.addLoyaltyCoupon) ||
        action.type === getType(rootActions.removeCoupon)
      ) {
        const autoExclusiveCouponForPickup = store$.value.featureReducer.features
          ? getBooleanFromFeatures('AutoExclusiveCouponForPickup', store$.value.featureReducer.features)
          : false
        allowAutoExclusiveCoupon =
          autoExclusiveCouponForPickup &&
          store$.value.currentOrderDetailsReducer.basketHeaderData?.serviceMethod === 'Pickup'
            ? true
            : undefined
      }

      const payload =
        action.type === getType(rootActions.saveOrder)
          ? buildBasketRequestFromOrder(
              action.payload.order,
              store$.value.currentOrderDetailsReducer.advanceOrder.id,
              action.payload.customer,
              store$.value.customerReducer.customerId,
            )
          : buildValidateBasketRequest(
              store$.value.currentOrderDetailsReducer.orderId,
              store$.value.currentOrderDetailsReducer.advanceOrder.id,
              {
                ...store$.value.currentOrderDetailsReducer.basketHeaderData,
                allowAutoExclusiveCoupon,
                withSalePrice,
              },
              {
                ...store$.value.basketReducer.basket,
                pendingCoupons: store$.value.basketReducer.pendingCoupons,
                pendingLoyaltyCoupons: store$.value.basketReducer.pendingLoyaltyCoupons,
              },
              store$.value.currentOrderDetailsReducer.customerDetailsData,
              store$.value.currentOrderDetailsReducer.paymentMethod,
              store$.value.currentOrderDetailsReducer.selectedDeliveryAddress,
              store$.value.customerReducer.customerId,
            )

      const config: GraphConfig | null = createConfig(store$.value, payload ? payload : undefined)
      if (!config || !payload) {
        handleFosBasketEvent(store$.value, securitySender, payload, action)

        return new Observable((observer) => {
          observer.next(rootActions.validateBasket.success(validateBasketEmptyResponse))
        })
      }

      if (store$.value.customerReducer.isLogged && store$.value.customerReducer.customerToken) {
        config.context = {
          headers: {
            authorization: `bearer ${store$.value.customerReducer.customerToken}`,
          },
        }
      }

      return graphQLMutation<ValidBasketSubResponse>(validateBasketMutation, config, securitySender).pipe(
        map((data) => sendFosBasketEvent(store$.value, data, securitySender, action)),
        map(rootActions.validateBasket.success),
        catchError(pipe(rootActions.validateBasket.failure, of)),
      )
    }),
  )

const validateBasketEmptyResponse = {
  validateBasket: {
    basket: { totalDiscount: 0, id: '', lines: [], total: 0, surcharges: [] },
    validationErrors: [],
  },
} as ValidBasketSubResponse

const handleFosBasketEvent = (
  rootReducer: RootReducer,
  securitySender: SecuritySender,
  payload: MutationWrapper<ValidateBasketInput> | null,
  action: RootActions,
) => {
  if (
    !payload &&
    (action.type === getType(rootActions.removeLineFromBasket) || action.type === getType(rootActions.removeCoupon))
  ) {
    sendFosBasketEvent(rootReducer, validateBasketEmptyResponse, securitySender, action)
  }
}

const sendFosBasketEvent = (
  rootReducer: RootReducer,
  data: ValidBasketSubResponse,
  securitySender: SecuritySender,
  action: RootActions,
) => {
  const { sendFosEvents } = createFosEventsHandler(rootReducer, securitySender)
  sendFosEvents({
    type: FosEventInfoType.BasketEvent,
    actionType: action.type,
    validBasketSubResponse: data,
  })

  return data
}
