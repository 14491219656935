import { TFunction } from 'i18next'
import { getProductDefaultSize } from './get-product-default-size'

/**
 * Determines if the quantity of ingredients is within the configured
 * range for the products topping constraints
 * @param item Product menu item
 * @param quantity Total number of ingredients
 * @param previousQuantity Previous total number of ingredients
 * @param section The array of sections created by `productIngredientsTransform`
 * @param t Translations for the menu scene
 * @returns Validation failure messages or none (success)
 */
// ! From `@dominos/business/functions/menu`
export const getProductRulesLogic = (
  item: ProductMenuItem,
  quantity: number,
  previousQuantity: number,
  currents: IngredientItemProps[],
  t: TFunction,
) => {
  const size = getProductDefaultSize(item) as ProductSize

  if (!size || !size.swaps || !size.swaps.toppings) {
    return []
  }

  const rule = size.swaps.toppings.rule
  const totalToppingCount = currents.reduce((a: number, b: IngredientItemProps) => a + b.quantity, 0)

  return [
    {
      expression: () => quantity > rule.maxPerIngredient,
      title: t('MaximumSingleTopping'),
      message: t('MaximumSingleToppingMessage', {
        defaultValue: `Maximum {{maxPerIngredient}} serves of any single topping allowed on each pizza or pizza half`,
        maxPerIngredient: rule.maxPerIngredient,
      }),
    },
    {
      expression: () => previousQuantity < quantity && totalToppingCount >= rule.max,
      title: t('MaximumToppings'),
      message: t('MaximumToppingsMessage', {
        defaultValue: `A maximum of {{max}} topping serves allowed on each pizza or pizza half`,
        max: rule.max,
      }),
    },
    {
      expression: () => previousQuantity > quantity && totalToppingCount <= rule.min,
      title: t('MinimumToppings'),
      message: t('MinimumToppingsMessage', {
        defaultValue: 'A minimum of {{min}} topping serves allowed on each pizza or pizza half',
        min: rule.min,
      }),
    },
  ]
}
