import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { SpotlightProvider } from '@dominos/components/spotlight'
import {
  AuthenticationPopupHandler,
  DeepLinkHandler,
  KioskOrderHandler,
  LastOrderHandler,
} from '@dominos/hooks-and-hocs'
import { B2BAuthenticationHandler } from '@dominos/hooks-and-hocs/authentication/b2b-authentication/b2b-authentication-handler'
import { UserInactivityMonitor } from '@dominos/hooks-and-hocs/session'
import { FosSessionImport, HistoryListener, ScrollToTop } from '../hocs'
import { KioskInactivityDetector } from '@dominos/components'

export const RouterContexts: React.FC<RouteComponentProps> = (props) => (
  <B2BAuthenticationHandler>
    <AuthenticationPopupHandler {...props}>
      <KioskOrderHandler {...props}>
        <UserInactivityMonitor {...props}>
          <DeepLinkHandler {...props}>
            <LastOrderHandler {...props}>
              <HistoryListener {...props}>
                <FosSessionImport {...props}>
                  <SpotlightProvider path='/'>
                    <ScrollToTop {...props}>
                      <KioskInactivityDetector>{props.children}</KioskInactivityDetector>
                    </ScrollToTop>
                  </SpotlightProvider>
                </FosSessionImport>
              </HistoryListener>
            </LastOrderHandler>
          </DeepLinkHandler>
        </UserInactivityMonitor>
      </KioskOrderHandler>
    </AuthenticationPopupHandler>
  </B2BAuthenticationHandler>
)
