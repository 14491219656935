import { flattenMenuToSections } from '@dominos/business/functions/menu'
import { AnalyticEventNames, AnalyticEvents, Currency, EcommerceCart, Product } from './analytics-interfaces'
import { GATrackingMessage, isNativeApp, postWebViewMessage } from '@dominos/business/functions/native-app'
import { isProductMenuItemNew } from '@dominos/components'

type MenuPage = Bff.Menu.old.MenuPage
type MenuSection = Bff.Menu.old.MenuSection

export const analytics = () => ({
  getGoogleID: () => {
    const match = /_ga=(.+?(?=;)|.+[^\W])/gi.exec(decodeURIComponent(document.cookie)) ?? undefined

    return match && match[1]
  },
  sendAnalytics: <K extends AnalyticEventNames>(name: K, data?: AnalyticEvents[K], callback?: () => void) => {
    const eventData: Record<string, unknown> = {
      event: name,
      ...(data && removeNullOrUndefined(data as unknown as Record<string, unknown>)),
    }
    if (name === 'EE - Purchase') {
      eventData.eventCallback = callback
      localStorage.removeItem('orderCheckoutDetails')
    }

    if (isNativeApp()) {
      postWebViewMessage<GATrackingMessage>({ type: 'tracking', data: eventData })
    } else {
      if (!window.dataLayer) {
        window.dataLayer = []
      }

      window.dataLayer.push(eventData)
    }
  },
})

const removeNullOrUndefined = (obj: Record<string, unknown>) =>
  Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      acc[key] = obj[key]
    }

    return acc
  }, {} as Record<string, unknown>)

export const basketToEcommerceCart = (
  basket?: Basket,
  menuPages?: MenuPage[] | null,
  currency?: Currency,
): EcommerceCart['Ecommerce Cart'] => {
  if (!basket || !menuPages) {
    return
  }

  return {
    Total: basket.total ?? 0.0,
    'Total As Integer': Math.round(basket.total ?? 0),
    Products: basketToProducts(basket, menuPages, currency),
  }
}

export const basketToProducts = (basket: Basket, menuPages: MenuPage[], currency?: Currency) => {
  const menuSections = flattenMenuToSections(menuPages)

  return basket?.lines?.reduce((products, line) => {
    const lines: Product[] = []

    switch (line.type) {
      case 'BasketCoupon':
        line.items.forEach((basketCouponItem) => {
          basketCouponItem.lines.forEach((basketLine) => {
            lines.push(basketLineToProduct(basketLine, menuSections, currency, line.code))
          })
        })

        break
      case 'BasketLine':
        lines.push(basketLineToProduct(line, menuSections, currency))
        break
    }

    return [...products, ...lines]
  }, [] as Product[])
}

export const basketLineToProduct = (
  basketLine: BasketLine,
  menuSections: MenuSection[],
  currency?: Currency,
  coupon?: string,
): Product => {
  let sauce: string | undefined
  let sectionCode: string = ''

  menuSections.forEach((section) => {
    section.items.forEach((item) => {
      switch (item.type) {
        case 'Portion':
          if (item.code === basketLine.productCode) {
            sectionCode = section.code
          }
          break
        case 'Product':
          if (!isProductMenuItemNew(item) && item.code === basketLine.productCode) {
            sauce = sauce = getSauce(basketLine, item)
            sectionCode = section.code
          }
          break
        case 'Variety':
          const variety = item.items.find((v) => v.code === basketLine.productCode)
          if (variety) {
            if (variety.type === 'Product') {
              sauce = getSauce(basketLine, variety)
            }
            sectionCode = section.code
          }
          break
      }
    })
  })

  const crust = basketLine.media.base
  const size = basketLine.sizeCode
  const modifications = basketLine.toppings?.map((change) => `${change.action} ${change.media.name}`)

  return {
    currency,
    name: basketLine.media.name,
    id: basketLine.productCode,
    price: basketLine.totalPrice?.toFixed(2).toString() ?? (0.0).toFixed(2).toString(),
    category: sectionCode,
    quantity: basketLine.quantity,
    inStock: true,
    ...(crust && { crust }),
    ...(sauce && { sauce }),
    ...(size && { size }),
    ...(crust && sauce && size && { variant: `${crust} ${sauce} ${size}` }),
    ...(modifications?.length && { modifications }),
    ...(coupon && { coupon }),
  }
}

const getSauce = (
  basketLine: BasketLine,
  menuItem: Pick<ProductMenuItem, 'sizes' | 'defaultSize'> | undefined,
): string | undefined =>
  (basketLine && basketLine.sauce?.media.add) ||
  menuItem?.sizes?.find((size) => size.code === menuItem.defaultSize)?.recipe?.sauce?.media?.name

declare global {
  interface Window {
    dataLayer?: object[]
  }
}
