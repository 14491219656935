/* eslint-disable max-lines-per-function, max-lines */
import AdyenCheckout from '@adyen/adyen-web'
import ApplePay from '@adyen/adyen-web/dist/types/components/ApplePay'
import GooglePay from '@adyen/adyen-web/dist/types/components/GooglePay'
import ClickPay from '@adyen/adyen-web/dist/types/components/ClickToPay'
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement'
import Core from '@adyen/adyen-web/dist/types/core'
import AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError'
import { CoreOptions as AdyenCoreConfig } from '@adyen/adyen-web/dist/types/core/types'
import { PaymentMethodOptions } from '@adyen/adyen-web/dist/types/types'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { NextStep } from '@dominos/hooks-and-hocs/checkout/place-order.interface'
import {
  useBasketTotal,
  useBasketValidation,
  useCustomer,
  useFeatures,
  usePaymentAmount,
  useReport,
} from '@dominos/hooks-and-hocs'
import { analytics } from '@dominos/hooks-and-hocs/logging/analytics'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentMethod } from '../payment-method'
import { PaymentMethodProps } from '../payment-method.interface'
import { useCustomerCheckoutDetails } from '../use-customer-checkout-details'
import { getAdyenReturnUrl } from './get-adyen-return-url'
import { AdyenAdditionalFields } from './payment-adyen-additional-fields'
import { getCreditCardTranslations, getGiftCardTranslations } from './payment-adyen-translations'
import { useSelector } from 'react-redux'
import './payment-adyen.css'
import { AdyenCheckoutConfig, AdyenPaymentMethodExtended } from './payment-adyen.interface'
import { useAdyenNativePayment } from './use-adyen-native-payment'

declare global {
  interface WindowEventMap {
    'native-adyen-submit': CustomEvent
  }
}

interface AdyenPaymentMethodProps extends PaymentMethodProps {
  adyenPaymentMethod: AdyenPaymentMethodExtended
}

export interface AdyenState {
  data: {
    paymentMethod: object
    storePaymentMethod?: boolean
    details?: { payload: string }
  }
  isValid: boolean
}

interface ReturnUrl {
  standard: string
  additionalDetails: string
}

export const HandledByAdditionalDetails: string = 'handled_by_additional_details'

const shouldAutoMount = (methodId: string) => methodId !== 'ClickToPay-clicktopay'

export const PaymentAdyen = ({
  paymentSetting,
  adyenPaymentMethod,
  onOutstandingBalance,
  outstandingBalance,
  orderId,
  selectedPaymentSetting,
  ...props
}: AdyenPaymentMethodProps) => {
  const { i18n, t } = useTranslation('checkout')
  const config = getCountryConfig()
  const basketTotal = useBasketTotal()
  const { getGoogleID } = analytics()
  const { isLoggedIn } = useCustomer()
  const customerCheckoutDetails = useCustomerCheckoutDetails()
  const { reportPaymentCompleted, reportPaymentFailed, reportNextAction, reportOutstandingBalance } = useReport()
  const { isBasketValid } = useBasketValidation(t)
  const { adyenAmount, displayAmount } = usePaymentAmount(true, outstandingBalance?.orderOutstandingBalance)
  const orderPaymentId = useSelector((state: RootReducer) => state.currentOrderDetailsReducer.orderPaymentId)
  const { featureEnabled } = useFeatures()

  const [adyenCheckout, setAdyenCheckout] = useState<Core>()
  const [adyenComponentState, setAdyenComponentState] = useState<AdyenState>()
  const [brand, setBrand] = useState('')
  const [binValue, setBinValue] = useState('')
  const [hidePaymentButton, setHidePaymentButton] = useState(true)
  const [paymentNextStep, setPaymentNextStep] = useState<NextStep>()
  const [additionalTransactionTokenFields, setAdditionalTransactionTokenFields] = useState<{ [key: string]: string }>(
    {},
  )
  const [properties, setProperties] = useState<{ key: string; value: string }[] | undefined>()
  const [isMethodAvailable, setIsMethodAvailable] = useState(false)
  const [triggerAutoPlaceOrder, setTriggerAutoPlaceOrder] = useState(false)
  const [adyenComponentMounted, setAdyenComponentMounted] = useState(false)
  const adyenInstance = useRef<UIElement>()
  const returnUrl = useRef<ReturnUrl>({ standard: '', additionalDetails: '' })

  const [savePaymentEnabled] = featureEnabled('SavePaymentAdyenOnCheckout')

  const methodId = `${paymentSetting.paymentMethod}-${adyenPaymentMethod.type}`
  const id = `adyen-${methodId}-component-container`
  const isSelected = selectedPaymentSetting?.methodId === methodId

  const startNativePayment = useAdyenNativePayment({
    setAdyenComponentState,
    setTriggerAutoPlaceOrder,
    adyenPaymentMethod,
    adyenCheckout,
    isMethodAvailable,
    isSelected,
    adyenAmount,
  })

  const onPaymentNextStep = (nextStep?: NextStep) => {
    setPaymentNextStep(nextStep)
  }

  const handleAdditionalTransactionTokenFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalTransactionTokenFields({ ...additionalTransactionTokenFields, [target.name]: target.value || '' })
  }

  useEffect(() => {
    returnUrl.current = {
      standard: getAdyenReturnUrl(adyenPaymentMethod.type, orderId!, orderPaymentId, false, getGoogleID()),
      additionalDetails: getAdyenReturnUrl(adyenPaymentMethod.type, orderId!, orderPaymentId, true),
    }
  }, [adyenPaymentMethod.type, orderId, orderPaymentId])

  const handlePaymentCompleted = (result?: { resultCode: string }) => {
    reportPaymentCompleted(result?.resultCode || JSON.stringify(result), 'handlePaymentCompleted')
    window.location.href = returnUrl.current.standard
  }

  const handlePaymentFailed = (error?: Omit<AdyenCheckoutError, 'stack'>) => {
    reportPaymentFailed(JSON.stringify({ error: { name: error?.name, message: error?.message, cause: error?.cause } }))
  }

  const handleAdditionalDetails = (state: AdyenState) => {
    reportPaymentCompleted(undefined, 'handleAdditionalDetails')
    const url = new URL(returnUrl.current.additionalDetails)
    url.searchParams.append(HandledByAdditionalDetails, 'true')
    url.searchParams.append('payload', state.data.details!.payload)
    window.location.href = url.toString()
  }

  const handleInitiateOrderError = () => {
    if (adyenPaymentMethod.type === 'clicktopay') {
      adyenInstance.current?.remove()
      adyenInstance.current = adyenCheckout?.create(adyenPaymentMethod.type, {
        ...adyenCheckoutConfig,
        amount: {
          currency: config.CURRENCY_CODE,
          value: adyenAmount,
        },
      })
      adyenInstance.current?.mount(`#${id}`)
    }
  }

  const adyenConfigStateArray = useState<AdyenCheckoutConfig>({
    onPaymentCompleted: handlePaymentCompleted,
    onError: handlePaymentFailed,
  })
  let adyenCheckoutConfig = adyenConfigStateArray[0]
  const setAdyenCheckoutConfig = adyenConfigStateArray[1]

  const updateProperties = () => {
    setProperties([
      { key: 'brand', value: brand },
      { key: 'binValue', value: binValue },
      {
        key: 'browserInfo',
        value: JSON.stringify({
          userAgent: window.navigator.userAgent,
          acceptHeader: '*/*',
        }),
      },
      { key: 'origin', value: window.location.origin },
      { key: 'savePayment', value: (!!adyenComponentState?.data.storePaymentMethod).toString() },
    ])
  }

  useEffect(() => {
    updateProperties()
  }, [brand, binValue, adyenComponentState])

  useEffect(() => {
    let adyenConfig: AdyenCoreConfig = {
      paymentMethodsResponse: {
        paymentMethods: [adyenPaymentMethod],
      },
      environment: config.ADYEN_ENVIRONMENT,
      clientKey: config.ADYEN_CLIENT_KEY,
      onChange: (state: AdyenState) => {
        setAdyenComponentState(state)
      },
      onPaymentCompleted: handlePaymentCompleted,
      onError: handlePaymentFailed,
    }

    switch (adyenPaymentMethod.type) {
      case 'scheme':
      case 'bcmc':
        adyenConfig = {
          ...adyenConfig,
          locale: 'custom',
          translations: getCreditCardTranslations(t),
        }
        setAdyenCheckoutConfig({
          ...adyenCheckoutConfig,
          enableStoreDetails: isLoggedIn && savePaymentEnabled,
          hasHolderName: true,
          holderNameRequired: true,
          hideCVC: adyenPaymentMethod.type === 'bcmc',
          onBrand: (data: { brand: string }) => {
            setBrand(data.brand)
          },
          onBinValue: (data: { binValue: string }) => {
            setBinValue(data.binValue)
          },
        } as PaymentMethodOptions<'scheme'>)
        setHidePaymentButton(false)
        updateProperties()
        break
      case 'directEbanking':
      case 'ideal':
      case 'bcmc_mobile':
      case 'mobilepay':
      case 'paypal':
      case 'paynow':
      case 'duitnow':
      case 'grabpay_MY':
      case 'grabpay_SG':
      case 'afterpaytouch':
      case 'touchngo':
      case 'molpay_ebanking_fpx_MY':
        setHidePaymentButton(false)
        setAdyenComponentState({
          data: {
            paymentMethod: { type: adyenPaymentMethod.type },
          },
          isValid: true,
        })

        if (adyenPaymentMethod.type === 'duitnow') {
          adyenCheckoutConfig = {
            ...adyenCheckoutConfig,
            countdownTime: 5, // mins
          }
        }

        setAdyenCheckoutConfig({
          ...adyenCheckoutConfig,
          showPayButton: false,
          onAdditionalDetails:
            adyenPaymentMethod.type === 'bcmc_mobile' ||
            adyenPaymentMethod.type === 'paynow' ||
            adyenPaymentMethod.type === 'duitnow' ||
            adyenPaymentMethod.type === 'molpay_ebanking_fpx_MY'
              ? handleAdditionalDetails
              : undefined,
        })
        break
      case 'applepay':
      case 'paywithgoogle':
      case 'googlepay':
      case 'clicktopay':
        const tempAdyenCheckoutConfig = {
          ...adyenCheckoutConfig,
          countryCode: config.COUNTRY,
          onChange: (state: AdyenState) => {
            setAdyenComponentState(state)
            setTriggerAutoPlaceOrder(true)
          },
          onSubmit: (_: AdyenState) => {
            // This is needed to avoid Adyen component triggering the onError event
          },
          onClick: (resolve: () => void) => {
            if (isBasketValid()) {
              resolve()
            }
          },
        }

        if (adyenPaymentMethod.type === 'applepay') {
          setAdyenCheckoutConfig({
            ...tempAdyenCheckoutConfig,
            supportedNetworks: ['amex', 'masterCard', 'visa'],
          })
        } else if (['paywithgoogle', 'googlepay'].indexOf(adyenPaymentMethod.type) !== -1) {
          setAdyenCheckoutConfig({
            ...tempAdyenCheckoutConfig,
            buttonLocale: i18n.language.substring(0, 2),
          })
        } else if (adyenPaymentMethod.type === 'clicktopay') {
          setAdyenCheckoutConfig({
            ...tempAdyenCheckoutConfig,
            merchantDisplayName: t('ClickToPayDisplayName', {
              defaultValue: "Domino's Pizza Enterprises Ltd",
            }),
            shopperEmail: customerCheckoutDetails.emailAddress,
          })
        }
        break
      case 'giftcard':
        adyenConfig = {
          ...adyenConfig,
          locale: 'custom',
          translations: getGiftCardTranslations(t),
        }
        setHidePaymentButton(false)
        setAdyenCheckoutConfig({
          ...adyenCheckoutConfig,
          onChange: (state: AdyenState) => {
            setAdyenComponentState(state)
          },
        })
        break
    }

    AdyenCheckout(adyenConfig).then((instance) => setAdyenCheckout(instance))
  }, [paymentNextStep])

  useEffect(() => {
    if (adyenCheckout) {
      if (paymentNextStep) {
        if (paymentNextStep.nextStepType === 'NextStepAction') {
          const nextAction = JSON.parse(paymentNextStep.nextStepActionPayload)
          reportNextAction({
            ...nextAction,
            userAgent: window.navigator.userAgent,
          })
          adyenInstance.current = adyenCheckout.createFromAction(nextAction, adyenCheckoutConfig)
        }

        if (paymentNextStep.nextStepType === 'OutstandingBalance' && onOutstandingBalance) {
          reportOutstandingBalance(paymentNextStep.outstandingBalance)

          const outstandingBalance = {
            orderTotal: basketTotal!,
            processedPaymentName: paymentSetting.paymentMethod,
            orderOutstandingBalance: paymentNextStep.outstandingBalance,
          }

          onOutstandingBalance(outstandingBalance)
        }
      } else {
        adyenInstance.current = adyenCheckout.create(adyenPaymentMethod.type, adyenCheckoutConfig)
      }

      const shouldMount = async (): Promise<boolean | void> => {
        if (adyenPaymentMethod.type === 'applepay') {
          return (adyenInstance.current as ApplePay).isAvailable()
        }

        if (['paywithgoogle', 'googlepay'].indexOf(adyenPaymentMethod.type) !== -1) {
          return (adyenInstance.current as GooglePay).isAvailable()
        }

        if (adyenPaymentMethod.type === 'clicktopay') {
          return (adyenInstance.current as ClickPay).isAvailable()
        }

        return Promise.resolve(true)
      }

      shouldMount()
        .then(() => {
          setIsMethodAvailable(true)
        })
        .catch(() => {
          setIsMethodAvailable(false)
        })
    }
  }, [adyenCheckout, adyenCheckoutConfig, paymentNextStep])

  useEffect(() => {
    if (
      isMethodAvailable &&
      // always mount next step actions
      (paymentNextStep?.nextStepType === 'NextStepAction' ||
        // when not next step and not mounted, mount if auto mount is enabled or the method is selected
        (!paymentNextStep && !adyenComponentMounted && (shouldAutoMount(methodId) || isSelected)))
    ) {
      adyenInstance.current?.mount(`#${id}`)
      setAdyenComponentMounted(true)
    }
  }, [isMethodAvailable, paymentNextStep, isSelected])

  useEffect(() => {
    if (
      adyenComponentMounted &&
      ['applepay', 'paywithgoogle', 'googlepay', 'clicktopay'].indexOf(adyenPaymentMethod.type) !== -1
    ) {
      adyenInstance.current?.update({
        amount: {
          currency: config.CURRENCY_CODE,
          value: adyenAmount,
        },
      })
    }
  }, [adyenComponentMounted, adyenAmount])

  return isMethodAvailable ? (
    <PaymentMethod
      {...props}
      methodId={`${paymentSetting.paymentMethod}-${adyenPaymentMethod.type}`}
      paymentSetting={paymentSetting}
      selectedPaymentSetting={selectedPaymentSetting}
      invalid={!adyenComponentState?.isValid}
      hidePaymentButton={hidePaymentButton}
      transactionToken={JSON.stringify({
        ...adyenComponentState?.data.paymentMethod,
        ...additionalTransactionTokenFields,
      })}
      properties={properties}
      onPaymentNextStep={onPaymentNextStep}
      outstandingBalance={outstandingBalance}
      adyenPaymentMethodType={adyenPaymentMethod.type}
      adyenPaymentDefaultName={adyenPaymentMethod.name}
      shouldAutoPlaceOrder={triggerAutoPlaceOrder}
      paymentButtonTitle={t('Pay Now')}
      paymentButtonIncludeTotal={true}
      paymentButtonTotal={displayAmount}
      onInitiateOrderError={handleInitiateOrderError}
    >
      <AdyenAdditionalFields
        adyenPaymentMethod={adyenPaymentMethod}
        onChange={handleAdditionalTransactionTokenFieldChange}
      />
      <div onClick={startNativePayment || undefined}>
        <div id={id} data-testid={id} style={{ pointerEvents: startNativePayment ? 'none' : 'auto' }} />
      </div>
    </PaymentMethod>
  ) : null
}
