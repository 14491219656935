import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rootActions } from '@dominos/business'
import { getDefaultFeatureFlags, getFeatureFlags } from './feature-flag-helper'
import { FeatureFlagType } from '../feature-flag.interface'

export const useFeatureFlags = () => {
  const initFlags = useSelector((state: RootReducer) => state.featureReducer.featuresV2 ?? getDefaultFeatureFlags())
  const sdkFlags = useFlags()
  const dispatch = useDispatch()

  const flags = useMemo(() => getFeatureFlags(sdkFlags) ?? initFlags, [sdkFlags])

  useEffect(() => {
    // pass the sdk flags to redux would cause multiple flag evaluation events
    // this is the workaround to prevent that
    const reduxFlags = Object.entries(flags).reduce(
      (prev, flag) => ({
        ...prev,
        [flag[0] as keyof FeatureFlagType]: flag[1],
      }),
      {} as FeatureFlagType,
    )

    dispatch(rootActions.setFeatureTogglesV2(reduxFlags))
  }, [flags])

  return { flags }
}
