export const getCreditCardTranslations = (t: import('i18next').TFunction) => ({
  custom: {
    storeDetails: t('creditCard-storeDetails-saveForMyNextPayment', {
      defaultValue: 'Save for my next payment',
    }),
    'creditCard.holderName': t('creditCard-holderName', { defaultValue: 'Full Name on Credit Card' }),
    'creditCard.holderName.placeholder': t('creditCard-holderName-placeholder', {
      defaultValue: 'J. Smith',
    }),
    'creditCard.numberField.title': t('creditCard-numberField-title', {
      defaultValue: 'Credit Card Number',
    }),
    'creditCard.numberField.placeholder': t('creditCard-numberField-placeholder', {
      defaultValue: '1234 5678 9012 3456',
    }),
    'creditCard.numberField.invalid': t('creditCard-numberField-invalid', {
      defaultValue: 'Invalid card number',
    }),
    'creditCard.expiryDateField.title': t('creditCard-expiryDateField-title', {
      defaultValue: 'Expiry date',
    }),
    'creditCard.expiryDateField.placeholder': t('creditCard-expiryDateField-placeholder', {
      defaultValue: 'MM/YY',
    }),
    'creditCard.expiryDateField.invalid': t('creditCard-expiryDateField-invalid', {
      defaultValue: 'Invalid expiry date',
    }),
    'creditCard.cvcField.title': t('creditCard-cvcField-title', { defaultValue: 'CCV' }),
    'creditCard.cvcField.placeholder': t('creditCard-cvcField-placeholder', { defaultValue: '123' }),
    'creditCard.cvcField.placeholder.3digits': t('creditCard-cvcField-placeholder', { defaultValue: '123' }),
    'error.va.gen.01': t('error-va-gen-01', { defaultValue: 'Incomplete field' }),
    'error.va.gen.02': t('error-va-gen-02', { defaultValue: 'Field not valid' }),
    'error.va.sf-cc-num.01': t('error-va-sf-cc-num-01', { defaultValue: 'Invalid card number' }),
    'error.va.sf-cc-dat.01': t('error-va-sf-cc-dat-01', { defaultValue: 'Card too old' }),
    'error.va.sf-cc-dat.02': t('error-va-sf-cc-dat-02', { defaultValue: 'Date too far in the future' }),
  },
})

export const getGiftCardTranslations = (t: import('i18next').TFunction) => ({
  custom: {
    'giftCard.holderName.title': t('giftCard-holderName-title', { defaultValue: 'Card Holder Name' }),
    'giftCard.holderName.placeholder': t('giftCard-holderName-placeholder', { defaultValue: 'J. Smith' }),
    'giftCard.numberField.title': t('giftCard-numberField-title', { defaultValue: 'Card Number' }),
    'giftCard.numberField.placeholder': t('giftCard-numberField-placeholder', {
      defaultValue: '1234 5678 9012 3456',
    }),
    'giftCard.pin.title': t('giftCard-pin-title', { defaultValue: 'Pin' }),
    'giftCard.pin.placeholder': t('giftCard-pin-placeholder', { defaultValue: '123' }),
  },
})
