import React from 'react'
import { TFunction } from 'react-i18next'
import { ProductBuildYourOwnView } from './product-build-your-own-view'
import { createProductBuildYourOwnViewModel } from './create-product-build-your-own-view-model'

interface ProductBuildYourOwnProps {
  testID: string
  t: TFunction<'menu'>
  color: string
  onClick: () => void
}

const ProductBuildYourOwn: React.FC<ProductBuildYourOwnProps> = (props) => (
  <ProductBuildYourOwnView
    viewModel={createProductBuildYourOwnViewModel({ ...props })}
    onClick={props.onClick}
    testID={props.testID}
  ></ProductBuildYourOwnView>
)

export default ProductBuildYourOwn
