import { createConfig } from '../epics'
import { sendFosEventMutation } from '../../queries'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'
import { graphQLMutation } from '@dominos/apollo'

export const createSendGraphQLMutation =
  (rootReducer: RootReducer, securitySender: SecuritySender) => async (fosEvent: Bff.Fos.FosEventRequest) => {
    const payload: MutationWrapper<Bff.Fos.FosEventRequest> = {
      input: fosEvent,
    }
    const config = await createConfig(rootReducer, payload)

    if (!config) {
      throw Error('Can not create graphql configuration')
    }

    graphQLMutation(sendFosEventMutation, config as GraphConfig, securitySender).subscribe()
  }
