import { MDTargetBlankAnchor } from '@dominos/components'
import { useFeatures, useMenu } from '@dominos/hooks-and-hocs'
import Markdown from 'markdown-to-jsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './footer.less'

export const TermsConditionsDisclaimers = ({ testID }: { testID: string }) => {
  const { t } = useTranslation('footer')
  const { featureEnabled } = useFeatures()
  const { disclaimers, termsAndConditions } = useMenu()
  const [termsHeaderEnabled, termsBodyEnabled, surchargeDisclaimerEnabled, kjInfoEnabled] = featureEnabled(
    'footer-terms-header',
    'footer-terms',
    'footer-surcharge',
    'footer-kj',
  )

  const termsHeader =
    termsHeaderEnabled && termsAndConditions
      ? t('TermsAndConditionsHeader', { defaultValue: '# Terms & Conditions' }).trim()
      : ''
  const termsBody =
    termsBodyEnabled && termsAndConditions ? t('TermsAndConditionsBody', { defaultValue: '' }).trim() : ''
  const disclaimer = disclaimers && disclaimers[0]
  const surchargeDisclaimer =
    surchargeDisclaimerEnabled && disclaimer ? t('SurchargeDisclaimer', { defaultValue: '' }).trim() : ''
  const kjInfo = kjInfoEnabled && disclaimer ? t('kjInfo', { defaultValue: '' }).trim() : ''

  return (
    <>
      <div data-testid={`${testID}.terms`} className={css.terms}>
        {termsHeader && (
          <Markdown options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}>{termsHeader}</Markdown>
        )}
        {surchargeDisclaimer && (
          <Markdown options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}>
            {surchargeDisclaimer}
          </Markdown>
        )}
        {termsBody && (
          <Markdown options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}>{termsBody}</Markdown>
        )}
      </div>
      {kjInfo ? (
        <>
          <span className={css.divider} />
          <div data-testid={`${testID}.disclaimers`} className={css.kJ}>
            <p>{kjInfo}</p>
            <span className={css.divider} />
          </div>
        </>
      ) : null}
    </>
  )
}
