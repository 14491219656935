import { rootActions } from '@dominos/business'
import { countFromBasketLines } from '@dominos/business/functions/basket'
import { InlineSingleItemOffer, OrderButton } from '@dominos/components'
import {
  useBasket,
  useBasketValidation,
  useCountryCode,
  useCurrentOrderDetails,
  useFeatures,
  useNativeAppStoredData,
  useOneTrust,
  useTermsAndConditionsInitialState,
} from '@dominos/hooks-and-hocs'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AddressMakeUpContainer } from '@dominos/components'
import { convertToStoredCustomerAddress, getOriginalAddress } from '@dominos/business/functions/address'

import { DeliveryInstructions } from './delivery-instructions'
import { CustomerOrderDetails } from './details-container'
import { FormValue } from './details-container.interface'
import styles from './details-container.less'
import { mapEClub } from './helpers'
import { DeliveryType } from './service-method-type'
import { TermsAndConditions } from './terms-and-conditions'
import { AdditionalAddressDetailsUpdate, DeliveryAddressAdditionalFieldsContainer } from '@dominos/components/address'
import { mapAddressComponentToStoredCustomerAddressUpdate } from '@dominos/business/functions/address'
import { getNativeFeatures } from '@dominos/business/functions/native-app'

interface DeliveryDetailsFormProps {
  customerOrderDetails: CustomerOrderDetails
  isMyDetailsValid: boolean
  eClub: { [key: string]: boolean }
  testID: string
  onComplete: () => void
}

/* eslint-disable max-lines-per-function */
const DeliveryDetailsForm = ({
  customerOrderDetails,
  isMyDetailsValid,
  eClub,
  testID,
  onComplete,
}: DeliveryDetailsFormProps) => {
  const dispatch = useDispatch()
  const { t: checkoutTns } = useTranslation('checkout')
  const { t: deliveryAddressTns } = useTranslation('delivery-address')
  const consents = useOneTrust()
  const { featureEnabled } = useFeatures()
  const nativeAppAutoCompleteDeliveryAddress = getNativeFeatures('AutoCompleteDeliveryAddress')
  const nativeAppAutoCompleteDisplayAdditionalFieldsCheckout = getNativeFeatures(
    'AutoCompleteDeliveryAddressFieldsDisplayCheckout',
  )

  const nativeAppAutoCompleteDeliveryAddressFieldsHidden = getNativeFeatures('AutoCompleteDeliveryAddressFieldsHidden')

  const [
    checkoutOffersEnabled,
    webAutoCompleteDeliveryEnabled,
    webAutoCompleteDisplayAdditionalFieldsCheckout,
    webAutoCompleteDeliveryAddressFieldsHidden,
  ] = featureEnabled(
    'CheckoutMyDetailsOffers',
    'AutoCompleteDeliveryAddress',
    'AutoCompleteDeliveryAddressFieldsDisplayCheckout',
    'AutoCompleteDeliveryAddressFieldsHidden',
  )
  const autoCompleteDeliveryAddressFieldsHidden =
    webAutoCompleteDeliveryAddressFieldsHidden || nativeAppAutoCompleteDeliveryAddressFieldsHidden
  const autoCompleteDeliveryEnabled = webAutoCompleteDeliveryEnabled || nativeAppAutoCompleteDeliveryAddress
  const autoCompleteDisplayAdditionalFieldsCheckout =
    webAutoCompleteDisplayAdditionalFieldsCheckout || nativeAppAutoCompleteDisplayAdditionalFieldsCheckout
  const countryCode = useCountryCode()
  const { basket: basketData, error: basketError, pending: basketLoading } = useBasket()
  const { basketHeaderData: currentOrderData, selectedDeliveryAddress } = useCurrentOrderDetails()
  const { isBasketValid } = useBasketValidation(checkoutTns)
  const { storeNativeAppCustomerDetails } = useNativeAppStoredData()

  const shouldDisplayAdditionalAddressFieldsAutoComplete = useMemo(
    () =>
      autoCompleteDeliveryEnabled &&
      autoCompleteDisplayAdditionalFieldsCheckout &&
      !autoCompleteDeliveryAddressFieldsHidden,
    [autoCompleteDeliveryEnabled, autoCompleteDisplayAdditionalFieldsCheckout, autoCompleteDeliveryAddressFieldsHidden],
  )

  const shouldSaveExtraAddress = useMemo(
    () => countryCode == 'JP' && !autoCompleteDeliveryEnabled,
    [autoCompleteDeliveryEnabled, countryCode],
  )

  const [deliveryInstructions, setDeliveryInstructions] = useState<string>('')
  const [serviceMethodSubType, setServiceMethodSubType] = useState<Bff.Stores.ServiceMethodSubTypes | undefined>(
    currentOrderData?.serviceMethodSubType ?? undefined,
  )

  const [extraAddress, setExtraAddress] = useState<{ [key: string]: FormValue }>({
    address: selectedDeliveryAddress?.customerAddress.streetNo || undefined,
    buildingName: selectedDeliveryAddress?.customerAddress.buildingName || undefined,
  })

  const [additionalAddressComponents, setAdditionalAddressComponents] = useState<
    AdditionalAddressDetailsUpdate | undefined
  >()

  const [termsValid, handleTermsAndConditionsChange] = useTermsAndConditionsInitialState()

  const itemCount = useMemo(() => countFromBasketLines(basketData.lines), [basketData.lines])

  const additionalAddressDetailsCorrect = useMemo(() => {
    if (autoCompleteDeliveryEnabled)
      return shouldDisplayAdditionalAddressFieldsAutoComplete ? additionalAddressComponents?.isValid ?? false : true

    return shouldSaveExtraAddress ? !!extraAddress.address : true
  }, [
    autoCompleteDeliveryEnabled,
    additionalAddressComponents,
    shouldSaveExtraAddress,
    extraAddress.address,
    shouldDisplayAdditionalAddressFieldsAutoComplete,
  ])

  const shouldOrderButtonDisabled = useMemo(
    () => !!basketError || !basketData || !isMyDetailsValid || !termsValid || !additionalAddressDetailsCorrect,
    [basketError, basketData, isMyDetailsValid, termsValid, additionalAddressDetailsCorrect],
  )

  const handleAddressChange = (field: string | null, value: FormValue) => {
    setExtraAddress((prev) => ({ ...prev, [field!]: value }))
  }

  const additionalAddressFieldsChange = (additionalAddressDetailsUpdate: AdditionalAddressDetailsUpdate) => {
    setAdditionalAddressComponents(additionalAddressDetailsUpdate)
  }

  const saveExtraAddress = () => {
    const { address, buildingName } = extraAddress

    if (address || buildingName) {
      const parsedAddress = convertToStoredCustomerAddress({
        storeNo: selectedDeliveryAddress!.storeNo!,
        rawAddress: getOriginalAddress(selectedDeliveryAddress!),
        address: address as string,
        buildingName: buildingName as string,
        name: selectedDeliveryAddress?.displayAddress,
      })

      parsedAddress.locale = selectedDeliveryAddress?.locale

      dispatch(rootActions.addCustomerAddressToOrder(parsedAddress))
      dispatch(rootActions.saveCustomerAddress(parsedAddress))
    }
  }

  const saveCustomerAddressAutoComplete = () => {
    if (selectedDeliveryAddress && countryCode) {
      const updatedCustomerAddress = mapAddressComponentToStoredCustomerAddressUpdate(
        deliveryAddressTns,
        selectedDeliveryAddress,
        additionalAddressComponents?.additionalAddressComponents ?? [],
        countryCode,
      )

      dispatch(rootActions.addCustomerAddressToOrder(updatedCustomerAddress.address))

      if (consents.Functional) dispatch(rootActions.saveCustomerAddress(updatedCustomerAddress.address))
    }
  }

  const placeOrder = () => {
    if (autoCompleteDeliveryEnabled) saveCustomerAddressAutoComplete()
    else if (shouldSaveExtraAddress) saveExtraAddress()

    dispatch(rootActions.selectServiceMethodSubType(serviceMethodSubType))

    const customerDetails = {
      ...customerOrderDetails,
      deliveryInstructions,
      eclub: mapEClub(eClub),
    }
    dispatch(rootActions.saveCustomerDetails(customerDetails))
    storeNativeAppCustomerDetails(customerDetails)
    onComplete()
  }

  const onPlaceOrderPress = () => {
    if (isBasketValid()) {
      placeOrder()
    }
  }

  return (
    <div>
      {!autoCompleteDeliveryAddressFieldsHidden && shouldDisplayAdditionalAddressFieldsAutoComplete && (
        <DeliveryAddressAdditionalFieldsContainer
          testID={`${testID}.delivery-address-additional-fields`}
          customerAddress={selectedDeliveryAddress?.customerAddress}
          onChange={additionalAddressFieldsChange}
        />
      )}
      {shouldSaveExtraAddress && (
        <AddressMakeUpContainer
          addressDetails={{
            address: extraAddress.address as string,
            buildingName: extraAddress.buildingName as string,
          }}
          onChange={handleAddressChange}
        />
      )}
      {checkoutOffersEnabled && (
        <InlineSingleItemOffer
          testID={`${testID}.my-details-offers`}
          filterLocation={{
            locationType: 'Page',
            locationCode: 'PaymentSelection',
            showBanner: true,
          }}
        />
      )}

      <DeliveryType checkedOption={serviceMethodSubType} onChecked={setServiceMethodSubType} />
      <DeliveryInstructions testID={`${testID}.delivery-instructions`} onChange={setDeliveryInstructions} />
      <TermsAndConditions
        testID={`${testID}.terms-and-conditions`}
        onTermsChange={handleTermsAndConditionsChange}
        isValid={termsValid}
      />
      <div className={styles.orderButtonContainer}>
        <OrderButton
          testID={`${testID}.place-order.button`}
          itemCount={itemCount}
          price={basketData.total || 0}
          onPress={onPlaceOrderPress}
          disabled={shouldOrderButtonDisabled}
          text={checkoutTns('Place Order')}
          fill
          loading={basketLoading}
        />
      </div>
    </div>
  )
}

export { DeliveryDetailsForm }
