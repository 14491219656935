export const FORGEROCK_CONSTANT = {
  SCOPE: 'openid profile me.read',
  REALM_PATH: 'alpha',
  INDEX_TYPE: 'service',
  CLIENT_ID: 'NextGenSDKPublicClient',
  CALLBACK_PATH: '/callback',
  TIMEOUT_MILLISECONDS: 1000,
  JOURNEYS: {
    ACTION_VALUE_LOGIN: 'login',
    ACTION_VALUE_REGISTER: 'register',
    UPDATE_PASSWORD_JOURNEY: 'DPE-UpdatePassword-Global',
    DELETE_ACCOUNT_JOURNEY: 'DPE-DeleteUser-Global',
  },
} as const

export type ForgerockJourney = typeof FORGEROCK_CONSTANT.JOURNEYS[keyof typeof FORGEROCK_CONSTANT.JOURNEYS]

export type ForgerockQueryParams = {
  action: ForgerockJourney
  market: string
  locale: string
  application: BffContext.ApplicationTypes
}

export type TokenOptions = JourneyTokenOptions | RedirectTokenOptions | RetrieveTokensOptions

export type JourneyTokenOptions = {
  login?: 'redirect' | 'embedded'
  query?: ForgerockQueryParams
  forceRenew?: boolean
}
export type RedirectTokenOptions = {
  login: 'redirect'
}
export type RetrieveTokensOptions = {
  query: { code: string; state: string }
}
