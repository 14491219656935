import React from 'react'
import { useFeatures } from '@dominos/hooks-and-hocs'
import classnames from 'classnames'

import css from './styles/column-layout.less'

export const MenuColumnLayout = (props: ColumnLayoutProps) => {
  const { testID, children } = props
  const { featureEnabled } = useFeatures()
  const [leftNavMenuEnabled] = featureEnabled('LeftNavMenu')

  return (
    <div data-testid={`${testID}.wrapper`} className={css.withoutSidebarWrapper}>
      <div
        data-testid={`${testID}.grid`}
        className={classnames(css.withoutSidebar, leftNavMenuEnabled && css.leftNavMenu)}
      >
        {children}
      </div>
    </div>
  )
}
