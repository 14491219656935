/* eslint-disable max-lines */
import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rootActions } from '@dominos/business'
import { AddressSearchErrors, AddressSearchResults, GenericCard, ValidationTextField } from '@dominos/components'
import {
  GoogleAutoComplete,
  useAddressSearchDetails,
  useAddressSearchQueries,
  useAddressSearchValidation,
  useAutoComplete,
  useErrorCode,
  useFeatures,
  useGeocoder,
  useGeoLocation,
  useReport,
  useStoreSelect,
  useStoresPreview,
} from '@dominos/hooks-and-hocs'

import { PickupStoreSearchResults } from '../pickup-store-search-results'
import { CurrentLocationDisplayField } from '@dominos/scenes/auto-complete-delivery-scene/current-location-display-field/current-location-display-field'

import css from './pickup-address-search.less'

import { PickupStoresPreview } from '../pickup-stores-preview'

const PICKUP_SERVICE_METHOD = 'Pickup'

export interface AddressSearchProps extends BaseProps {
  title: ComponentProps<typeof GenericCard>['title']
  placeholder: ComponentProps<typeof ValidationTextField>['placeholder']
  onCompletePickup?: () => void
}

// eslint-disable-next-line max-lines-per-function
export const PickupAddressSearch = ({ testID, title, placeholder, onCompletePickup }: AddressSearchProps) => {
  const dispatch = useDispatch()
  const searchAddressInput = useSelector((state: RootReducer) => state.customerReducer.searchAddressInput)
  const searchPickupStoreLocation = useSelector((state: RootReducer) => state.customerReducer.searchPickupStoreLocation)

  const [selectedPickupStoreLocation, setSelectedPickupStoreLocation] = useState<StoreGeo | undefined>()
  const [selectedAddress, setSelectedAddress] = useState<AddressLine | undefined>()
  const { reportStoreSelected } = useReport()
  const { getStore, store } = useStoreSelect()
  const setStore = (store: Bff.Stores.Store) => dispatch(rootActions.storeSelected(store, true))

  const [displayCurrentLocation, setDisplayCurrentLocation] = useState<boolean>()

  const { featureEnabled } = useFeatures()
  const [autoCompleteCurrentLocation] = featureEnabled('AutoCompleteCurrentLocation')
  const { location, permitted, getLocation, requestLocationPermission } = useGeoLocation()
  const { addressLine: addressFromGeocoder, reverseGeocodeLocation } = useGeocoder()

  const storesPreview = useStoresPreview({ useAddressSearchQueriesHook: useAddressSearchQueries })

  useEffect(() => {
    if (!autoCompleteCurrentLocation) return

    const shareLocation = async () => {
      await requestLocationPermission()
      await getLocation()
    }

    shareLocation()
  }, [])

  useEffect(() => {
    if (!location) return

    reverseGeocodeLocation(location)
    storesPreview.requestNearByStores(location)
  }, [location, permitted])

  useEffect(() => {
    if (!addressFromGeocoder) return

    enableCurrentLocation()
  }, [addressFromGeocoder])

  const enableCurrentLocation = () => {
    setDisplayCurrentLocation(true)
  }

  const disableCurrentLocation = () => {
    setDisplayCurrentLocation(false)
    resetStoreDetails()
    setSelectedAddress(undefined)
  }

  const {
    getPredictions,
    predictions,
    getAddress,
    address,
    isLoading: isLoadingAutoComplete,
    input,
    error: autoCompleteError,
  } = useAutoComplete(GoogleAutoComplete, PICKUP_SERVICE_METHOD)
  const { validationRules } = useAddressSearchValidation()
  const {
    fetchPickupStoreDetails,
    pickupStoreDetails,
    reset: resetStoreDetails,
    isLoading: isLoadingStoreDetails,
    error: storeDetailsServerError,
  } = useAddressSearchDetails(PICKUP_SERVICE_METHOD)

  const errorCode = useErrorCode({
    type: PICKUP_SERVICE_METHOD,
    storeDetailsServerError,
    predictions,
    input,
    autoCompleteError,
    searchIsCompleted: false,
  })

  useEffect(() => {
    dispatch(rootActions.resetCurrentStore())
    dispatch(rootActions.resetAutoCompleteScenario())
    if (searchPickupStoreLocation) {
      const { longitude, latitude } = searchPickupStoreLocation
      const addressLocation = { geo: { longitude, latitude } } as DeliveryAddressRequest
      fetchPickupStoreDetails(addressLocation)
      setSelectedPickupStoreLocation(addressLocation.geo)
    }
  }, [])

  useEffect(() => {
    fetchPickupStoreDetails(address)
  }, [address])

  useEffect(() => {
    if (store && onCompletePickup) {
      dispatch(rootActions.saveSearchState(input, address?.geo ?? selectedPickupStoreLocation))
      setStore(store)
      reportStoreSelected(store)
      onCompletePickup()
    }
  }, [store])

  const onPickupSearchStoreSelectedAddress = (address: AddressLine) => {
    const pickupStoreDetails = address.additionalData as PickupStoreDetails
    const locationId = pickupStoreDetails?.locations[0].locationId
    getStore(pickupStoreDetails?.storeNo ?? 0, null, locationId)
    dispatch(rootActions.savePickupStoreLocationId(locationId))
    dispatch(rootActions.selectServiceMethod(PICKUP_SERVICE_METHOD))
  }

  const onPickupSearchPreviewStoreSelected = (id: string) => {
    const numberId = parseInt(id)

    if (!storesPreview.nearByStores) return

    const store = storesPreview.nearByStores.find((store) => store.uid === numberId)

    if (!store) return

    onPickupSearchStoreSelectedAddress(store)
  }

  const onChange = async (updatedField: { [key: string]: ValidationField }) => {
    const key = Object.keys(updatedField)[0]
    const newSearch = updatedField[key]

    resetStoreDetails()
    setSelectedAddress(undefined)

    await getPredictions(newSearch.value, newSearch.isValid)
  }

  const onSelectAddress = async (address: AddressLine) => {
    setSelectedAddress(address)
    await getAddress(address, false)
  }

  const onBackToAutoCompleteSearch = () => {
    resetStoreDetails()
    setSelectedAddress(undefined)
  }

  const retrySelectingAddress = useCallback(
    () => selectedAddress && onSelectAddress(selectedAddress),
    [selectedAddress],
  )

  const isLoading = useMemo(
    () => isLoadingAutoComplete || isLoadingStoreDetails,
    [isLoadingAutoComplete, isLoadingStoreDetails],
  )

  const shouldDisplayPickupSearchStore = !!pickupStoreDetails

  return (
    <div data-testid={testID} className={css.wrapper}>
      <GenericCard testID={testID} title={title}>
        {displayCurrentLocation && !input ? (
          <CurrentLocationDisplayField
            location={addressFromGeocoder?.address}
            testID={testID}
            onClearInput={disableCurrentLocation}
          />
        ) : (
          <ValidationTextField
            testID={testID}
            fieldName={'addressSearch'}
            placeholder={placeholder}
            hasClearInputButton
            hideEmptyErrorContainer
            onChange={onChange}
            validationRules={validationRules}
            initialValue={searchAddressInput}
          />
        )}

        {!shouldDisplayPickupSearchStore && (
          <>
            <AddressSearchResults
              testID={`${testID}.searchResults`}
              type={PICKUP_SERVICE_METHOD}
              searchValue={input}
              addresses={predictions}
              isLoading={isLoading}
              itemLoadingKey={selectedAddress?.uid}
              hasError={!!errorCode}
              onSelectAddress={onSelectAddress}
            />

            <AddressSearchErrors
              testID={`${testID}.errors`}
              errorCode={errorCode}
              isLoading={isLoading}
              serviceMethod={PICKUP_SERVICE_METHOD}
              onRetry={retrySelectingAddress}
            />
          </>
        )}
      </GenericCard>

      {!input && (
        <PickupStoresPreview
          testID={`${testID}.pickupStoresPreview`}
          isLoading={isLoading}
          addresses={storesPreview.nearByStores}
          itemLoadingKey={selectedAddress?.uid}
          onSelectStore={onPickupSearchPreviewStoreSelected}
        />
      )}

      {shouldDisplayPickupSearchStore && (
        <PickupStoreSearchResults
          testID={`${testID}.pickupStoreSearchResults`}
          isLoading={isLoading}
          addresses={pickupStoreDetails}
          itemLoadingKey={selectedAddress?.uid}
          onSelectAddress={onPickupSearchStoreSelectedAddress}
          onRetry={onBackToAutoCompleteSearch}
        />
      )}
    </div>
  )
}
