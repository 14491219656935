import { BasketLineData, createBasketLine, getDefaultSizeAndEnergy } from '@dominos/business/functions/basket'
import {
  CustomisationChanges,
  getCustomisationChanges,
  getExtraSurcharge,
  getImages,
  getSelectedProductSize,
} from '@dominos/business/functions/menu'
import { formatSuperscript } from '@dominos/business/functions/text/format-superscript'
import { isPortionMenuItem, Legends } from '@dominos/components'
import { ProductPricingDetails } from '@dominos/components/product/product-pricing-details'
import { useFeatures, usePricing } from '@dominos/hooks-and-hocs'
import classnames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMenuStyles } from '../menu-card.styles'
import { MenuCustomisation } from './menu-customisation'
import { MenuCardProps } from './menu-product-card.interfaces'
import css from './menu-product-card.less'
import { useProductMenuItemCustomisations } from './use-product-menu-item-customisations'

type SwapSetProduct = Bff.Menu.old.SwapSetProduct
type SwapSetProductSize = Bff.Menu.old.SwapSetProductSize

const IGNORE = ['kJ.Pizza', 'kJ.Standard']

const getMenuItemCustomisationChanges = (
  menuItem: ProductMenuItem | SwapSetProduct | PortionMenuItem,
  currentCustomisations: MenuCustomisationItem[],
) => {
  const productSize = getSelectedProductSize(menuItem, currentCustomisations)
  const size = {
    ...productSize,
    code: menuItem.defaultSize === productSize?.code ? undefined : productSize?.code,
  } as ProductSize

  return getCustomisationChanges(currentCustomisations, size)
}

const getBasketLineData = (
  menuItem: ProductMenuItem | SwapSetProduct | PortionMenuItem,
  customisationChanges: CustomisationChanges,
) =>
  ({
    item: menuItem,
    sauce: customisationChanges.sauce,
    base: customisationChanges.base,
    sizeCode: customisationChanges.sizeCode,
    options: customisationChanges.options,
  } as BasketLineData)

export const MenuProductCard = (props: MenuCardProps) => {
  const { testID, item: menuItem, children, onClick, addToBasket, isFiltered = false, getBaseProductPrice } = props
  const { featureEnabled } = useFeatures()
  const styles = useMenuStyles(!!children)
  const { t } = useTranslation('menu')
  const [
    editEnabled,
    basesEnabled,
    vpimUpgradeButtonEnabled,
    showQtyPicker,
    vspUpgradeButtonEnabled,
    leftNavMenuEnabled,
  ] = featureEnabled(
    'menu-card-edit-button',
    'menu-card-bases',
    'VoucherPricingInMenuUpgradeButton',
    'ShowQuantityPickerOnMenuCard',
    'VoucherSoftPinningMenuUpgradeButton',
    'LeftNavMenu',
  )

  const {
    setSize,
    setBase,
    setOptions,
    setQuantity,
    resetCurrentCustomisationsToDefault,
    currentCustomisations,
    currentAvailableCustomisations,
  } = useProductMenuItemCustomisations(menuItem, { basesEnabled })
  const imageUrl = getImages(menuItem).menuImage.fullUrl
  const kj = getDefaultSizeAndEnergy(menuItem).energyKJ
  const { formattedPrice, formattedSalePrice, formattedPromoPrice, getPrice } = usePricing()

  const legends: MenuLegend[] =
    'legends' in menuItem ? (menuItem.legends || []).filter((items) => !IGNORE.includes(items.code)) : []

  const customisationChanges = useMemo(() => {
    if (currentCustomisations) {
      return getMenuItemCustomisationChanges(menuItem, currentCustomisations)
    }
  }, [JSON.stringify(currentCustomisations)])

  const extraSurcharge = useMemo(() => {
    if (!isPortionMenuItem(menuItem)) {
      const selectedSize = getSelectedProductSize(menuItem, currentCustomisations) as SwapSetProductSize

      return getExtraSurcharge(getBaseProductPrice, menuItem, selectedSize)
    }
  }, [JSON.stringify(currentCustomisations)])

  useEffect(() => {
    if (customisationChanges) {
      getPrice(getBasketLineData(menuItem, customisationChanges))
    }
  }, [customisationChanges])

  const onAddToBasket = (
    product: ProductMenuItem,
    productCustomisation: MenuCustomisationItem[],
    extraSurcharge?: number,
  ) => {
    addToBasket?.(product, productCustomisation, extraSurcharge)
    resetCurrentCustomisationsToDefault?.()
  }

  const onProductClick = () => {
    if (onClick) {
      let basketLine: BasketLine | undefined
      if (customisationChanges && !isPortionMenuItem(menuItem)) {
        basketLine =
          createBasketLine(customisationChanges.quantity, getBasketLineData(menuItem, customisationChanges)) ||
          undefined
      }
      onClick(menuItem, basketLine)
    }
    resetCurrentCustomisationsToDefault?.()
  }

  const onClickHandler = menuItem.features?.inMenuCustomisation
    ? () => {
        if (currentCustomisations && menuItem && !isPortionMenuItem(menuItem)) {
          onAddToBasket(menuItem, currentCustomisations, extraSurcharge)
        }
      }
    : onProductClick

  const showPrice = !!formattedPrice && !(isFiltered && vpimUpgradeButtonEnabled)

  return (
    <div
      data-testid={testID}
      className={classnames(css.card, leftNavMenuEnabled && css.leftNavMenu)}
      style={styles.card}
      role='button'
      onClick={onProductClick}
    >
      <div>
        {editEnabled && (
          <button data-testid={`${testID}.button`} className={css.editButton} aria-label={t('Edit')}>
            {t('Edit')}
          </button>
        )}
        <div className={css.imageContainer}>
          <Legends
            testID={'MenuLegend'}
            containerStyle={{
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'absolute',
              right: 2,
              bottom: 2,
            }}
            imageStyle={{ width: 22 }}
            legends={legends}
          />
          <img data-testid={`${testID}.image`} className={css.image} style={styles.image} src={imageUrl} alt='' />
        </div>
        <div className={css.cardContent} style={styles.cardContent}>
          <p data-testid={`${testID}.title`} className={css.title}>
            {(menuItem.media && menuItem.media.name) || ''}
          </p>
          <div data-testid={`${testID}.subtitle`} className={css.subtitle}>
            <ProductPricingDetails
              testID={`${testID}.subtitle`}
              kj={kj}
              price={formattedPrice}
              salePrice={formattedSalePrice}
              promoPrice={formattedPromoPrice}
              showPrice={showPrice}
              showEnergyDividerForPromo={false}
            />
          </div>
          <p data-testid={`${testID}.description`} className={css.description}>
            {menuItem.media?.description ? formatSuperscript(menuItem.media.description) : ''}
          </p>
        </div>
      </div>
      <div data-testid={`${testID}.children`} className={css.selectors}>
        <MenuCustomisation
          testID={`${menuItem.code}.customisation`}
          inMenuCustomisation={menuItem.features?.inMenuCustomisation}
          options={currentAvailableCustomisations}
          onClick={onClickHandler}
          menuCustomisationValues={currentCustomisations}
          setSize={setSize}
          setBase={setBase}
          setOptions={setOptions}
          setQuantity={setQuantity}
          extraSurcharge={extraSurcharge}
          showAddButton={!isFiltered || (!vpimUpgradeButtonEnabled && !vspUpgradeButtonEnabled)}
          hideQuantity={isFiltered && !showQtyPicker}
        />
      </div>
    </div>
  )
}
