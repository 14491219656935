import { isNativeApp } from '@dominos/business/functions/native-app'
import { NavigationConstants } from '@dominos/navigation'

export const getAdyenReturnUrl = (
  paymentMethodType: string,
  orderId: string,
  orderPaymentId: string,
  excludeMobileDeepLinks: boolean = false,
  googleId?: string,
) => {
  const url = new URL(`${NavigationConstants.checkoutProcessing}/${orderId}/${orderPaymentId}`, window.location.origin)

  if (googleId) {
    url.searchParams.set('ga_redirect_id', googleId)
  }

  if (isNativeApp()) {
    url.pathname = `${NavigationConstants.nativeAppCheckoutProcessing}/${orderId}/${orderPaymentId}`

    if (
      !excludeMobileDeepLinks &&
      [
        'bcmc_mobile',
        'ideal',
        'directEbanking',
        'mobilepay',
        'paynow',
        'grabpay_MY',
        'touchngo',
        'duitnow',
        'grabpay_SG',
        'afterpaytouch',
        'fpx',
      ].includes(paymentMethodType)
    ) {
      const searchParams = url.searchParams.toString()

      return `dominos://payment${url.pathname}${searchParams && '?'}${searchParams}`
    }
  }

  return url.toString()
}
