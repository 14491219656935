import { applicationReducer } from './application.reducer'
import { basketReducer } from './basket.reducer'
import { currentOrderDetailsReducer } from './current-order-details.reducer'
import { customerReducer } from './customer.reducer'
import { featureReducer } from './feature.reducer'
import { sessionReducer } from './session.reducer'
import { storeReducer } from './store.reducer'
import { voucherReducer } from './voucher.reducer'
import { upsellReducer } from './upsell.reducer'

export {
  basketReducer,
  currentOrderDetailsReducer,
  customerReducer,
  storeReducer,
  applicationReducer,
  sessionReducer,
  featureReducer,
  voucherReducer,
  upsellReducer,
}
