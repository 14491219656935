import React from 'react'
import { PaymentMethod } from '../payment-method'
import { PaymentMethodProps } from '../payment-method.interface'
import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import { getCountryConfig } from '@dominos/business/functions'
import { usePaypalEvents } from './use-paypal-events'
import { usePaymentAmount } from '@dominos/hooks-and-hocs'
import { useFosOrderButtonClickedEvent } from '../../use-fos-order-button-clicked-event'
import { ActionButton } from '../../../../../buttons'

export const PaymentPaypalDirect = (props: PaymentMethodProps) => {
  const config = getCountryConfig()
  const { paymentAmount } = usePaymentAmount(
    props.paymentSetting.donationEnabled,
    props.outstandingBalance?.orderOutstandingBalance,
  )
  const sendOrderButtonClickedEvent = useFosOrderButtonClickedEvent()
  const { paypalEvents, loading } = usePaypalEvents(
    paymentAmount,
    props.paymentSetting.providerCode as BffContext.PaymentProviders,
  )
  const options = {
    intent: 'authorize',
    'client-id': config.PAYPAL_CLIENT_ID,
    'merchant-id': props.paymentSetting.accountId || '',
    currency: config.CURRENCY_CODE,
    'integration-date': '2022-09-23',
    'disable-funding':
      'card,credit,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo', // https://developer.paypal.com/sdk/js/configuration/#link-disablefunding
  }
  const expanded = props.paymentSetting.accountId && props.selectedPaymentSetting?.paymentMethod === 'PayPal'

  const onCreateOrder = () => {
    sendOrderButtonClickedEvent()

    return paypalEvents.createOrder()
  }

  return (
    <PaymentMethod methodId={props.paymentSetting.paymentMethod} hidePaymentButton={true} {...props}>
      {expanded && (
        <PayPalScriptProvider options={options}>
          <LoadingState />
          <div style={loading || props.isValidatingBasket || !paymentAmount ? disabledButtonStyle : enabledButtonStyle}>
            <PayPalButtons
              {...paypalEvents}
              createOrder={onCreateOrder}
              style={{ layout: 'horizontal', label: 'pay', tagline: false }}
            />
          </div>
        </PayPalScriptProvider>
      )}
    </PaymentMethod>
  )
}

const enabledButtonStyle = { zIndex: 0 }
const disabledButtonStyle = { ...enabledButtonStyle, filter: 'grayscale(100%)', pointerEvents: 'none' }

function LoadingState() {
  const [state] = usePayPalScriptReducer()

  return state.isResolved ? null : <ActionButton loading disabled testID={'loading'} />
}
