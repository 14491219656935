import React, { useRef } from 'react'
import { OrderDetailsPillInformation } from '@dominos/components'
import {
  useHasUserStartedJourneyOnMenu,
  useOrderDetailsPillInformationPreference,
  useReport,
  useSpotlightContext,
} from '@dominos/hooks-and-hocs'
import { navigate, useLocation } from '@reach/router'
import { SpotlightKey } from '@dominos/components'

export const useSpotlightOrderDetails = () => {
  const location = useLocation()
  const spotlightKey = useRef<SpotlightKey>('')
  const hasUserStartedJourneyOnMenu = useHasUserStartedJourneyOnMenu()
  const { isOrderDetailsPillInformationAllowed, dismissInformation } = useOrderDetailsPillInformationPreference()
  const { registerToSpotlight, unregisterFromSpotlight, displaySpotlight, hideSpotlight } = useSpotlightContext()
  const { reportReturningCustomerOrder } = useReport()

  const handleDismiss = (neverShowAgain: boolean = false) => {
    dismissInformation(neverShowAgain)
    hideSpotlight()
    unregisterFromSpotlight(spotlightKey.current)
  }

  const orderDetailsPillInformationContent = (
    <OrderDetailsPillInformation testID='order-details-pill-information' onDismiss={handleDismiss} />
  )

  const callback = React.useCallback((orderDetailsPillRef) => {
    if (hasUserStartedJourneyOnMenu && isOrderDetailsPillInformationAllowed) {
      if (orderDetailsPillRef) {
        reportReturningCustomerOrder('Order details balloon popup')

        spotlightKey.current = registerToSpotlight(orderDetailsPillRef, orderDetailsPillInformationContent)
        displaySpotlight(spotlightKey.current)
        /**
         * Replace current location state to remove `userHasStartedJourneyOnMenu`
         * in order to stop unexpected behavior when users come back to this page
         * through any other flow
         */
        const { userHasStartedJourneyOnMenu, ...rest } = (location.state ?? {}) as { [K: string]: unknown }
        navigate(location.pathname, { state: { ...rest }, replace: true })
      } else {
        hideSpotlight()
        unregisterFromSpotlight(spotlightKey.current)
      }
    }
  }, [])

  return [callback]
}
