import { Error, LinkButton, MDTargetBlankAnchor } from '@dominos/components'
import { ActionButton } from '@dominos/components/buttons'
import { LoyaltyEnroll } from '@dominos/components/loyalty'
import { PopUpNotification } from '@dominos/components/notification'
import { TermsAndConditionsGeneric } from '@dominos/components/terms-and-conditions'
import {
  useCountryCode,
  useCurrentOrderDetails,
  useFeatures,
  useLanguages,
  useOneTrustStyleOverride,
  useRecaptcha,
  useReport,
  useSetLoggedInUser,
} from '@dominos/hooks-and-hocs'
import { useAccountCreateEmail } from '@dominos/hooks-and-hocs/customer/use-account-create-email'
import { useDominosTheme } from '@dominos/hooks-and-hocs/theme'
import { CreateAccountSceneProps } from '@dominos/scenes/create-account-scene'
import { navigate, useLocation } from '@reach/router'
import Markdown from 'markdown-to-jsx'
import { getProviderId, getProviderType } from '@dominos/hooks-and-hocs/logging/helpers'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AccountDetails } from './account-details'
import { createAccountErrors } from './create-account-errors'
import css from './create-account.less'
import { createCustomerQueryBuilder } from './create-customer-query-builder'
import { PasswordForm } from './password-form'
import { Subscriptions } from './subscriptions'
import { TermsAndConditions } from './terms-and-conditions'
import { getErrorMessageFromCreateAccountError } from './functions'

const initialSubscriptionState = {
  marketing: undefined,
  vouchers: undefined,
}

const recaptchaAction: BffContext.RecaptchaAction = 'CreateAccount'

// eslint-disable-next-line max-lines-per-function
export const CreateAccount = ({
  testID = 'create-account',
  loginRoute = '/',
  authenticationSource = 'Legacy',
  ...props
}: CreateAccountSceneProps) => {
  const { t } = useTranslation('login')
  const { reportRegistration, reportRegistrationAttempt } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const theme = useDominosTheme()
  const countryCode = useCountryCode()
  const location = useLocation()
  const { language } = useLanguages()
  const [accountDetails, setAccountDetails] = useState<{ [key: string]: string | undefined }>({
    Name: props.name,
    Email: props.email,
    AlternateName: props.alternateName,
  })
  const creationAttemptedRef = useRef<boolean>(false)
  const [isAccountDetailsValid, setIsAccountDetailsValid] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>()
  const [password, setPassword] = useState<string>()
  const [termsValid, setTermsValid] = useState(false)
  const [subscriptions, setSubscriptions] = useState<Record<string, boolean | undefined>>(initialSubscriptionState)
  const [loyaltyEnrolled, setLoyaltyEnrolled] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const { setLoggedInUser } = useSetLoggedInUser()
  const [idToken, setIdToken] = useState('')
  const { executeV3, renderCheckboxV2, getV2Token, resetCheckboxV2 } = useRecaptcha()
  const { featureEnabled } = useFeatures()
  const [
    recaptchaEnabled,
    loyaltyFeatureEnabled,
    marketingVoucherNotificationEnabled,
    marketingSpecialOffersEnabled,
    isGDPRDisclaimerEnabled,
    isLoyaltyMarketingEnabled,
  ] = featureEnabled(
    'Recaptcha',
    'loyalty-backend',
    'CustomerMarketingConsentVoucherNotification',
    'CustomerMarketingConsentSpecialOffers',
    'createAccountGDPRDisclaimer',
    'LoyaltyMarketing',
  )

  useEffect(() => {
    setIsPasswordValid(props.identityProvider?.identityProviderId !== undefined)
  }, [props.identityProvider?.identityProviderId])

  const getProviderName = (provider: BffContext.ProviderTypes | undefined) =>
    provider == 'dominos' ? 'Email' : provider
  const callBackOnCreateSuccess = async (token: string, customerId: string) => {
    setIdToken(token)
    await setLoggedInUser(token, customerId, true, getProviderName(getProviderType(props.identityProvider))).then(
      (success) => {
        if (authenticationSource === 'UniversalAuth') {
          props.onSuccess(token)
        } else {
          setShowPopup(success)
        }
      },
    )
  }
  const { createAccount, loading, error, useRecaptchaV2, shouldResetCheckbox } =
    useAccountCreateEmail(callBackOnCreateSuccess)

  const shouldShowAlternateName = useMemo(() => countryCode === 'JP' && language === 'ja', [countryCode, language])

  useOneTrustStyleOverride()

  useEffect(() => {
    if (!!error) {
      reportRegistration({
        success: false,
        orderId,
        identityProvider: getProviderType(props.identityProvider),
        identityProviderId: getProviderId(props.identityProvider),
        authenticationSource,
        url: location.href,
        error: getErrorMessageFromCreateAccountError(error),
      })
    }
  }, [error])

  useEffect(() => {
    if (shouldResetCheckbox) {
      resetCheckboxV2()
    }
  }, [shouldResetCheckbox])

  useEffect(
    () => () => {
      if (!creationAttemptedRef.current) {
        reportRegistrationAttempt({
          success: false,
          url: location.href,
          identityProvider: getProviderType(props.identityProvider) ?? 'email',
          authenticationSource: 'UniversalAuth',
          note: 'User closed account window',
          orderId,
          identityProviderId: getProviderId(props.identityProvider),
          loyaltyOptIn: loyaltyEnrolled,
        })
      }
    },
    [],
  )
  const onNotificationClose = () => {
    setShowPopup(false)
    if (idToken) {
      props.onSuccess(idToken)
    } else {
      props.onFail()
    }
  }

  const onFormValidationChange = (isValid: boolean) => {
    setIsAccountDetailsValid(isValid)
  }
  const onDetailsChange = (field: string, value: string | undefined) => {
    setAccountDetails((prev) => ({ ...prev, [field]: value }))
  }
  const onPressLoginButton = () => {
    props.redirectTo ? navigate(loginRoute, { state: { redirectTo: props.redirectTo } }) : navigate(loginRoute)
  }
  const onCreateAccountClicked = async () => {
    creationAttemptedRef.current = true
    if (isAccountDetailsValid && isPasswordValid && termsValid) {
      let recaptchaToken: string | undefined

      if (recaptchaEnabled && !props.identityProvider) {
        recaptchaToken = useRecaptchaV2 ? getV2Token() : await executeV3(recaptchaAction)
      }

      const variables = createCustomerQueryBuilder({
        password,
        subscriptions,
        loyaltyEnrolled,
        orderId,
        token: recaptchaToken,
        email: accountDetails.Email!,
        identityProvider: props.identityProvider,
        name: accountDetails.Name!,
        alternateName: accountDetails.AlternateName,
        phoneNumber: accountDetails.Mobile!,
        useRecaptchaV3: !useRecaptchaV2,
        recaptchaAction: recaptchaAction,
      })

      createAccount(variables, authenticationSource)
    }
  }
  const onMarketingConsentChange = (fieldName: string | null, isChecked: boolean) => {
    setSubscriptions({ ...subscriptions, [fieldName!]: isChecked })
  }
  const loyaltyUpdatesEnabled = !!subscriptions.loyalty
  const onLoyaltyMarketingConsentChange = (isChecked: boolean) => onMarketingConsentChange('loyalty', isChecked)

  return (
    <div data-testid={testID}>
      <div className={css.description}>
        {props.description || (
          <>
            {t('CreateAccountAlreadyGotAccount', { defaultValue: 'Already got an account?' })}{' '}
            <LinkButton
              testID={'onPressLoginButton'}
              style={{ cursor: 'pointer', color: '#4183c4' }}
              weight={'bold'}
              onPress={props.onLogin ?? onPressLoginButton}
              text={t('CreateAccountLogInButton', { defaultValue: 'Log in' })}
            />
          </>
        )}
      </div>
      <form>
        <div className={css.inputHeader}>
          {props.subtitle || t('CreateAccountSignUpWithEmailTitle', { defaultValue: 'Sign up with email' })}
        </div>

        <div className={css.constrainWidth}>
          <AccountDetails
            email={props.email}
            name={props.name}
            alternateName={props.alternateName}
            phoneNumber={props.phoneNumber}
            onValidationChange={onFormValidationChange}
            onChange={onDetailsChange}
            hideName={!!props.identityProvider && props.name !== undefined}
            hideAlternateName={
              (!!props.identityProvider && props.alternateName !== undefined) || !shouldShowAlternateName
            }
            hideEmail={!!props.identityProvider && !!props.email}
          />
          {props.identityProvider !== undefined ? null : (
            <PasswordForm onPasswordChanged={setPassword} onPasswordValid={setIsPasswordValid} />
          )}
          {loyaltyFeatureEnabled && (
            <LoyaltyEnroll
              enrolled={loyaltyEnrolled}
              onCheck={setLoyaltyEnrolled}
              receiveUpdatesEnabled={loyaltyUpdatesEnabled}
              onCheckReceiveUpdates={onLoyaltyMarketingConsentChange}
              showLoyaltyMarketing={isLoyaltyMarketingEnabled}
            />
          )}
        </div>
        {(marketingVoucherNotificationEnabled || marketingSpecialOffersEnabled) && (
          <Subscriptions
            testID={`CreateAccount.EDMSubscriptions`}
            onChange={onMarketingConsentChange}
            voucherNotificationEnabled={marketingVoucherNotificationEnabled}
            specialOffersEnabled={marketingSpecialOffersEnabled}
          />
        )}
        <TermsAndConditions setValid={setTermsValid} />
        {recaptchaEnabled && !props.identityProvider && (
          <Markdown
            data-testid='RecaptchaPolicy.Text'
            className={css.recaptchaPolicy}
            options={{ overrides: { a: MDTargetBlankAnchor } }}
          >
            {t('recaptchaPolicy', {
              defaultValue:
                'This site is protected by reCAPTCHA and the Google [Privacy Policy](https://policies.google.com/privacy) and [Terms of Service](https://policies.google.com/terms) apply',
            })}
          </Markdown>
        )}
      </form>
      <div className={css.constrainWidth}>
        {error && <Error errorDefinitions={createAccountErrors} tns={t} error={error} />}

        <div id='recaptcha-container' />
        {useRecaptchaV2 && renderCheckboxV2(recaptchaAction, 'recaptcha-container')}
      </div>
      <ActionButton
        testID='createAccount.button'
        onPress={onCreateAccountClicked}
        text={t('CreateAccountButton', { defaultValue: 'Create Account' })}
        containerStyle={{ width: 'calc(100% - 16px)', margin: '8px 8px 4px 8px', height: '54px' }}
        loading={loading}
        disabled={!(isAccountDetailsValid && isPasswordValid && termsValid) || loading}
        textFontWeight='semibold'
      />
      {!!isGDPRDisclaimerEnabled && (
        <div className={css.gdpr}>
          <TermsAndConditionsGeneric
            testID={`CreateAccount.GDPRDisclaimer`}
            text={t(`GDPR Disclaimer`)}
            textStyle={{ color: theme.colours.web.grey, fontSize: 12 }}
            weight='bold'
          />
        </div>
      )}
      {showPopup ? (
        <PopUpNotification
          heading={t('CreateAccountCreateSuccessful', { defaultValue: 'Account Created' })}
          onClose={onNotificationClose}
        />
      ) : null}
    </div>
  )
}
