import React from 'react'
import { DeliverySearchToolTip, GenericCard, ValidationTextField } from '@dominos/components'
import { MapListToggle } from './map-list-toggle'
import css from './delivery-autocomplete-search.less'
import {
  IUseDeliveryAutoCompleteSearchComponentProps,
  useDeliveryAutoCompleteSearchComponent,
} from 'olo-feature-address'
import { DeliveryAddressErrorContainer } from '../delivery-address-error-container'
import { DeliveryPreviewListContainer } from '../delivery-preview-list'
import { DeliveryAddressInputAssistant } from '../delivery-address-input-assistant'
import { DeliveryAddressPredictions } from '../delivery-address-predictions'
import { GeoLocationInterface } from 'packages/interfaces'

const testID = 'delivery-address-search'

export interface DeliveryAutoCompleteSearchProps extends IUseDeliveryAutoCompleteSearchComponentProps {
  toggleViewMode: () => void
  isLoading: boolean
  testID: string
  location: GeoLocationInterface
  setAddressInput: (input: string | undefined) => void
}

export const DeliveryAutoCompleteSearch = (props: DeliveryAutoCompleteSearchProps) => {
  const {
    deliveryAddressChange,
    deliveryAddress,
    navigationAction,
    viewMode,
    deliverySearchTitle,
    deliverySearchInputPlaceholder,
    deliverySearchInputValue,
    onInputChange,
    predictions,
    onSelectAddress,
    shouldShowAddressSearch,
    shouldShowDeliveryAddressPreview,
    shouldDisplayMapListToggle,
    toggleViewMode,
    currentError,
    fallbackSearchButtonLabel,
    showFallbackAddressSearchButton,
    shouldShowPredictions,
    onErrorDisplayChangeHandler,
    selectedAddressIsLoading,
    selectedAddressLoadingKey,
  } = useDeliveryAutoCompleteSearchComponent(props)

  const handleChange = async (updatedField: { [key: string]: ValidationField }) => {
    const key = Object.keys(updatedField)[0]
    const newSearch = updatedField[key]
    onInputChange(newSearch.value ?? '')
  }

  return (
    <div data-testid={testID} className={css.wrapper}>
      {shouldShowAddressSearch && (
        <GenericCard
          testID={testID}
          title={deliverySearchTitle}
          TitleTriggerComponent={
            shouldDisplayMapListToggle && (
              <MapListToggle testID={testID} searchMode={viewMode ?? 'List'} onTitleTriggerClick={toggleViewMode} />
            )
          }
        >
          <ValidationTextField
            testID={testID}
            fieldName={'addressSearch'}
            placeholder={deliverySearchInputPlaceholder}
            hasClearInputButton
            hideEmptyErrorContainer
            onChange={handleChange}
            initialValue={deliverySearchInputValue}
          />
          <DeliverySearchToolTip />
          <DeliveryAddressInputAssistant inputValue={deliverySearchInputValue} predictions={predictions} />
          {shouldShowPredictions && (
            <DeliveryAddressPredictions
              predictions={predictions}
              onSelectAddress={onSelectAddress}
              navigationAction={navigationAction}
              showFallbackSearchButton={showFallbackAddressSearchButton}
              fallbackSearchButtonLabel={fallbackSearchButtonLabel}
              isLoading={selectedAddressIsLoading}
              itemLoadingKey={selectedAddressLoadingKey}
            />
          )}
          <DeliveryAddressErrorContainer
            currentError={currentError}
            viewMode={viewMode}
            currentComponent={'DeliveryAddressSearch'}
            onClose={undefined}
            navigationAction={navigationAction}
            onErrorDisplayChange={onErrorDisplayChangeHandler}
            deliveryAddress={deliveryAddress}
          />
        </GenericCard>
      )}
      <div className={css.storedListWrapper}>
        <DeliveryPreviewListContainer
          deliveryAddressChange={deliveryAddressChange}
          nearbyLocation={props.location}
          hideComponent={!shouldShowDeliveryAddressPreview}
        />
      </div>
    </div>
  )
}
