import { useMutation } from '@apollo/client'
import { resetPasswordMutation } from '@dominos/business/queries'
import { ActionButton, GenericCard, PasswordInput } from '@dominos/components'
import { PopUpNotification } from '@dominos/components/notification'
import {
  useAlert,
  useCountryCode,
  useCurrentOrderDetails,
  useDominosTheme,
  useFeatures,
  useReport,
} from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate, useLocation } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ResetPassword = () => {
  const { t } = useTranslation('login')
  const theme = useDominosTheme()
  const [password, setPassword] = useState<string>()
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [token, setToken] = useState<string>()
  const [externalUserId, setExternalUserId] = useState<string>()
  const location = useLocation()
  const { showAlert } = useAlert()
  const { reportResetPassword } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const [showPopup, setShowPopup] = useState(false)
  const [resetPassword, { loading }] = useMutation<
    { resetPassword: boolean },
    { token: string; password: string; externalUserId: string }
  >(resetPasswordMutation, {
    onError: () => handleError(),
    onCompleted: (data) => handleComplete(data.resetPassword),
  })
  const { featureEnabled } = useFeatures()
  const [singaporeCustomerMigration] = featureEnabled('SingaporeCustomerMigration')
  const countryCode = useCountryCode()

  const handleError = async () => {
    await reportResetPassword({ token, orderId, success: false, forceFlush: true })
    showAlert(
      t('ResetPasswordFailedMessage', {
        defaultValue: 'Sorry, there was an error processing your reset password request. Please try again later.',
      }),
    )
  }

  const handleComplete = async (result: boolean) => {
    if (result) {
      await reportResetPassword({ token, orderId, success: true, forceFlush: true })
      setShowPopup(true)
    } else {
      await handleError()
    }
  }

  useEffect(() => {
    if (!!location.search) {
      const tokenValue = new URLSearchParams(location.search).get('token')
      const externalTokenValue = new URLSearchParams(location.search).get('Token')
      const externalUserIdValue = new URLSearchParams(location.search).get('userId')

      // This is only needed for SG onboarding, after SG is 100% on NextGen this can be deleted
      if (singaporeCustomerMigration && countryCode === 'SG' && externalTokenValue && externalUserIdValue) {
        setToken(externalTokenValue)
        setExternalUserId(externalUserIdValue)

        return
      } else {
        if (tokenValue) {
          setToken(tokenValue)
        } else {
          navigate(NavigationConstants.home)
        }
      }
    } else {
      navigate(NavigationConstants.home)
    }
  }, [])

  const styles = {
    resetButton: { width: '100%' },
    content: { padding: '12px 0 0 0' } as React.CSSProperties,
  }

  const onReset = () => {
    if (isPasswordValid) {
      resetPassword({ variables: { token: token!, password: password!, externalUserId: externalUserId! } })
    }
  }

  const onPasswordChanged = (passwordText: string) => {
    setPassword(passwordText)
  }

  const onPasswordValid = (isValid: boolean) => {
    setIsPasswordValid(isValid)
  }

  const onNotificationClose = () => {
    setShowPopup(false)
    navigate(NavigationConstants.home)
  }

  return (
    <GenericCard
      testID='GenericCardPopup'
      title={t('ResetPasswordTitle', { defaultValue: 'Save New Password' })}
      style={{ zIndex: 2, position: 'relative' }}
    >
      <div style={styles.content}>
        <PasswordInput
          onPasswordChanged={onPasswordChanged}
          onPasswordValid={onPasswordValid}
          passwordPlaceholder={t('ResetPasswordPasswordLabel', { defaultValue: 'New Password' })}
          passwordConfirmPlaceholder={t('ResetPasswordConfirmPasswordLabel', { defaultValue: 'Confirm New Password' })}
        />
      </div>
      <ActionButton
        onPress={onReset}
        testID={'reset.button'}
        containerStyle={styles.resetButton}
        textStyle={{ color: theme.colours.tooltipText }}
        textFontWeight={'semibold'}
        text={t('ResetPasswordButtonText', { defaultValue: 'Save New Password' })}
        disabled={!!loading || !isPasswordValid || !token}
        loading={loading}
      />
      {showPopup ? (
        <PopUpNotification
          heading={t('ResetPasswordSuccessful', { defaultValue: 'Your password has been successfully reset.' })}
          onClose={onNotificationClose}
        />
      ) : null}
    </GenericCard>
  )
}
