import React from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorNotifier } from '@dominos/components'
import { useCountDown, usePageView } from '@dominos/hooks-and-hocs'
import { KioskOrderPopup } from '../kiosk-order-popup'
import { TIMEOUT_SECONDS } from '../kiosk-inactivity-config'
import { useRestartOrder } from '@dominos/components/footer/simple-footer/use-restart-order'

export const KioskTrackerPage = ({ order }: { order: Bff.Orders.Order }) => {
  const { t } = useTranslation('kiosk')
  const resetOrder = useRestartOrder({ report: false, fos: false, updateFeatureToggles: true })

  usePageView('Flow.Tracker.ArrivedTracker')
  useCountDown({ seconds: TIMEOUT_SECONDS, onCountDownEnd: resetOrder, startCountDown: true })

  const isTerminalPayment = order.details?.payments?.some((payment) => payment.paymentMethod === 'Terminal')
  const popupMessage = isTerminalPayment
    ? t('OrderConfirmationTerminalMessage', {
        defaultValue: 'We will notify you when your order <br> is ready to pickup from counter',
      })
    : t('OrderConfirmationPopupMessage', {
        defaultValue: 'Proceed to the counter to pay<br>to get your order cooking.',
      })

  return (
    <ErrorNotifier namespace={'tracker'}>
      <KioskOrderPopup
        testId='kiosk-tracker'
        popupTitle={t('OrderConfirmationPopupTitle', {
          defaultValue: 'Your order is confirmed',
          name: order.details?.header.customer?.name,
        })}
        icon='confirmationTick'
        popupMessage={popupMessage}
        onConfirm={resetOrder}
        onConfirmText={t('OrderConfirmationConfirmButton', {
          defaultValue: 'Done',
        })}
      />
    </ErrorNotifier>
  )
}
