import React, { useRef, useState } from 'react'
import Keyboard from 'react-simple-keyboard'
import { useVirtualKeyboardLayout } from '@dominos/hooks-and-hocs/virtual-keyboard/layout'
import 'react-simple-keyboard/build/css/index.css'
import css from './virtual-keyboard.less'
import { buttonTheme } from './virtual-keyboard-button-theme'
import { SwitchLayout } from './switch-layout'

export const VirtualKeyboard: React.FC<{
  didChangeTextInput?: ({ target }: React.ChangeEvent<HTMLInputElement>) => void
  didPressKey?: (event: React.KeyboardEvent<HTMLInputElement> & { target: { value: string } }) => void
  handleClose: () => void
  inputFieldName?: string
}> = (props) => {
  const [layoutName, setLayoutName] = useState('default')
  const { keyboardLayouts, selectedLayout, switchLayout } = useVirtualKeyboardLayout()
  const [showSwitchLayout, setShowSwitchLayout] = useState(false)
  const keyboard = useRef()
  const hideSwitchLayoutModal = () => setShowSwitchLayout(false)
  const showSwitchLayoutModal = () => setShowSwitchLayout(true)
  const onChange = (input: string) => {
    if (props.didChangeTextInput) {
      props.didChangeTextInput({ target: { value: input } } as React.ChangeEvent<HTMLInputElement>)
    }
  }

  const handleShift = () => {
    const newLayoutName = layoutName === 'default' ? 'shift' : 'default'
    setLayoutName(newLayoutName)
  }

  const onKeyPress = (button: string, e?: MouseEvent) => {
    e?.preventDefault()
    if (button === '{close}') {
      props.handleClose()

      return
    }

    if (button === '{shift}' || button === '{lock}') {
      handleShift()
    }

    if (button === '{switchLanguage}') {
      showSwitchLayoutModal()
    }

    if (props.didPressKey) {
      props.didPressKey({ target: { value: button } } as React.KeyboardEvent<HTMLInputElement> & {
        target: { value: string }
      })
    }
  }

  const onLayoutSelect = (selectedIndex: number) => {
    switchLayout(selectedIndex)
    hideSwitchLayoutModal()
  }

  return (
    <div className={css.virtualKeyboard} data-testid='virtual-keyboard'>
      {showSwitchLayout && (
        <SwitchLayout
          testID='virtual-keyboard'
          layouts={keyboardLayouts}
          selectedLayout={selectedLayout}
          handleChange={onLayoutSelect}
          handleDismiss={hideSwitchLayoutModal}
        />
      )}
      <Keyboard
        keyboardRef={(r) => (keyboard.current = r)}
        inputName={props.inputFieldName}
        layoutName={layoutName}
        layout={selectedLayout?.layoutItem.layout}
        onChange={onChange}
        onKeyPress={onKeyPress}
        buttonTheme={buttonTheme}
        display={selectedLayout?.display}
        autoUseTouchEvents
      />
    </div>
  )
}
