import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { useFeatures } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'

const applicationConfig = getCountryConfig()

const openCookieSettings = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  window.OneTrust?.ToggleInfoDisplay()
  e?.preventDefault()

  return false
}

export const CookieSettings = ({ testID }: { testID: string }) => {
  const { t } = useTranslation('footer')
  const { featureEnabled } = useFeatures()
  const [footerCookieEnabled] = featureEnabled('footer-cookie')

  return (
    <>
      {footerCookieEnabled && applicationConfig.ONE_TRUST_KEY !== '' && applicationConfig.ONE_TRUST_KEY !== 'null' && (
        <a id='ot-sdk-btn' data-testid={`${testID}.legal-links.Cookie Settings`} href='' onClick={openCookieSettings}>
          {t('Cookie Settings')}
        </a>
      )}
    </>
  )
}
