import { rootActions } from '@dominos/business/root.actions'
import { FeatureType } from '@dominos/hooks-and-hocs/features/feature-context'
import { FeatureFlagType } from '@dominos/hooks-and-hocs/features/feature-flag.interface'
import { getType } from 'typesafe-actions'

interface FeatureState {
  features?: FeatureType[]
  featuresV2?: FeatureFlagType
}

export const featureReducer: import('redux').Reducer<FeatureState> = (
  state: FeatureState = {},
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.setFeatureToggles):
      return { ...state, features: action.payload }
    case getType(rootActions.setFeatureTogglesV2):
      return { ...state, featuresV2: action.payload }
    default:
      return state
  }
}
