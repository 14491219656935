import React, { useEffect, useState } from 'react'
import { CollapsibleCard } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res'
import styles from './order-details-pill.less'
import { navigate } from '@reach/router'
import { NavigationConstants } from '@dominos/navigation'
import { useTranslation } from 'react-i18next'
import { toPascalCase } from '@dominos/business/functions'
import {
  useCurrentStore,
  useDisplayAddress,
  useDominosTheme,
  useOrderTime,
  useReport,
  useServiceMethod,
} from '@dominos/hooks-and-hocs'
import { DateTime } from 'luxon'

export const OrderDetailsPill = ({ testID = 'order-details', disableClick = false }) => {
  const [expanded, setExpanded] = useState(false)
  const { i18n, t } = useTranslation('menu')
  const serviceMethod = useServiceMethod()
  const currentStore = useCurrentStore()
  const orderDateTime = useOrderTime()
  const displayAddress = useDisplayAddress()
  let orderHumanReadableDateTime = t('NOW')

  const { reportReturningCustomerOrder, reportStartJourneyOnMenuOrderDetailsChanged } = useReport()
  useEffect(() => {
    if (expanded) {
      reportReturningCustomerOrder('Option to change available')
    }
  }, [expanded])

  if (orderDateTime) {
    orderHumanReadableDateTime = DateTime.fromISO(orderDateTime, { setZone: true })
      .setLocale(i18n.language)
      .toFormat(t('DateTimeFormat', { defaultValue: 'h:mma EEE d MMM' }))
  }

  const pickupStr = t('Pick Up')
  const deliverStr = t('Delivery')
  const pickUpOrDeliverTitle = serviceMethod === 'Pickup' ? `${pickupStr}` : `${deliverStr}`
  const pickUpOrDeliverIcon = serviceMethod === 'Pickup' ? 'storeDetailsPickUp' : 'storeDetailsDelivery'

  const storeNameText =
    currentStore && currentStore.media?.name
      ? t('DominosStore', {
          storeName: toPascalCase(currentStore.media?.name ?? ''),
          defaultValue: "Domino's {{storeName}}",
          interpolation: { escapeValue: false },
        })
      : ''

  const pickUpOrDeliverLocation =
    serviceMethod === 'Pickup' ? storeNameText : displayAddress ?? t('Delivery address not available')

  const theme = useDominosTheme()

  const handleChangeOrderButtonClick = () => {
    reportReturningCustomerOrder('Order details changed')
    reportStartJourneyOnMenuOrderDetailsChanged()

    navigate(NavigationConstants.home)
  }

  const noncollapsibleContent = (
    <div className={styles.noncollapsibleContent}>
      {
        <div className={styles.headerIcon} data-testid={`${testID || 'OrderDetailsPill'}-${pickUpOrDeliverIcon}`}>
          <StaticSvgIcon name={pickUpOrDeliverIcon} width={30} height={30} isUnstyled fill={theme.colours.hyperlink} />
        </div>
      }

      <div className={styles.headerWrapper}>
        <header className={styles.header} role={'title'} data-testid={`${testID || 'OrderDetailsPill'}-header`}>
          <span data-testid={`${testID || 'OrderDetailsPill'}-title`} className={styles.title}>
            {pickUpOrDeliverTitle}
            <span
              data-testid={`${testID || 'OrderDetailsPill'}-orderHumanReadableDateTime`}
              className={styles.orderHumanReadableDateTime}
            >
              {orderHumanReadableDateTime}
            </span>
          </span>
        </header>

        <div
          data-testid={`${testID || 'OrderDetailsPill'}-pickUpOrDeliverLocation`}
          className={expanded ? `${styles.wrapper} ${styles.expanded}` : `${styles.wrapper} ${styles.collapsed}`}
        >
          {pickUpOrDeliverLocation}
        </div>
      </div>
    </div>
  )

  const collapsibleContent = (
    <div className={styles.changeOrderButtonContainer}>
      <button
        onClick={handleChangeOrderButtonClick}
        className={styles.changeOrderButton}
        data-testid={`${testID}.change-order-details-button`}
      >
        <div className={styles.changeIcon}>
          <StaticSvgIcon name={'change'} isUnstyled={false} fill='rgb(52, 120, 246)' width={18} height={18} />
        </div>
        <div>{t('Change Order Details')}</div>
      </button>
    </div>
  )

  return (
    <CollapsibleCard
      startExpanded={expanded}
      testID={`${testID}.order-details-pill`}
      noncollapsibleContent={noncollapsibleContent}
      collapsibleContent={collapsibleContent}
      onToggleChanged={disableClick ? undefined : setExpanded}
      disableClick={disableClick}
    />
  )
}
