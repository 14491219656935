import { useFeatures } from '@dominos/hooks-and-hocs/features'
import { SubMenuRoutes, SubMenuToggles } from '@dominos/interfaces'
import _ from 'lodash'

export const useAccountDetailsSubmenuToggles = () => {
  const { featureEnabled } = useFeatures()
  const [savedVouchers, myRewards, myAccountDetails, myAccountPassword, myAccountSavedPayments, myAccountDelete] =
    featureEnabled(
      'saved-vouchers',
      'loyalty-backend',
      'MyAccountDetails',
      'MyAccountPassword',
      'MyAccountSavedPayments',
      'MyAccountDelete',
    )

  const toggleRoutes: SubMenuRoutes = {
    myRewards: 'my-rewards',
    myAccountDetails: 'details',
    myAccountPassword: 'password',
    myAccountSavedPayments: 'payment-details',
    savedVouchers: 'vouchers',
    myAccountDelete: 'delete',
  }

  const subMenuToggles = (): SubMenuToggles => {
    const toggles: SubMenuToggles = {
      myRewards,
      myAccountDetails,
      myAccountPassword,
      myAccountSavedPayments,
      savedVouchers,
      myAccountDelete,
    }

    return toggles
  }

  // Determines which is the default option to show when on desktop view
  // The order of toggleRoutes/subMenuToggles properties should match the order of menu items on my-account-layout -> SubMenu
  const getFirstActiveToggle = (): string => {
    const toggles = subMenuToggles()
    const firstActive = Object.keys(toggles).find((key: string) => toggles[key] === true) ?? ''

    return Object(toggleRoutes)[firstActive]
  }

  return {
    subMenuToggles,
    getFirstActiveToggle,
  }
}
