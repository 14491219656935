import { ProductCustomiser, ProductEditor, useProductContext } from '@dominos/components'
import { useBreakpoints, useKiosk } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useBaseData, useSizeData } from '@dominos/components/product/functions'

export const RecipeProductCard = ({ testID, onDismiss }: RecipeProductCardProps) => {
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const isMobileLayout = isMobile || isKioskOrder

  const { dimensionSetState, productData } = useProductContext()

  const sizeData = useSizeData(productData, dimensionSetState)
  const baseData = useBaseData(productData, dimensionSetState)

  const onBaseChange = (baseCode: string) => {
    dimensionSetState?.handleDimensionChange('Base', baseCode)
  }

  const onSizeChange = (sizeCode: string) => {
    dimensionSetState?.handleDimensionChange('Size', sizeCode, true)
  }

  return (
    <ProductEditor
      testID={`${testID}.product-editor`}
      onDismiss={onDismiss}
      showBackButton={!!isMobileLayout}
      showCloseButton={!isMobileLayout}
    >
      <ProductCustomiser
        sizeData={sizeData}
        onSizeChange={onSizeChange}
        baseData={baseData}
        onBaseChange={onBaseChange}
      />
    </ProductEditor>
  )
}
